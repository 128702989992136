<div fxLayout="row" fxLayoutAlign="space-between start" class="screen-header">
  <div
    *ngIf="action"
    fxLayout="column"
    fxLayoutAlign="center end"
    class="header-action"
  >
    <app-button
      class="main-action"
      color="secondary"
      size="l"
      [text]="action"
      [disabled]="disabled"
      (btnClick)="onClickAction()"
    ></app-button>
  </div>
</div>
