import { Observable } from 'rxjs'

export function toIncrementalBatch<T>(
  data: T[],
  initialSize = 50,
  delayTime = 500
): Observable<T[]> {
  const incremental$ = new Observable<T[]>(observer => {
    if (data.length <= initialSize) {
      observer.next(data)
      observer.complete()
    } else {
      const copied = Object.assign([], data)
      const firstBatch = copied.splice(0, initialSize)

      setTimeout(() => {
        observer.next(copied)
        observer.complete()
      }, delayTime)
      observer.next(firstBatch)
    }
  })
  return incremental$
}
