import { Component, EventEmitter, Output } from '@angular/core'
import { HubtypeProviderAccount } from '../../../models/hubtype-provider-account'

@Component({
  selector: 'app-channels-twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.scss'],
})
export class TwitterComponent {
  twitterChannel = {
    twitterUsername: '',
    twitterConsumerKey: '',
    twitterConsumerSecret: '',
    twitterAccessToken: '',
    twitterAccesTokenSecret: '',
    twitterEnvironment: '',
  }

  @Output() newChannel = new EventEmitter<HubtypeProviderAccount>()
  constructor() {}

  addTwitter() {
    const provider = new HubtypeProviderAccount()
    const credentials = {
      username: this.twitterChannel.twitterUsername,
      consumer_key: this.twitterChannel.twitterConsumerKey,
      consumer_secret: this.twitterChannel.twitterConsumerSecret,
      access_token: this.twitterChannel.twitterAccessToken,
      access_token_secret: this.twitterChannel.twitterAccesTokenSecret,
      environment: this.twitterChannel.twitterEnvironment,
    }
    provider.provider = HubtypeProviderAccount.TWITTER
    provider.credentials_json = credentials
    provider.name = this.twitterChannel.twitterUsername
    this.newChannel.emit(provider)
  }
}
