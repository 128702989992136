import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { finalize, first } from 'rxjs/operators'
import { HubtypeOrganization } from '../../models/hubtype-organization'
import {
  HubtypeProviderAccount,
  WebchatSettings,
} from '../../models/hubtype-provider-account'
import * as fromRoot from '../../reducers'
import { ProviderAccountService } from '../../services/hubtype-api/provider-account.service'
import { AngularComponent } from '../AngularComponent'

@Component({
  selector: 'app-channel-detail',
  templateUrl: './channel-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./channel-detail.component.scss'],
})
export class ChannelDetailComponent
  extends AngularComponent
  implements OnInit, OnDestroy
{
  providerAccount: HubtypeProviderAccount
  webchatSettings: WebchatSettings = { visible: true }
  organization: HubtypeOrganization
  providerId: string
  showCodeSnippet = false
  feedback = ''

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    @Inject('providerAccountService')
    private providerAccountService: ProviderAccountService
  ) {
    super()
    this.subscribeUntilDestroy(this.route.paramMap, (params: ParamMap) => {
      this.webchatSettings = undefined
      this.providerId = params.get('provider_id')
      this.ngOnInit()
    })
  }

  ngOnInit() {
    this.providerAccountService
      .getProvider(this.providerId)
      .pipe(
        first(),
        finalize(() => this.ref.markForCheck())
      )
      .subscribe(
        res => {
          this.providerAccount = res
          let settings = res.credentials_json.settings
          if (settings) {
            this.webchatSettings = {
              visible: settings.visible,
              whitelisted_urls: settings.whitelisted_urls,
              scheduled_queue_id: settings.scheduled_queue_id,
            }
          }
        },
        err =>
          console.log(`Provider with id ${this.providerId} does not exist`, err)
      )
  }

  onWebchatSettingsChanged(updatedSettings: WebchatSettings): void {
    this.webchatSettings = { ...this.webchatSettings, ...updatedSettings }
  }

  showFeedback() {
    if (this.feedback) {
      setTimeout(() => {
        this.ref.markForCheck()
        this.feedback = null
      }, 5000)
      return true
    }
    return false
  }

  updateSettings(): void {
    const previousSettings = this.providerAccount.credentials_json.settings
    this.providerAccount.credentials_json.settings = {
      ...previousSettings,
      ...this.webchatSettings,
    }
    this.providerAccountService
      .updateWebchatSettings(this.providerId, this.webchatSettings)
      .pipe(
        first(),
        finalize(() => this.ref.markForCheck())
      )
      .subscribe(
        res => {
          this.feedback = 'Updated successfully'
        },
        err => {
          const errorMsg = 'There was an external error updating settings'
          this.feedback = errorMsg
          console.log(errorMsg, err)
        }
      )
  }

  back() {
    this.backRoute(this.router, this.route)
  }
}
