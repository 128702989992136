<div class="header-content">
  <div class="title-wrapper">
    <div class="title">{{ title }}</div>
    <div class="subtitle" *ngIf="subtitle">{{ subtitle }}</div>
  </div>
  <div class="actions">
    <ng-content></ng-content>
  </div>
</div>
<ht-tab *ngIf="tabs.length" [tabs]="tabs"></ht-tab>
