import { DEFAULT_LANGUAGE } from '../constants'

export const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator['userLanguage'] ||
      navigator.language ||
      navigator['browserLanguage'] ||
      DEFAULT_LANGUAGE

export const getLanguageFromCode = (code: string): string =>
  languageISOCode.find(l => l.code === code)?.name || code

export const languageISOCode = [
  { code: 'en', name: 'English' },
  { code: 'cs', name: 'Czech' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'ro', name: 'Romanian' },
  { code: 'hr', name: 'Croatian' },
  { code: 'el', name: 'Greek' },
  { code: 'sk', name: 'Slovak' },
  { code: 'it', name: 'Italian' },
  { code: 'tr', name: 'Turkish' },
  { code: 'ru', name: 'Russian' },
  { code: 'es', name: 'Spanish' },
  { code: 'de', name: 'German' },
  { code: 'fr', name: 'French' },
  { code: 'pl', name: 'Polish' },
  { code: 'pt', name: 'Portuguese' },
]

export const getLanguageNameFromISOCode = (languageIsoCode: string) => {
  const languageNames = new Intl.DisplayNames(['en'], {
    type: 'language',
  })
  return languageNames.of(languageIsoCode)
}
