<div
  class="switcher {{ isSelected ? 'switcher-selected' : 'switcher-default' }}"
  (click)="onClick()"
  fxLayout="column"
>
  <h2>{{ title }}</h2>
  <p>{{ description }}</p>
  <div
    class="content {{ isSelected ? 'content-selected' : 'content-default' }}"
  >
    <ng-content></ng-content>
  </div>
</div>
