import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'

@Component({
  selector: 'ht-dialog',
  templateUrl: './ht-dialog.component.html',
  styleUrls: ['./ht-dialog.component.scss'],
})
export class HtDialogComponent implements OnInit {
  @Input()
  closable: boolean = true
  @Input()
  defaultOpen: boolean = false
  @Input()
  data: any = {}
  @Input()
  size: HtDialogSize = HtDialogSize.MEDIUM
  @Input()
  title = ''
  @Input()
  trigger: ElementRef = this.elRef

  @Output()
  closeOutput = new EventEmitter<any>()

  isOpen = false

  constructor(private elRef: ElementRef) {}

  get htDialogClassExpression() {
    return `ht-dialog ${this.size}`
  }

  close() {
    this.isOpen = false
    this.closeOutput.emit(true)
  }

  public getData(): any {
    return this.data
  }

  ngOnInit(): void {
    this.isOpen = this.defaultOpen
  }

  open(data?: any) {
    if (data) {
      this.setData(data)
    }
    this.isOpen = true
  }

  setData(data) {
    this.data = data
  }
}

@Component({
  selector: 'ht-dialog-body',
  template: '<ng-content></ng-content>',
  styleUrls: ['./ht-dialog.component.scss'],
})
export class HtDialogBodyComponent {}

@Component({
  selector: 'ht-dialog-footer',
  template: '<ng-content></ng-content>',
  styleUrls: ['./ht-dialog.component.scss'],
})
export class HtDialogFooterComponent {}

enum HtDialogSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
