import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { HubtypeProviderAccount } from '../../models/hubtype-provider-account'
import { ProviderAccountService } from '../../services/hubtype-api/provider-account.service'

@Component({
  selector: 'app-list-channels',
  templateUrl: './list-channels.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./list-channels.component.scss'],
})
export class ListChannelsComponent implements OnInit, OnDestroy {
  @Input() channels: HubtypeProviderAccount[]
  @Output() channelDeleted = new EventEmitter<HubtypeProviderAccount>()
  private ngUnsubscribe: Subject<void> = new Subject<void>()
  public feedback: string

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    @Inject('providerAccountService')
    private providerAccountService: ProviderAccountService
  ) {}

  ngOnInit() {
    if (this.channels) {
      for (const channel of this.channels) {
        if (channel && channel.isFacebook) {
          this.providerAccountService
            .obtainGetStarted(channel)
            .subscribe(res => {
              this.ref.markForCheck()
              // TODO: Change the is_active here is confusing. If we still need the GetStarted thing we should use an specific attribute
              res.data.length
                ? (channel.is_active = true)
                : (channel.is_active = false)
            })
        }
      }
    }
  }

  disableGetStarted(pa: HubtypeProviderAccount) {
    this.providerAccountService
      .disableGetStarted(pa)
      .pipe(finalize(() => this.ref.markForCheck()))
      .subscribe({
        next: res => {
          if (res.result === 'success') {
            pa.is_active = !pa.is_active
            this.feedback = null
          }
        },
        error: err => {
          console.log('error in disable get started button', err)
          this.feedback = 'There was en error disabling the get started button'
        },
      })
  }

  enableGetStarted(pa: HubtypeProviderAccount) {
    this.providerAccountService
      .enableGetStarted(pa)
      .pipe(finalize(() => this.ref.markForCheck()))
      .subscribe({
        next: res => {
          if (res.result === 'success') {
            pa.is_active = !pa.is_active
            this.feedback = null
          }
        },
        error: err => {
          console.log('error in enable get started button', err)
          this.feedback = 'There was en error enabling the get started button'
        },
      })
  }

  haveSettings(channel: HubtypeProviderAccount) {
    return (
      (channel.is_active && channel.isWebchat) ||
      channel.is_whatsapp_cloud ||
      (channel.is_active && channel.isWhatsapp && !channel.is_whatsapp_cloud)
    )
  }

  handleClick(channel: HubtypeProviderAccount) {
    // close the currently open detail page if opened or do nothing otherwise.
    // This also happen when click on integrations without settings
    this.router.navigate(['.'], { relativeTo: this.route })

    if (channel.is_active && channel.isWebchat) {
      this.router.navigate(['./detail', { provider_id: channel.id }], {
        relativeTo: this.route,
      })
    } else if (channel.is_whatsapp_cloud) {
      this.router.navigate(['./whatsapp-cloud', { provider_id: channel.id }], {
        relativeTo: this.route,
      })
    } else if (
      channel.is_active &&
      channel.isWhatsapp &&
      !channel.is_whatsapp_cloud
    ) {
      this.router.navigate(['./whatsapp-info', { provider_id: channel.id }], {
        relativeTo: this.route,
      })
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
