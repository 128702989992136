import { Directive, HostListener } from '@angular/core'

@Directive({
  selector: '[key-down-stop-propagation]',
})
export class KeydownStopPropagationDirective {
  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Enter') {
      event.stopPropagation()
    }
  }
}
