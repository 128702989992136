import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core'

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input()
  currentPage = 1
  @Input()
  maxLinks = 3
  @Input()
  pageSize = 20
  @Input()
  total = 0

  @Output()
  select = new EventEmitter<number>()

  lastPage = 1
  pages: number[] = []
  show = true

  constructor() {}

  ngOnInit() {
    this.calculatePages()
  }

  ngOnChanges() {
    this.calculatePages()
  }

  onClick(page: number) {
    this.select.emit(page)
  }

  private calculatePages() {
    if (this.total <= 0) {
      this.show = false
      return
    }
    this.lastPage = Math.ceil(this.total / this.pageSize)
    const currentPage = Number(this.currentPage)
    const maxLinks = Number(this.maxLinks)

    const totalPages = Math.min(maxLinks, this.lastPage)
    if (totalPages <= 1) {
      this.show = false
      return
    }

    let firstIndex = 1
    let lastIndex = this.lastPage
    if (this.lastPage > maxLinks) {
      lastIndex = Math.min(
        this.lastPage,
        currentPage + Math.floor(maxLinks / 2)
      )
      firstIndex = Math.max(1, lastIndex - maxLinks + 1)
    }

    this.pages = []
    for (let i = 0, page = firstIndex; i < totalPages; i++, page++) {
      this.pages.push(page)
    }
    this.show = true
  }
}
