<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <div [class]="htDialogClassExpression">
    <div class="ht-dialog-container" role="dialog" [attr.aria-label]="title">
      <div class="ht-dialog-header">
        <div>
          <div
            *ngIf="title"
            class="ht-dialog-header-title text-body bold break-word"
            >{{ title }}</div
          >
        </div>

        <div class="ht-dialog-close" (click)="close()" *ngIf="closable">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="ht-dialog-container-background"></div>
</ng-template>
