<div class="ht-tab-container">
  <ul class="ht-tab" role="tablist">
    <li *ngFor="let tab of tabs" class="ht-tab-item">
      <a
        class="ht-tab-link"
        [routerLink]="tab.link"
        routerLinkActive="active"
        role="tab"
        [attr.data-testid]="'tab-' + tab.link"
        [attr.aria-label]="tab.text"
        >{{ tab.text }}

        <ht-badge *ngIf="tab.badge" [type]="tab.badge.type">{{
          tab.badge.text
        }}</ht-badge>
      </a>
    </li>
  </ul>
  <div class="ht-tab-content" role="tabpanel">
    <router-outlet></router-outlet>
  </div>
</div>
