import { Component, Input } from '@angular/core'

@Component({
  selector: 'ht-metric-chip-value',
  templateUrl: './ht-metric-chip-value.component.html',
})
export class HtMetricChipValueComponent {
  @Input() value: any
  @Input() type: string
}
