<div class="dropdown" role="listbox" [attr.aria-label]="label">
  <button
    ht-select-button
    (click)="toggleDropdown()"
    [size]="buttonSize"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  >
    <ng-container *ngIf="!hideLabel">
      <span *ngIf="showSelectedValue; else ngContent">
        {{ selectedValue }}
      </span>

      <ng-template #ngContent>
        <ng-content></ng-content>
      </ng-template>
    </ng-container>
    <i
      class="fa-solid"
      [ngClass]="showDropdown ? 'fa-chevron-up' : 'fa-chevron-down'"
      *ngIf="!hideArrow"
    ></i>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="showDropdown"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  cdkConnectedOverlayWidth="240px"
>
  <div
    class="menu"
    (click)="handleDropdownMenuClick($event)"
    style="cursor: default !important"
  >
    <div
      class="search-bar-wrapper"
      *ngIf="numberOfItems >= SHOW_SEARCHBAR_ELEMENTS_THRESHOLD"
    >
      <ht-search-bar
        type="secondary"
        size="small"
        [placeholder]="searchBarPlaceholder"
        (search)="filterItems($event)"
        [(ngModel)]="searchValue"
      ></ht-search-bar>
    </div>
    <div class="items-container">
      <ng-container *ngTemplateOutlet="dropDownGroup"></ng-container>
    </div>

    <div class="apply-btn">
      <button ht-button-ds-primary [fullWidth]="true" (click)="onClickApply()">
        Apply
      </button>
    </div>
  </div>
</ng-template>

<ng-template #dropDownGroup>
  <ng-container *ngFor="let section of filteredData">
    <div class="section">
      <div *ngIf="section.title" class="section-title">
        {{ section.title }}
      </div>
      <ng-container *ngFor="let item of section.items">
        <div class="dropdown-checkbox-item">
          <ht-radiobutton
            [formControl]="optionControl"
            name="options"
            [value]="item.id"
            >{{ item.label }}</ht-radiobutton
          >
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
