<ht-modal [options]="modalOptions" (closeModalOutput)="onClose()" #modal>
  <ht-table-header>
    <div>
      <ht-search-bar
        placeholder="Search agents by name or email"
        [value]="searchFilter"
        size="small"
        (search)="onSearch($event)"
      ></ht-search-bar>
      <filter-user-status
        (statusChange)="onStatusFilterChange($event)"
      ></filter-user-status>
    </div>
  </ht-table-header>

  <div class="full-content">
    <user-list
      role="assignAgentsTable"
      class="assign-agents-table"
      #agentsTable
      [textFilter]="searchFilter"
      [statusFilter]="tableCheckboxFilter"
      [users]="allAgents"
      [disabledUserIds]="disabledAgentsIds"
      [(selectedUsersIds)]="selectedAgentsIds"
      [configTable]="tableConfig"
    ></user-list>
  </div>
</ht-modal>
