<ng-container *ngIf="type === 'number'">{{
  value | number: '1.0-2'
}}</ng-container>
<ng-container *ngIf="type === 'bigNumber'">{{
  value | bigNumber
}}</ng-container>
<ng-container *ngIf="type === 'percent'">{{ value | percent }}</ng-container>
<ng-container *ngIf="type === 'time'">{{
  value | minutesSeconds
}}</ng-container>
<ng-container *ngIf="type === 'decimalPercent'">{{
  value | decimalPercent
}}</ng-container>
