import { Component, Input, OnInit } from '@angular/core'
import { BadgeType } from '../ht-badge/ht-badge.model'
export interface Tab {
  link: string
  text: string
  badge?: { text: string; type: BadgeType }
}

@Component({
  selector: 'ht-tab',
  templateUrl: './ht-tab.component.html',
  styleUrls: ['./ht-tab.component.scss'],
})
export class HtTabComponent implements OnInit {
  @Input()
  tabs: Tab[] = []

  constructor() {}

  ngOnInit(): void {}
}
