import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'
import { finalize, first, switchMap } from 'rxjs/operators'
import {
  EMPTY_FORM_FIELDS,
  NEWPASSWORD_MATCHES_CURRENTPASSWORD,
  NOT_IDENTICAL_PASSWORDS,
  PASSWORD_CHANGED,
  PASSWORD_MATCHES_USER_INFO,
} from '../../constants'
import { CustomError } from '../../constants/errors'
import { FeedbackService } from '../../services/feedback.service'
import { UserService } from '../../services/hubtype-api/user.service'
import { passwordMatchesUserInfo } from '../../utils/view-utils'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent {
  public feedback: string

  public userPassword: {
    currentPassword: string
    newPassword: string
    confirmationPassword: string
  } = {
    currentPassword: '',
    newPassword: '',
    confirmationPassword: '',
  }

  constructor(
    private ref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private feedbackService: FeedbackService,
    @Inject('userService') private userService: UserService
  ) {}

  onCancel() {
    this.dialogRef.close(true)
  }

  isFormDataValid(): boolean {
    if (
      this.userPassword.newPassword !== this.userPassword.confirmationPassword
    ) {
      this.feedback = NOT_IDENTICAL_PASSWORDS

      return false
    } else if (
      this.userPassword.currentPassword.length === 0 ||
      this.userPassword.newPassword.length === 0
    ) {
      this.feedback = EMPTY_FORM_FIELDS
      return false
    } else if (
      passwordMatchesUserInfo(this.userPassword.newPassword, this.data.user)
    ) {
      this.feedback = PASSWORD_MATCHES_USER_INFO

      return false
    } else if (
      this.userPassword.currentPassword === this.userPassword.newPassword
    ) {
      this.feedback = NEWPASSWORD_MATCHES_CURRENTPASSWORD
      return false
    }
    return true
  }

  changePasswordForm(form: HTMLFormElement) {
    this.ref.markForCheck()
    if (this.isFormDataValid()) {
      this.userService
        .changePassword(
          this.data.user.id,
          this.userPassword.currentPassword,
          this.userPassword.newPassword
        )
        .pipe(
          switchMap(() => this.userService.setOfflineAndLogout()),
          first(),
          finalize(() => this.ref.markForCheck())
        )
        .subscribe(
          () => {
            this.feedbackService.success(PASSWORD_CHANGED)
            this.dialogRef.close(true)
          },
          err => {
            form.resetForm({
              currentPassword: '',
              newPassword: '',
              confirmationPassword: '',
            })
            this.feedback = err?.error?.detail
            let errorMsg = CustomError.PASSWORD_CHANGE_FAILED.message
            if (this.feedback) {
              errorMsg += `: ${this.feedback}`
            }
            this.feedbackService.error(
              CustomError.PASSWORD_CHANGE_FAILED.code,
              errorMsg,
              err
            )
          }
        )
    }
  }
}
