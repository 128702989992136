import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'truncateLongString',
})
export class TruncateLongStringPipe implements PipeTransform {
  public readonly DELIMITER = '...'

  transform(value, leftCharacters: number, rightCharacters?: number): any {
    if (!value) {
      return ''
    }
    const safeLeftCharacters: number = Number(leftCharacters) || 0
    const safeRightCharacters: number = Number(rightCharacters) || 0
    const isTruncateNeeded =
      safeLeftCharacters + safeRightCharacters + this.DELIMITER.length <
      value.length

    if (!isTruncateNeeded) {
      return value
    }

    let result =
      safeLeftCharacters > 0
        ? `${value.slice(0, safeLeftCharacters)}${this.DELIMITER}`
        : safeLeftCharacters === 0
          ? this.DELIMITER
          : value

    result =
      safeRightCharacters > 0 &&
      value.length > safeLeftCharacters + safeRightCharacters
        ? `${result}${value.slice(-safeRightCharacters)}`
        : result

    return result
  }
}
