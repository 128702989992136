<div fxLayoutAlign="center center" fxLayout="column" class="mat-typography">
  <div
    [ngClass]="{ 'warn-header': data.type === WARNING_TYPE }"
    class="dialog-header"
    fxLayoutAlign="start center"
  >
    <h3 mat-dialog-title>{{ data.title }}</h3>
  </div>
  <div *ngIf="data.body" class="dialog-body">
    <div *ngIf="data.type === WARNING_TYPE" class="warning-text">WARNING</div>
    <span>{{ data.body }}</span>
  </div>
  <div class="dialog-footer" fxLayout="row" fxLayoutAlign="center center">
    <button mat-button (click)="dialogRef.close(false)">No</button>
    <button mat-raised-button (click)="dialogRef.close(true)">Yes</button>
  </div>
</div>
