import {
  MyOrganization,
  HubtypeOrganizationComplete,
} from './hubtype-organization'

const DEFAULT_HEART_BEAT_PERIOD_IN_SECONDS = 3 * 60

export class HubtypePusherApp {
  public organizationId: string
  public key: string
  public cluster: string
  public channelPrefix: string
  public statusChannel: string
  public heartBeatPeriodInSeconds: number

  constructor() {}

  static fromOrganization(
    org: HubtypeOrganizationComplete | MyOrganization
  ): HubtypePusherApp {
    const pusherApp = new HubtypePusherApp()
    pusherApp.organizationId = org.id
    pusherApp.key = org.settings.pusher.key
    pusherApp.cluster = org.settings.pusher.cluster
    pusherApp.channelPrefix = org.settings.pusher.channel_prefix
    pusherApp.statusChannel = org.settings.pusher.status_channel
    pusherApp.heartBeatPeriodInSeconds =
      org.settings.pusher.heart_beat_period_in_seconds

    return pusherApp
  }
}
