<div class="away-body">
  <div class="text-heading">Enjoy your break!</div>
  <div class="text-body">You are currently in 'Away' mode</div>

  <button ht-button-ds-primary (click)="close(false)">
    Turn off 'Away' mode
  </button>

  <button ht-button-ds-primary appearance="outline" (click)="close(true)">
    Continue in 'Away' mode
  </button>
</div>
