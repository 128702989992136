import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-screen-section',
  templateUrl: './screen-section.component.html',
  styleUrls: ['./screen-section.component.scss'],
})
export class ScreenSectionComponent implements OnInit {
  @Input()
  name = ''

  constructor() {}

  ngOnInit() {}
}
