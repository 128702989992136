import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'

@Component({
  selector: 'app-screen-header',
  templateUrl: './screen-header.component.html',
  styleUrls: ['./screen-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenHeaderComponent implements OnInit {
  @Input()
  action: string
  @Input()
  name = 'Header'
  @Input()
  disabled = false

  @Output()
  actionRun = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  onClickAction() {
    this.actionRun.emit()
  }
}
