<form
  class="connect-bg connect-channel"
  (ngSubmit)="addTelegram()"
  #telegramForm="ngForm"
  name="telegramForm"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
>
  <h2>Add Telegram</h2>
  <h3>Step 1. Create a Telegram Bot</h3>
  <p>
    You need to create a Bot from the Telegram app. Start a conversation with
    the
    <a href="https://telegram.me/BotFather" target="_blank">BotFather account</a
    >, type /newbot and follow the instructions there.
  </p>
  <p>
    The botUsername is what identifies your bot and it will not be possible to
    change it later.
  </p>
  <h3>Step 2. Add the Bot Username and Token</h3>
  <mat-form-field fxFlex>
    <input
      fxFlex
      matInput
      [(ngModel)]="telegramChannel.telegramBotName"
      #telegramName="ngModel"
      name="telegramName"
      type="text"
      placeholder="Bot Username"
      required
    />
  </mat-form-field>

  <mat-form-field fxFlex>
    <input
      fxFlex
      matInput
      [(ngModel)]="telegramChannel.telegramBotToken"
      #telegramBotToken="ngModel"
      name="telegramBotToken"
      type="text"
      placeholder="Token"
      [pattern]="pattern"
      required
    />
    <mat-hint align="start"
      ><strong
        >Copy the token that the BotFather sent you and paste it here</strong
      ></mat-hint
    >
  </mat-form-field>

  <button
    mat-raised-button
    fxFlex="none"
    type="submit"
    [disabled]="!telegramForm.form.valid"
  >
    Connect bot
  </button>
</form>
