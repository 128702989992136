<label [attr.for]="id">
  <input
    #radioButton
    (change)="onInputChange()"
    type="radio"
    role="radio"
    [id]="id"
    [value]="value"
    [name]="name"
    [disabled]="disabled"
    [attr.aria-label]="label" />
  <span><ng-content></ng-content></span
></label>
