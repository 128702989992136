import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import * as fromRoot from '../reducers'

@Injectable()
export class AdminRoleGuard {
  private url: string

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.url = state.url

    return this.store.select(fromRoot.getMeState).pipe(
      filter(x => Boolean(x)),
      map(me => {
        if (!me || !me.is_admin) {
          this.router.navigate(['/'])
          return false
        }
        return true
      })
    )
  }
}

@Injectable()
export class ManagerOrAdminRoleGuard {
  private url: string

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.url = state.url

    return this.store.select(fromRoot.getMeState).pipe(
      filter(x => Boolean(x)),
      map(me => {
        if (!me || me.is_agent) {
          this.router.navigate(['/'])
          return false
        }
        return true
      })
    )
  }
}
