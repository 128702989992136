import { Action } from '@ngrx/store'
import { HubtypePusherApp } from 'models/hubtype-pusher-app'

export enum InfrastructureActionType {
  FETCH_PUSHER_SETTINGS = '[INFRASTRUCTURE] FETCH PUSHER SETTINGS',
  UPDATE_PUSHER_SETTINGS = '[INFRASTRUCTURE] UPDATE PUSHER SETTINGS',
}

export class FetchPusherSettings implements Action {
  // Handled by an effect
  readonly type = InfrastructureActionType.FETCH_PUSHER_SETTINGS

  constructor() {}
}

export class UpdatePusherSettings implements Action {
  // Handled by a reducer
  readonly type = InfrastructureActionType.UPDATE_PUSHER_SETTINGS

  constructor(public payload: HubtypePusherApp) {}
}

export type InfrastructureAction = FetchPusherSettings | UpdatePusherSettings
