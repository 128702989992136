import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core'

export interface ChipAction {
  icon: string
  label: string
  onClick?: (data: any) => any
}

@Component({
  selector: 'ht-chip',
  templateUrl: './ht-chip.component.html',
  styleUrls: ['./ht-chip.component.scss'],
  host: { class: 'ht-chip', '(click)': 'handleClick($event)' },
})
export class HtChipComponent implements OnInit {
  @Input() actions: ChipAction[] = []
  @Input() data: any
  @Input() hideActions = false
  @Output() clickChip: EventEmitter<any> = new EventEmitter()

  constructor() {}

  @HostBinding('class.--with-actions') get hasActions() {
    return this.actions.length > 0
  }
  @HostBinding('class.--with-hidden-actions') get hasHiddenActions() {
    return this.hideActions
  }
  @HostBinding('class.--is-clickable') get isClickable() {
    return this.clickChip.observers.length > 0
  }

  ngOnInit(): void {}

  handleClick(event: MouseEvent) {
    if (this.isClickable) {
      event.stopPropagation()
      this.clickChip.emit(this.data)
    }
  }

  onClickAction(event: MouseEvent, action: ChipAction) {
    event.stopPropagation()
    if (typeof action.onClick === 'function') {
      action.onClick(this.data)
    }
  }
}
