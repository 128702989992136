import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Analytics } from 'utils/analytics'
import { DEFAULT_DATE_AND_HOUR_FORMAT } from 'utils/time-utils'

export interface RowData {
  [otherOptions: string]: unknown
  id: string
  children?: { [otherOptions: string]: unknown; id: string }[]
  expanded?: boolean
  iconClass?: string
}
export interface Column {
  name: string
  type:
    | 'string'
    | 'truncated'
    | 'icon'
    | 'percent'
    | 'time'
    | 'datetime'
    | 'circle'
  key: string
  textAlign?: 'start' | 'end' | 'center'
  width?: string
  tooltip?: string
}

export interface TableConfig {
  columns: Column[]
  pagination?: {
    enabled: boolean
    pageSize: number
    currentPage: number
  }
  isLoading?: boolean
  canExpandRows?: boolean
}

@Component({
  selector: 'ht-table-tiny',
  templateUrl: './ht-table-tiny.component.html',
  styleUrls: ['./ht-table-tiny.component.scss'],
})
export class HtTableTinyComponent {
  @Input()
  tableConfig: TableConfig = {
    columns: [],
    pagination: {
      enabled: false,
      currentPage: 1,
      pageSize: 1,
    },
    isLoading: false,
    canExpandRows: false,
  }

  @Input()
  expandChevronEvent: string

  @Output()
  rowClick = new EventEmitter()

  // eslint-disable-next-line @typescript-eslint/naming-convention
  _data: RowData[] = []

  // generate a random id for the table to avoid conflicts on other tables paginations
  tableId = `table-${Math.random()}`

  get data() {
    return this._data
  }

  @Input()
  set data(value: RowData[]) {
    this._data = value
  }

  DEFAULT_DATE_AND_HOUR_FORMAT = DEFAULT_DATE_AND_HOUR_FORMAT

  constructor() {}

  onRowClick(row) {
    this.rowClick.emit(row)

    const dataRow = this.data.find(dat => dat.id === row.id)
    if (dataRow) {
      dataRow.expanded = !dataRow.expanded
    }
    if (dataRow.expanded && this.expandChevronEvent) {
      Analytics.event(this.expandChevronEvent)
    }
  }

  onPageChange(page) {
    this.tableConfig.pagination.currentPage = page
  }
}
