import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core'
import { BannerBadgeConfig, BannerType } from './ht-banner.model'

@Component({
  selector: 'ht-banner',
  templateUrl: './ht-banner.component.html',
  styleUrls: ['./ht-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtBannerComponent {
  @Output() closed = new EventEmitter<void>()

  @Input() type = BannerType.Info
  @Input() leadIconClass: string
  @Input() badgeConfig: BannerBadgeConfig
  @Input() title = ''
  @Input() message = ''
  @Input() isClosable = true

  @HostBinding('class')
  get bannerClasses() {
    const typeClass = `--type-${this.type}`

    return {
      [typeClass]: true,
      '--is-closed': this.isClosed,
    }
  }

  isClosed = false

  constructor() {}

  closeBanner(event: Event) {
    event.stopPropagation()
    this.isClosed = true
    this.closed.emit()
  }
}
