import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { debounceTime } from 'rxjs/operators'

export enum BehaviourType {
  ENTER = 'enter',
  DEBOUNCE = 'debounce',
  ALL = 'all',
}
@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent implements OnInit, OnChanges {
  @Input()
  placeholder = 'Search'
  @Input()
  search = ''
  @Input()
  searchBehaviour: BehaviourType = BehaviourType.ENTER
  @Output()
  searchChange = new EventEmitter<string>()

  searchControl = new UntypedFormControl()
  applied = false

  constructor() {}

  ngOnInit(): void {
    if (
      this.searchBehaviour === BehaviourType.DEBOUNCE ||
      this.searchBehaviour === BehaviourType.ALL
    ) {
      this.searchControl.valueChanges
        .pipe(debounceTime(300))
        .subscribe(text => {
          this.applied = text !== ''
          this.searchChange.emit(text)
        })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const search = changes['search']?.currentValue || ''
    if (search !== this.searchControl.value) {
      this.searchControl.setValue(search)
      this.applied = search !== ''
    }
  }

  onClear() {
    this.applied = false
    this.searchControl.setValue('')
    this.searchChange.emit('')
  }

  onEnter() {
    if (
      this.searchBehaviour === BehaviourType.ENTER ||
      this.searchBehaviour === BehaviourType.ALL
    ) {
      const text = this.searchControl.value
      this.applied = text !== ''
      this.searchChange.emit(text)
    }
  }
}
