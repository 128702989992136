<div class="no-auth-page-container centered" *ngIf="expiredToken">
  <div class="title">
    <h1><i class="fa-solid fa-circle-exclamation"></i></h1>

    <p>This password reset link has expired.</p>
    <button
      ht-button-ds-primary
      appearance="minimal"
      [fullWidth]="true"
      routerLink="/reset-pwd"
    >
      Try resetting your password again
    </button>
  </div>
</div>
