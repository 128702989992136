<form
  class="connect-bg connect-channel"
  (ngSubmit)="addWhatsappPlayground()"
  [formGroup]="phoneForm"
  name="whatsappPlaygroundForm"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
>
  <h2>Add Whatsapp Playground</h2>
  <p>
    Whatsapp Playground allows you to test your bot with the
    <strong>official Whatsapp Business API</strong> without going through the
    hassle of getting the approval for the private beta.
  </p>
  <p>
    You have to whitelist the phone number from which you'll test the Whatsapp
    Playground integration. If you need to whitelist more than one phone number
    just add more Whatsapp Playground integrations (max 10).
  </p>
  <p><strong>Whitelist your phone number:</strong></p>
  <ngx-mat-intl-tel-input
    [preferredCountries]="['us', 'gb', 'es']"
    [enablePlaceholder]="true"
    [enableSearch]="true"
    required
    name="phone"
    formControlName="phone"
    #phone
  ></ngx-mat-intl-tel-input>
  <mat-hint>e.g. {{ phone.selectedCountry.placeHolder }}</mat-hint>

  <button
    mat-raised-button
    fxFlex="none"
    type="submit"
    [disabled]="!phoneForm.valid"
  >
    Connect Whatsapp Playground
  </button>
  <p>
    NOTE: This is intended for <strong>demos and testing purposes only</strong>,
    please, avoid using it for production applications. If you need the official
    integration please get in touch at
    <strong>support&#64;hubtype.com</strong>
  </p>
</form>
