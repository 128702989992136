export enum TableItemActionType {
  CLICK = 'click',
  DELETE = 'delete',
  EDIT = 'edit',
}

export enum TableColumnType {
  CRUD = 'crud',
  ICON = 'icon',
  SELECT = 'select',
  TEXT = 'text',
}

export interface TableDataItem {
  [key: string]: string | { [key: string]: string }
}

export interface TableItemAction {
  target: any
  type: TableItemActionType
}

export interface TableColumn {
  key: string
  name: string
  type: TableColumnType
  style?: { [key: string]: string }
}
