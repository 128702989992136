<div fxFlex fxLayout="column" class="mat-typography">
  <div class="dialog-header" fxLayoutAlign="start center">
    <h3 fxFlex="100" mat-dialog-title>Delete bot</h3>
    <button
      mat-icon-button
      fxFlex="none"
      fxFlexAlign="end"
      (click)="dialogRef.close()"
    >
      <mat-icon class="mat-24" aria-label="Close">clear</mat-icon>
    </button>
  </div>
  <div class="dialog-body" fxLayout="column wrap" fxLayoutAlign="center center">
    <p>
      Are you absolutely sure you want to delete <b>{{ data }}</b
      >?
    </p>
    <br />
    <p>
      Type the name of the bot in order to confirm.<b>
        This action cannot be undone.</b
      >
    </p>

    <div fxFlex="25">
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="userInput"
          value="userInput"
          name="name"
          type="text"
          placeholder="Type &quot;{{ data }}&quot; here"
          required
        />
      </mat-form-field>
    </div>
  </div>
  <div class="dialog-footer" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="dialogRef.close()">Cancel</button>
    <button
      mat-raised-button
      class="delete-button"
      [disabled]="!isValid()"
      (click)="dialogRef.close(data)"
    >
      Delete
    </button>
  </div>
</div>
