import {
  AfterViewChecked,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms'
import { Observable } from 'rxjs'
import {
  RIGHT_SIDE_VERTICAL_PADDING,
  TABLE_FOOTER_HEIGHT,
} from 'src/app/constants'

export interface TableColumnInterface {
  row: TableRow
  column?: Column
  isEditing?: boolean
}

export type ActionDeactivated = boolean | ((value: any) => boolean)
export interface Action {
  label: string
  icon?: string
  onClick: (data: any, row: TableRow) => any
  isDeactivated?: ActionDeactivated
  hasVisibleLabel?: boolean
}
export interface Tag {
  name: string
  amount: number
}

export interface HtTableSort {
  columnKey: string
  direction: SortDirection
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

const SORT_DIRECTION_LABEL = {
  [SortDirection.ASC]: 'ascending',
  [SortDirection.DESC]: 'descending',
}
export interface Column {
  name: string
  type?:
    | 'string'
    | 'tag'
    | 'dropdown'
    | 'profileAvatar'
    | 'truncated'
    | 'time'
    | 'datetime'
    | 'icon'
  key?: string
  class?: string | string[] | Set<string> | { [klass: string]: any }
  textAlign?: 'start' | 'end' | 'center'
  width?: string
  tooltip?: string
  placeholder?: string
  isDeactivated?: boolean
  component?: any
  canBeCopied?: boolean
  sortKey?: string
  sortable?: boolean

  /**
   * Optional type==='string'
   * */
  computedLabel?: (value: any) => string
  /**
   * Required type==='dropdown'
   * */
  dropDownList?: { id: string; value: string }[]
  validators?: ValidatorFn[]
}

export interface TableConfig {
  columns: Column[]
  defaultSort?: HtTableSort
  serverSideSort?: boolean
  pagination?: {
    enabled: boolean
    serverSide: boolean
    pageSize: number
    total?: number
    currentPage?: number
    onPageChangeServer?: (page: any) => void
  }
  fillAvailableHeight?: boolean
  hasBulkActions?: boolean
  actions?: Action[]
  expandable?: boolean | ((value: any) => boolean)

  onSaveOnRow?: (line: {
    [otherOptions: string]: unknown
    id: string
  }) => Observable<any>
  onRowClick?: (line: any) => void
  onRowSelected?: (Any) => void
  isLoading?: boolean
  emptyCase?: {
    title: string
    subtitle: string
    buttonText: string
    onButtonClick: (event: any) => void
  }
  selectable?: boolean
  clickable?: boolean
  hideHeader?: boolean
  singleOption?: boolean
}

export interface RowData {
  id: string
  children?: RowDataChildren[]
  [otherOptions: string]: unknown
}

export interface RowDataChildren {
  id: string
  [otherOptions: string]: unknown
}

export interface TableRow {
  id: string
  value: any
  formGroup: UntypedFormGroup
  children?: any[]
  expandable?: boolean
  isExpanded?: boolean
  toggleEdit: () => void
}

export const DEFAULT_ACTIONS = {
  EDIT: {
    label: 'Edit',
    icon: 'fa-pen-to-square',
  },
  EDIT_IN_ROW: {
    label: 'Edit',
    icon: 'fa-pen-to-square',
    onClick: (item, row: TableRow) => {
      row.toggleEdit()
    },
  },
  DELETE: {
    label: 'Delete',
    icon: 'fa-trash-can',
  },
}

export const SELECTED_CONTROL_NAME = 'selected'

@Component({
  selector: 'ht-table',
  templateUrl: './ht-table.component.html',
  styleUrls: ['./ht-table.component.scss'],
})
export class HtTableComponent implements AfterViewChecked, OnChanges, OnInit {
  @ContentChild('subRowsTemplate') subRowsTemplate: TemplateRef<any> | undefined

  @ViewChild('wrapper') wrapper: ElementRef

  @Input()
  config: TableConfig = {
    columns: [],
  }
  @Input()
  selectedIds: string[] = []
  @Input()
  activeRow: any
  @Input()
  disabledIds: string[] = []
  @Output()
  selectedIdsChange = new EventEmitter<any[]>()

  @Input() expandedRowsIds: string[] = []
  @Output() expandedRowsIdsChange = new EventEmitter<string[]>()

  @Input()
  get data(): RowData[] {
    return this.pData
  }
  set data(value) {
    this.pData = value
  }

  @Output()
  sortChanged: EventEmitter<HtTableSort> = new EventEmitter<HtTableSort>()

  SORT_DIRECTION = SortDirection
  SORT_DIRECTION_LABEL = SORT_DIRECTION_LABEL
  SORT_NEXT_DIRECTION_LABEL = {
    [SortDirection.ASC]: SORT_DIRECTION_LABEL[SortDirection.DESC],
    [SortDirection.DESC]: SORT_DIRECTION_LABEL[SortDirection.ASC],
  }

  tableId = `table-${Math.random()}`
  editId: string
  sortFormGroup = new UntypedFormGroup({
    direction: new UntypedFormControl(null),
    columnKey: new UntypedFormControl(null),
  })
  form: UntypedFormGroup = new UntypedFormGroup({
    rows: new UntypedFormArray([]),
    sort: this.sortFormGroup,
  })
  formEdit: UntypedFormGroup
  rows: TableRow[] = []
  hasSelectedRows = false
  sortable = false

  private defaultObj = { id: null }
  private pData: RowData[]

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngAfterViewChecked(): void {
    this.resizeTable()
  }

  ngOnInit(): void {
    this.initializeDefaultSort()
  }

  private initializeDefaultSort() {
    if (this.config.defaultSort) {
      this.sortFormGroup.setValue(this.config.defaultSort)
    }
  }

  ngOnChanges(): void {
    this.sortable = this.config.columns.some(column => column.sortable)
    this.updateRows()
    this.updateHasSelectedRows()
  }

  onResize() {
    this.resizeTable()
  }

  onColumnHeaderClick(column: Column) {
    this.sortByColumn(column)
  }

  private sortByColumn(column: Column) {
    if (!column.sortable) return
    const columnKey = this.getColumnSortKey(column)
    const currentSortColumnKey = this.sortFormGroup.value.columnKey
    if (columnKey === currentSortColumnKey) {
      this.toggleSortDirection()
    } else {
      this.setSortKey(columnKey)
    }
    this.sortChanged.emit({
      direction: this.sortFormGroup.controls['direction'].value,
      columnKey: columnKey,
    })
    this.sortRows(this.rows)
  }

  private sortRows(rows: TableRow[]) {
    if (this.sortable && !this.config.serverSideSort) {
      rows.sort((rowA, rowB) => {
        const { columnKey, direction } = this.sortFormGroup.value
        const aValue = rowA.value[columnKey]
        const bValue = rowB.value[columnKey]

        let comparison = 0
        if (typeof aValue === 'string') {
          comparison = aValue.localeCompare(bValue)
        } else {
          comparison = aValue - bValue
        }

        return direction === SortDirection.ASC ? comparison : -comparison
      })
    }
  }

  private getColumnSortKey(column: Column): string {
    return column.sortKey || column.key
  }

  private toggleSortDirection() {
    const currentDirection = this.sortFormGroup.controls['direction'].value
    const newDirection =
      currentDirection === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
    this.sortFormGroup.controls['direction'].setValue(newDirection)
  }

  private setSortKey(columnKey: string) {
    this.sortFormGroup.setValue({
      columnKey: columnKey,
      direction: SortDirection.ASC,
    })
  }

  onPageChange(page: number) {
    if (
      this.config.pagination.serverSide &&
      this.config.pagination.onPageChangeServer
    ) {
      this.config.pagination.onPageChangeServer(page)
    }

    this.config.pagination.currentPage = page
  }

  addNewItem(defaultItem?) {
    //If now is edit mode
    if (this.isCreatingNewItem()) {
      return
    }
    if (!this.data) {
      this.data = []
    }
    this.data.unshift({ ...defaultItem, ...this.defaultObj })
    this.editId = null
    this.updateRows()

    const rows: any = this.form.controls.rows
    this.formEdit = rows.controls[0] //We know the first control is the new form control
  }

  onRowClick(rowData: any) {
    if (this.config.selectable) {
      this.toggleSelectedRow(rowData.id)
      if (this.config.onRowSelected) {
        this.config.onRowSelected(this.selectedIds)
      }
    }
    if (this.config.onRowClick) {
      this.config.onRowClick(rowData)
    }
  }

  updateHasSelectedRows = () => {
    this.hasSelectedRows = !!this.selectedIds.length
  }

  onToggleRowExpand(row: TableRow) {
    if (row.isExpanded) {
      this.expandedRowsIds = [...new Set([...this.expandedRowsIds, row.id])]
    } else {
      this.expandedRowsIds = this.expandedRowsIds.filter(id => id !== row.id)
    }
  }

  getSelectedIds() {
    return this.form.value.rows
      .filter(({ selected }) => selected)
      .map(({ id }) => id)
  }

  //#region privateMethods
  private updateRows() {
    if (this.config && this.data) {
      const groups: UntypedFormGroup[] = []
      const rows: TableRow[] = []

      this.data.forEach(dataRow => {
        const newRow = this.generateRow(dataRow)
        rows.push(newRow)
        groups.push(newRow.formGroup)
      })

      this.form = this.formBuilder.group({
        rows: this.formBuilder.array(groups),
        sort: this.sortFormGroup,
      })

      this.sortRows(rows)
      this.rows = rows
    }
  }

  private generateRow(dataRow: any): TableRow {
    const group = this.getGroup(dataRow)

    return {
      id: dataRow.id || null,
      children: this.generateChildrenRows(dataRow.children),
      value: dataRow,
      formGroup: group,
      expandable: this.getIsExpandable(dataRow),
      isExpanded: this.getIsExpanded(dataRow),
      toggleEdit: () => {
        const isUpdating = this.editId === dataRow.id
        this.editId = isUpdating ? undefined : dataRow.id
        this.formEdit = isUpdating ? group : undefined
      },
    }
  }

  private generateChildrenRows(childrenDataRows: any[]): TableRow[] {
    if (childrenDataRows) {
      return childrenDataRows.map(child => ({
        id: child.id,
        value: child,
        formGroup: new UntypedFormGroup({}), // TODO: form group for children not supported yet
        expandable: false,
        isExpanded: false,
        toggleEdit: () => {},
      }))
    }
  }

  private getIsExpandable(dataRow: any): boolean {
    if (typeof this.config.expandable === 'function') {
      return this.config.expandable(dataRow)
    } else {
      return this.config.expandable
    }
  }

  private getIsExpanded(dataRow: any): boolean {
    return this.config.expandable
      ? this.expandedRowsIds.includes(dataRow.id)
      : false
  }

  private getGroup(dataRow): UntypedFormGroup {
    const obj = {}
    this.config.columns.forEach(column => {
      if (column.key) {
        obj[column.key] = new UntypedFormControl(
          dataRow[column.key],
          column.validators
        )
      }
    })
    obj['id'] = new UntypedFormControl(dataRow.id)
    if (this.config.selectable) {
      const isSelected = this.selectedIds?.includes(dataRow.id)
      const isDisabled = this.disabledIds?.includes(dataRow.id)
      obj[SELECTED_CONTROL_NAME] = new UntypedFormControl({
        value: isSelected,
        disabled: isDisabled,
      })
    }

    return this.formBuilder.group(obj)
  }

  private isCreatingNewItem(): boolean {
    return this.editId === this.defaultObj.id
  }

  private toggleSelectedRow(rowId: string) {
    const index = this.selectedIds.indexOf(rowId)
    const isSelected = index > -1
    if (isSelected) {
      this.selectedIds.splice(index, 1)
    } else {
      if (this.config.singleOption) {
        this.selectedIds = []
        this.selectedIds.push(rowId)
      } else {
        this.selectedIds.push(rowId)
      }
    }
    this.updateHasSelectedRows()
    this.setSelectedRowValue(rowId, !isSelected)
  }

  private setSelectedRowValue(rowId: string, isSelected: boolean) {
    const rows: any = this.form.controls.rows
    const rowToToggle = rows.controls.find(row => row.value.id === rowId)
    rowToToggle.get(SELECTED_CONTROL_NAME).setValue(isSelected)
    if (this.config.singleOption && isSelected) {
      const rowsToUntoggle = rows.controls.filter(row => row.value.id !== rowId)
      rowsToUntoggle.map(row => row.get(SELECTED_CONTROL_NAME).setValue(false))
    }
  }

  private resizeTable() {
    if (this.config.fillAvailableHeight) {
      const tableHeight =
        window.innerHeight -
        this.wrapper.nativeElement.offsetTop -
        RIGHT_SIDE_VERTICAL_PADDING * 2 -
        (this.config.hasBulkActions && this.hasSelectedRows
          ? TABLE_FOOTER_HEIGHT - RIGHT_SIDE_VERTICAL_PADDING
          : 0)
      this.wrapper.nativeElement.style['max-height'] = `${tableHeight}px`
    }
  }
  //#endregion
}
