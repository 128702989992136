import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  readonly WARNING_TYPE = ConfirmDialogType.WARNING
  readonly DANGER_TYPE = ConfirmDialogType.DANGER
  readonly CONFIRM_TYPE = ConfirmDialogType.CONFIRM
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}
}

export enum ConfirmDialogType {
  WARNING = 'warning',
  DANGER = 'danger',
  CONFIRM = 'confirm',
}
export interface ConfirmDialogData {
  title: string
  type: ConfirmDialogType
  body: string | undefined
}
