import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'ht-file-input',
  templateUrl: './ht-file-input.component.html',
  styleUrls: ['./ht-file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => HtFileInputComponent),
    },
  ],
})
export class HtFileInputComponent implements ControlValueAccessor {
  @ViewChild('fileInput', { static: false })
  myFileInput: ElementRef

  @Input()
  icon: string

  @Input()
  placeholder = ''

  @Input()
  width = ''

  @Input()
  disabled = false

  @Input()
  accept = '*'

  fileName = ''

  constructor() {}

  onChange = file => file
  onTouched = () => {}

  onInput(file: File) {
    if (!file) {
      return
    }
    this.fileName = file?.name || ''
    this.onTouched()
    this.onChange(file)
  }

  writeValue(file: File): void {
    this.fileName = file?.name || ''
    if (this.myFileInput && !file) {
      this.myFileInput.nativeElement.value = ''
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange
  }
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }
}
