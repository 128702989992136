<!-- Here all filters will be printed, only multiselects with 'multiselectWithChips' reference will have chips displayed -->
<ng-content></ng-content>

<div class="chips-group">
  <ht-chip-ds
    *ngFor="let chip of chips"
    class="chip"
    [data]="chip"
    (removeClick)="onRemoveChip($event)"
  ></ht-chip-ds>

  <button
    *ngIf="chips?.length > 0 && isClearFiltersEnabled"
    ht-button-ds-secondary
    appearance="minimal"
    class="clear-chips"
    (click)="onRemoveAllChips()"
    role="clear-filter-text"
  >
    Clear filters
  </button>
</div>
