import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { IPickerConfig } from '@easepick/core/dist/types'
import { DateRange } from 'models/date-range'
import { getMessageErrors } from 'models/form'
import { format } from 'utils/time-utils'
import { ErrorMessages } from './../../../models/form'
import { HtButtonDSAppearance, HtButtonDSIntent, HtButtonDSSize } from '../ht-button-ds/ht-button-ds.component'

// Extend IPickerConfig because the initial interface doesn't have a complete definition
declare module '@easepick/core/dist/types' {
  interface IPickerConfig {
    AmpPlugin: any
  }
}

/**
 * Instance of easepick library because there is a bug. Waiting to response
 * https://github.com/easepick/easepick/issues/103
 */
declare let easepick: any

export interface HtDatePickerConfig {
  button?: {
    intent: HtButtonDSIntent
    appearance: HtButtonDSAppearance
    size: HtButtonDSSize
  }
  numberOfCalendar?: number
  align?: 'left' | 'right'
  isNullable?: boolean
  nullablePresetText?: string
}

@Component({
  selector: 'ht-date-picker',
  templateUrl: './ht-date-picker.component.html',
  styleUrls: ['./ht-date-picker.component.scss'],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  host: { '[attr.aria-label]': 'label' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtDatePickerComponent),
      multi: true,
    },
  ],
})
export class HtDatePickerComponent
  implements AfterViewInit, OnChanges, OnInit, ControlValueAccessor
{
  @ViewChild('datepicker') htmlElementDatePicker
  @Input() dateRange: DateRange
  @Input() isDateRange = false
  @Input() hasPresets = false
  @Input() applyLabel = 'Apply'
  @Input() lockDateRange?: DateRange
  @Input() lockDates?: Date[]
  @Input() minDate?: Date
  @Input() maxDate?: Date
  @Input() noErrorMessage = false
  @Input() customErrors: ErrorMessages = {}
  @Input()
  config: HtDatePickerConfig = {
    numberOfCalendar: 1,
    align: 'left',
    button: {
      intent: HtButtonDSIntent.PRIMARY,
      appearance: HtButtonDSAppearance.OUTLINE,
      size: HtButtonDSSize.MEDIUM,
    }
  }
  @Input() label: string

  @Output()
  onchange = new EventEmitter<Date | DateRange>(null)

  @Output()
  openDatePicker = new EventEmitter<boolean>(null)

  @Output() valueChange = new EventEmitter<Date | DateRange>()

  readonly NULLABLE_PRESET_TEXT = 'No date'
  readonly TEXT_NO_DATE = 'Choose date'
  readonly MESSAGE_ERROR_CLASS = 'message-different-range'
  readonly MESSAGE_ERROR_TEXT =
    'Keep in mind you are comparing different data sizes'
  readonly MESSAGE_ERROR_ICON = 'fa-solid fa-circle-exclamation'

  control: UntypedFormControl
  innerValue: Date | DateRange
  datePicker: any
  textButton = ''
  error = ''
  styleFileName

  private touched!: (value: Date | DateRange) => void
  private changed!: (value: Date | DateRange) => void

  constructor(private ref: ChangeDetectorRef, private injector: Injector) {}

  get showError(): boolean {
    if (this.control && !this.noErrorMessage) {
      const hasError =
        !this.control.valid && this.control.errors && this.control.touched
      if (hasError) {
        this.error = getMessageErrors(this.control.errors, this.customErrors)
      }

      return hasError
    }
    return false
  }

  get value(): Date | DateRange {
    return this.innerValue
  }
  @Input() set value(value: Date | DateRange) {
    this.innerValue = value
    this.changed && this.changed(value)
    this.touched && this.touched(value)
    this.valueChange.emit(value)
  }

  writeValue(date: Date | DateRange): void {
    this.innerValue = date
  }

  registerOnChange(fn: (value: Date | DateRange) => void): void {
    this.changed = fn
  }

  registerOnTouched(fn: () => void): void {
    this.touched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    // TODO: implement disabled state
    // this.disabled = isDisabled
  }

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl)
    if (ngControl instanceof FormControlName) {
      this.control = this.injector.get(FormGroupDirective).getControl(ngControl)
    } else {
      this.control = (ngControl as FormControlDirective).form as UntypedFormControl
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.datePicker &&
      changes.lockDateRange &&
      changes.lockDateRange.currentValue.start &&
      changes.lockDateRange.currentValue.end
    ) {
      //On refresh lockDateRange
      this.datePicker.clear()
      this.initializeDatePicker()
    }
  }

  ngAfterViewInit(): void {
    this.initializeDatePicker()
    this.initializeDate()
  }

  open(): void {
    if (this.isDateRange) {
      const { start } = this.value as DateRange
      this.datePicker.gotoDate(start || new Date())
    } else {
      this.datePicker.gotoDate(this.value || new Date())
    }
    this.datePicker.show()
    this.openDatePicker.emit(true)
  }

  private onChange(event: any): void {
    if (this.isDateRange) {
      this.setRangeDates(event)
    } else {
      this.setDate(event)
    }
  }

  private setRangeDates(event: any): void {
    const start: Date = event.detail.start
    const end: Date = event.detail.end

    this.innerValue = { start, end }
    this.setRangeTextButton(start, end)
    this.ref.markForCheck()

    this.onchange.emit({ start, end })
    this.changed && this.changed({ start, end })
  }

  private setDate(event: any): void {
    const date: Date = event.detail.date
    this.innerValue = date
    this.setTextButton(date)
    this.ref.markForCheck()
    this.onchange.emit(date)
    this.changed && this.changed(date)
  }

  private initializeDatePicker() {
    this.ref.detectChanges()
    const datePickConfig: IPickerConfig = this.getDatePickConfig()
    this.datePicker = new easepick.create(datePickConfig)

    //On apply button
    this.datePicker.on('select', event => this.onChange(event))

    //On click on preset button click
    this.datePicker.on('click', event => this.onClickCalendar(event))
  }
  private initializeDate() {
    if (!this.value) {
      return this.setTextButton(null)
    }
    if (this.isDateRange) {
      const { start, end } = this.value as DateRange
      this.datePicker.setDateRange(start, end)
      this.setRangeTextButton(start, end)
    } else {
      this.datePicker.setDate(this.value)
      this.setTextButton(this.value)
    }
  }

  private isLockDate(date: Date): boolean | null {
    const dateTime = date.removeTime().getTime()
    if (this.lockDateRange) {
      return date.isBetweenInclusive(
        this.lockDateRange.start,
        this.lockDateRange.end
      )
    }
    if (this.lockDates) {
      return this.lockDates.some(
        lockDate => dateTime === lockDate?.removeTime().getTime()
      )
    }
    return false
  }

  private getPresetsConfig(): Record<string, unknown> {
    if (this.hasPresets) {
      return {
        PresetPlugin: { customPreset: this.getPresets() },
      }
    }
    return {}
  }

  private getLockConfig(): Record<string, unknown> {
    return {
      LockPlugin: {
        ...(this.minDate ? { minDate: this.minDate.removeTime() } : {}),
        ...(this.maxDate ? { maxDate: this.maxDate.removeTime() } : {}),
        inseparable: true,
        filter: (date: Date) => this.isLockDate(date),
      },
    }
  }

  private getPresets(): Record<string, unknown> {
    const presets = this.getDefaultPresets()

    if (this.config.isNullable) {
      presets[this.config.nullablePresetText || this.NULLABLE_PRESET_TEXT] = [
        new Date().subtract(2, 'years'),
        new Date().subtract(2, 'years'),
      ]
    }

    return presets
  }

  private getDefaultPresets(): Record<string, unknown> {
    const startDate = new Date().removeTime()
    return {
      Yesterday: [startDate.subtract(1, 'day'), startDate.subtract(1, 'day')],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Last 7 days': [startDate.subtract(6, 'days'), startDate],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Last 30 days': [startDate.subtract(29, 'days'), startDate],
    }
  }

  private getDatePickConfig(): IPickerConfig {
    const element = this.htmlElementDatePicker.nativeElement
    const plugins = [
      'AmpPlugin',
      'LockPlugin',
      ...(this.isDateRange ? ['RangePlugin'] : []),
      ...(this.hasPresets ? ['PresetPlugin'] : []),
    ]
    return {
      element,
      css: ['/ht-date-picker.css'],

      zIndex: 30,
      plugins,
      calendars: this.isDateRange ? 2 : 1,
      grid: this.isDateRange ? 2 : 1,
      autoApply: !this.isDateRange,
      locale: {
        apply: this.applyLabel,
      },
      AmpPlugin: {
        dropdown: {
          months: true,
          years: true,
          minYear: this.minDate
            ? this.minDate.getFullYear()
            : new Date().getFullYear() - 10,
          maxYear: this.maxDate
            ? this.maxDate.getFullYear()
            : new Date().getFullYear() + 5,
        },
        darkMode: false,
      },
      //Left panel with predefined days
      ...this.getPresetsConfig(),
      //Allow set month and year with dropdown
      //Lock days. In our case, if we have lock range date, we lock this range
      ...this.getLockConfig(),
    }
  }

  private onClickCalendar(event) {
    //Click on preset section
    if (event.target.classList.contains('preset-button')) {
      //Click on button is "No date"
      if (
        event.target.textContent.indexOf(
          this.config.nullablePresetText || this.NULLABLE_PRESET_TEXT
        ) >= 0
      ) {
        this.datePicker.clear()
        this.setTextButton(null)
        this.datePicker.hide()
        this.onchange.emit(null)
        this.ref.markForCheck()
      }
      //Go to date
      else {
        this.datePicker.gotoDate(event.target.dataset.start)
      }
    }
  }

  private setTextButton(date) {
    // avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.textButton = date ? format(date, 'MMM DD YYYY') : this.TEXT_NO_DATE
    }, 0)
  }

  private setRangeTextButton(start, end) {
    // avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      if (start && end) {
        this.textButton =
          format(start, 'MMM DD') + ' - ' + format(end, 'MMM DD')
      } else {
        this.textButton = this.TEXT_NO_DATE
      }
    }, 0)
  }
}
