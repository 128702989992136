import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import {
  HtButtonDSAppearance,
  HtButtonDSIntent,
} from '../ht-button-ds/ht-button-ds.component'

export interface HtModalOptionsBtn {
  show: boolean
  text?: string
  intent?: HtButtonDSIntent
  appearance?: HtButtonDSAppearance
  disabled?: boolean
  click?: any
  /**
   * To right align -> margin-left: auto
   * To left align -> leave it blank
   */
  style?: string
}
export interface HtModalOptions {
  notClosable?: boolean
  rightBtn?: HtModalOptionsBtn
  leftBtn?: HtModalOptionsBtn
  middleBtnArry?: HtModalOptionsBtn[]
  subtitle?: {
    text: string
    color?: 'default' | 'red'
  }
  title?: string
  width?: number
  size?: 'small'
}

@Component({
  selector: 'ht-modal',
  templateUrl: './ht-modal.component.html',
  styleUrls: ['./ht-modal.component.scss'],
  host: {
    role: 'dialog',
    '[attr.aria-label]': 'options.title || options.subtitle?.text',
  },
})
export class HtModalComponent implements OnInit {
  @Input()
  data: any = {}

  @Input()
  options: HtModalOptions = {
    title: '',
    leftBtn: {
      show: false,
    },
    rightBtn: { show: false },
    middleBtnArry: [],
  }

  @Output()
  closeModalOutput = new EventEmitter<any>()

  isOpen = false
  hostElement: any

  constructor(
    private elRef: ElementRef,
    private ref: ChangeDetectorRef
  ) {
    this.hostElement = this.elRef.nativeElement
  }

  @HostBinding(`class.--size-small`) get smallClass() {
    return !this.options.width && this.options.size === 'small'
  }
  @HostBinding('class.--size-custom') get customSizeClass() {
    return !!this.options.width
  }
  @HostBinding('class.--open') get openClass() {
    return this.isOpen
  }
  @HostBinding('class.--closed') get closedClass() {
    return !this.isOpen
  }

  public getData(): any {
    return this.data
  }

  ngOnInit(): void {}

  // Using this function knowing that is going to be executed several times. Is the less painfull way to hide the bar when an input change.
  shouldShowButtonBar() {
    return (
      this.options?.leftBtn?.show ||
      this.options?.rightBtn?.show ||
      this.options?.middleBtnArry?.some(x => x.show)
    )
  }

  setData(data) {
    this.data = data
  }

  open(data?: any) {
    if (data) {
      this.setData(data)
    }
    this.isOpen = true
  }

  close() {
    this.isOpen = false
    // TODO: investigate why this is necessary. We are forcing the change detection because sometimes, the component doesn't trigger it.
    this.ref.markForCheck()
    this.closeModalOutput.emit(true)
  }
}
