<h1 *ngIf="user.isAdvisorsEnabled" class="h-section-title">Holidays</h1>
<div
  *ngIf="user.isAdvisorsEnabled"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="holidays-form"
>
  <div
    fxLayout="row"
    *ngFor="let holiday of displayHolidays"
    class="holiday-container"
    #holidayRef
  >
    <div fxLayout="row" class="date-selectors">
      <mat-form-field>
        <label>Start</label>
        <input
          matInput
          [matDatepicker]="holidayStart"
          [(ngModel)]="holiday.range.startDate"
          #start_date="ngModel"
          name="{{ getRandomModelName() }}"
          [value]="holiday.range.startDate"
          [disabled]="!holiday.canEdit"
          [min]="todayDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="holidayStart"
        ></mat-datepicker-toggle>
        <mat-datepicker #holidayStart></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <label>End</label>
        <input
          matInput
          [matDatepicker]="holidayEnd"
          [(ngModel)]="holiday.range.endDate"
          #end_date="ngModel"
          name="{{ getRandomModelName() }}"
          [min]="holiday?.range?.startDate"
          [disabled]="!holiday.canEdit"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="holidayEnd"
        ></mat-datepicker-toggle>
        <mat-datepicker #holidayEnd></mat-datepicker>
      </mat-form-field>
    </div>
    <div
      *ngIf="holiday?.canEdit && !holiday?.confirmAddHoliday"
      fxLayoutAlign="center center"
      class="close"
      (click)="addHoliday(holiday)"
    >
      <mat-icon>done</mat-icon>
    </div>
    <div
      *ngIf="holiday?.canEdit && !holiday?.confirmDeleteHoliday"
      fxLayoutAlign="center center"
      class="close"
      (click)="holiday.confirmDeleteHoliday = true"
    >
      <mat-icon>delete_outline</mat-icon>
    </div>
    <div
      *ngIf="showRemove(holiday) && holiday?.canEdit"
      (click)="deleteHoliday(holiday)"
      class="remove"
    >
      Remove
    </div>
  </div>
  <div class="sections-button" fxLayoutAlign="start" fxLayout="column">
    <div fxLayout="row">
      <app-button
        color="secondary"
        text="Add More"
        size="m"
        (click)="addMore()"
      ></app-button>
      <p
        class="text-feedback"
        *ngIf="showFeedback(feedbackHoliday)"
        [ngClass]="{ success: !feedbackHolidayError }"
      >
        {{ feedbackHoliday }}
      </p>
    </div>
    <div
      *ngIf="showPastHolidays"
      fxLayoutAlign="start"
      class="see-past"
      (click)="seePastHolidays()"
    >
      See past holidays
    </div>
    <div
      *ngIf="!showPastHolidays"
      fxLayoutAlign="start"
      class="see-past"
      (click)="hidePastHolidays()"
    >
      Hide past holidays
    </div>
  </div>
</div>
