<div
  (click)="onChipClick()"
  class="chip"
  aria-label="chip"
  [ngClass]="{
    default: size === 'default',
    large: size === 'large',
    'chart-background-1': color === 'primary' || color === 1,
    'chart-background-2': color === 'aqua' || color === 2,
    'chart-background-3': color === 'neutral' || color === 3,
    'background-green': color === 'green',
    'background-yellow': color === 'yellow',
    'background-orange': color === 'orange',
    'background-red': color === 'red',
    'background-red-light': color === 'red-light'
  }"
>
  <span class="label no-wrap">{{ data.label }}</span>
  <i class="fa fa-xmark"></i>
</div>
