import { Component, EventEmitter, Output } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { HubtypeProviderAccount } from '../../../models/hubtype-provider-account'
import { processPhoneNumber } from '../../../utils/whatsapp-utils'

@Component({
  selector: 'app-channels-whatsapp-playground',
  templateUrl: './whatsapp-playground.component.html',
  styleUrls: ['./whatsapp-playground.component.scss'],
})
export class WhatsappPlaygroundComponent {
  phoneForm = new UntypedFormGroup({
    phone: new UntypedFormControl(''),
  })

  @Output() newChannel = new EventEmitter<HubtypeProviderAccount>()
  constructor() {}

  addWhatsappPlayground() {
    const allowed_user_phone = processPhoneNumber(this.phoneForm.value.phone)
    const provider = new HubtypeProviderAccount()
    provider.provider = HubtypeProviderAccount.WHATSAPP_PLAYGROUND
    provider.credentials_json = { allowed_user_phone }
    this.newChannel.emit(provider)
  }
}
