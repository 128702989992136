import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { Tab } from '../ht-tab/ht-tab.component'

@Component({
  selector: 'ht-content-header',
  templateUrl: './ht-content-header.component.html',
  styleUrls: ['./ht-content-header.component.scss'],
  host: {},
})
export class HtContentHeaderComponent implements OnInit {
  @Input() title: string
  @Input() subtitle?: string
  @Input() tabs?: Tab[] = []

  // avoid conflict with html title attribute: https://stackoverflow.com/questions/60528098/angular-using-title-as-input
  @HostBinding('attr.title') get getTitle(): null {
    return null
  }
  @HostBinding('class.--with-tabs') get withTabs() {
    return this.tabs.length
  }

  constructor() {}

  ngOnInit(): void {}
}
