<div fxFlex fxLayout="column" class="add-channels">
  <div *ngIf="step === 1" fxFlex fxLayout="column" fxLayoutAlign="start center">
    <button fxFlex="none" fxFlexAlign="end" mat-button (click)="back()">
      <mat-icon>close</mat-icon>
    </button>
    <div fxFlex fxLayout="column" fxLayoutAlign="start center">
      <div fxFlex="none">Select the messenger that you want to integrate:</div>
      <div
        fxFlex
        class="channel-list"
        fxLayout="row wrap"
        fxLayoutAlign="start start"
      >
        <div
          class="provider-button"
          *ngFor="let provider of providers"
          fxLayout="column"
          fxLayoutAlign="center center"
          (click)="selectProvider(provider)"
        >
          <app-provider-icon
            fxFlex="none"
            [provider]="provider"
          ></app-provider-icon>
          <div fxFlex="none">
            {{ getProviderName(provider) }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step === 2" fxFlex fxLayout="column">
    <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between stretch">
      <a fxFlex="none" mat-button (click)="init()"
        ><mat-icon>chevron_left</mat-icon> Select another messenger</a
      >
      <a fxFlex="none" mat-button (click)="back()"
        ><mat-icon>close</mat-icon></a
      >
    </div>

    <app-channels-telegram
      *ngIf="selectedProvider === 'telegram'"
      (newChannel)="providerCreated($event)"
    ></app-channels-telegram>
    <app-channels-facebook
      *ngIf="
        selectedProvider === 'facebook' || selectedProvider === 'instagram'
      "
      [provider]="selectedProvider"
      (newChannel)="providerCreated($event)"
    ></app-channels-facebook>
    <app-channels-twitter
      *ngIf="selectedProvider === 'twitter'"
      (newChannel)="providerCreated($event)"
    ></app-channels-twitter>
    <app-channels-zendesk
      *ngIf="selectedProvider === 'zendesk'"
      (newChannel)="providerCreated($event)"
    ></app-channels-zendesk>
    <app-channels-webchat
      *ngIf="selectedProvider === 'webchat'"
      (newChannel)="providerCreated($event)"
    ></app-channels-webchat>
    <app-channels-whatsapp
      *ngIf="selectedProvider === 'whatsapp_cloud'"
      [provider]="selectedProvider"
      (newChannel)="providerCreated($event)"
    ></app-channels-whatsapp>
    <app-channels-whatsapp-playground
      *ngIf="selectedProvider === 'whatsapp_playground'"
      (newChannel)="providerCreated($event)"
    ></app-channels-whatsapp-playground>

    <div *ngIf="selectedProvider === 'generic'" class="generic">
      <h3>
        Want to integrate a custom messaging channel like a web based chat or an
        in-app chat?
      </h3>
      <p>Contact us at support&#64;hubtype.com and we'll help you set it up.</p>
    </div>
  </div>

  <div
    *ngIf="step === 3"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-spinner [diameter]="32" [strokeWidth]="3.2"></mat-spinner>
    <h2 fxFlex="none">Connecting {{ providerAccount?.name }}...</h2>
  </div>

  <div *ngIf="step === 4" fxFlex fxLayout="column">
    <button fxFlex="none" fxFlexAlign="end" mat-button (click)="back()">
      <mat-icon>close</mat-icon>
    </button>
    <h2>Awesome!</h2>
    <p>Your integration is ready.</p>
    <p>
      The {{ providerAccount?.provider }} account "{{ providerAccount?.name }}"
      is now connected to <span *ngIf="queue">the queue {{ queue.name }}</span
      ><span *ngIf="bot">the bot {{ bot.name }}</span
      >.
    </p>
    <div *ngIf="selectedProvider === 'webchat'">
      <p>
        In order to use this Webchat, please copy and paste the following code
        in your <b>index.html</b> webpage.
      </p>
      <p>
        For more information, please read our full documentation.
        <a href="https://docs.botonic.io"> Botonic Docs </a>
      </p>
      <app-webchat-snippet
        [showCodeSnippet]="true"
        [providerAccount]="providerAccount"
      ></app-webchat-snippet>
    </div>
    <div *ngIf="selectedProvider === 'whatsapp_playground'">
      <p>You can now test your bot on this WhatsApp number:</p>
      <h3>{{ providerAccount?.username }}</h3>
    </div>
    <button
      mat-raised-button
      flex="none"
      type="button"
      class="main-btn"
      (click)="init()"
    >
      Add another messenger
    </button>
    <button mat-button flex="none" type="button" (click)="back()">
      Finish
    </button>
  </div>

  <div *ngIf="step === 5" fxFlex fxLayout="column">
    <button fxFlex="none" fxFlexAlign="end" mat-button (click)="back()">
      <mat-icon>close</mat-icon>
    </button>
    <h2>Ooops...</h2>
    <div fxFlex="none">
      <strong
        >There was a problem connecting {{ providerAccount?.name }}:
        {{ feedback }}</strong
      >
    </div>
    <button
      mat-raised-button
      flex="none"
      type="button"
      class="main-btn"
      (click)="this.step = 2"
    >
      Try again
    </button>
  </div>
</div>
