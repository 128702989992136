<div class="table-header-content">
  {{ column.name }}
  <i
    *ngIf="column.sortable"
    class="fa-solid"
    [ngClass]="{
      'fa-arrow-up-short-wide': (isSorting && isSortingAsc) || !isSorting,
      'fa-arrow-down-wide-short': isSorting && !isSortingAsc,
      'sortable-placeholder': !isSorting
    }"
  ></i>
</div>
