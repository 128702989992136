<div [ngClass]="{ disabled, checked }" [class]="type">
  <label class="checkbox-container" role="checkbox" [tabindex]="tabIndex">
    <input type="checkbox" [(ngModel)]="checked" [disabled]="disabled" />
    <span class="checkmark" [class]="style"></span>
    <ng-container *ngIf="type === 'button'">
      <div
        ht-button-ds
        [appearance]="checked ? 'solid' : 'outline'"
        [isDisabled]="disabled"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
      <div class="error-message" *ngIf="showError && error">
        {{ error }}
      </div>
    </ng-container>

    <ng-container *ngIf="type === 'checkbox'">
      <div class="checkbox-label">
        <ng-container *ngTemplateOutlet="content"></ng-container>
        <div class="error-message" *ngIf="showError && error">
          {{ error }}
        </div>
      </div>
    </ng-container>
  </label>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
