<div fxFlex fxLayout="column" class="mat-typography" tabindex="0">
  <div class="dialog-header" fxLayoutAlign="start center">
    <h3 fxFlex="100" mat-dialog-title>Ban user</h3>
    <i
      fxFlex="none"
      class="material-icons close-icon"
      fxLayoutAlign="end"
      (click)="onClose()"
      >clear</i
    >
  </div>
  <div fxLayout="column" class="dialog-body">
    <p>
      Once this action is confirmed you will no longer be able to contact<br />
      {{ data.endUserName.toUpperCase() }} via the platform. This action is
      irreversible.<br /><br />Do you want to permanently ban the user
      {{ data.endUserName }}?
    </p>
  </div>
  <div class="dialog-footer" fxLayout="row">
    <button
      fxFlexAlign="end"
      mat-raised-button
      color="secondary"
      type="button"
      (click)="onClose()"
    >
      cancel
    </button>
    <button
      fxFlexAlign="end"
      mat-raised-button
      color="primary"
      type="button"
      (click)="onClose(true)"
    >
      yes, ban this user
    </button>
  </div>
</div>
