import {
  FacebookLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login'
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { filter, finalize } from 'rxjs/operators'
import {
  HubtypeProviderAccount,
  WhatsappPhoneNumber,
} from '../../../models/hubtype-provider-account'
import { ProviderAccountService } from '../../../services/hubtype-api/provider-account.service'
import { AngularComponent } from '../../AngularComponent'

@Component({
  selector: 'app-channels-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss'],
})
export class WhatsappComponent extends AngularComponent implements OnInit {
  @Input() provider: string
  phoneNumbers: WhatsappPhoneNumber[]
  selectedPhoneNumber: WhatsappPhoneNumber
  loadingPhoneNumbers = false
  feedback = false

  @Output() newChannel = new EventEmitter<HubtypeProviderAccount>()

  constructor(
    @Inject('providerAccountService')
    private providerAccountService: ProviderAccountService,
    private ref: ChangeDetectorRef,
    private socialAuthService: SocialAuthService
  ) {
    super()
  }

  ngOnInit(): void {
    this.socialAuthService.authState.subscribe(user => {
      if (user.authToken) {
        // we check user.authToken to ensure the embedded signup process finished successfully
        this.getPhoneNumbers(user.authToken)
      } else {
        this.feedback = true
        console.log('SocialAuthService canceled. No user authtoken')
      }
    })
  }

  launchWhatsAppSignup() {
    const fbLoginOptions = {
      scope:
        'business_management,whatsapp_business_management,whatsapp_business_messaging',
      extras: {
        feature: 'whatsapp_embedded_signup',
      },
    }
    this.socialAuthService.signIn(
      FacebookLoginProvider.PROVIDER_ID,
      fbLoginOptions
    )
  }

  getPhoneNumbers(authToken: string) {
    this.feedback = false
    this.loadingPhoneNumbers = true

    this.providerAccountService
      .getWhatsappPhoneNumbers(this.provider, authToken)
      .pipe(
        filter(phoneNumbers => phoneNumbers && phoneNumbers.length > 0),
        finalize(() => {
          this.loadingPhoneNumbers = false
          this.ref.markForCheck()
        })
      )
      .subscribe(phoneNumbers => {
        if (phoneNumbers) {
          this.phoneNumbers = phoneNumbers
          this.selectedPhoneNumber = this.phoneNumbers[0]
        }
      })

    this.selectedPhoneNumber = this.phoneNumbers[0]
    this.loadingPhoneNumbers = false
  }

  addWhatsappCloud() {
    const provider = new HubtypeProviderAccount()
    provider.provider = this.provider
    provider.credentials_json = {
      waba_id: this.selectedPhoneNumber.waba_id,
      phone_number_id: this.selectedPhoneNumber.id,
      display_phone_name: this.selectedPhoneNumber.display_phone_name,
      display_phone_number: this.selectedPhoneNumber.display_phone_number,
    }
    this.newChannel.emit(provider)
  }
}
