<div class="webchat-settings" fxFlex fxLayout="column">
  <p>
    <strong>Note:</strong>
    Set <code>visibility:'dynamic'</code> in your webchat to enable these
    settings.
  </p>
  <mat-checkbox
    class="checkbox"
    [(ngModel)]="webchatSettings.visible"
    (change)="onCheck()"
    matTooltip="Only in Botonic versions +0.12.0"
    [matTooltipPosition]="'right'"
  >
    Visible
  </mat-checkbox>
  <app-whitelisted-urls
    [text]="getWhitelistedUrlsText()"
    matTooltip="Urls where webchat is authorized (or visible in Botonic +0.12.0). If none is set, any address will be valid."
    matTooltipPosition="right"
    (whitelistedUrlsFilled)="onWhitelistedUrlsFilled($event)"
  ></app-whitelisted-urls>

  <app-queue-selector
    [title]="'Schedule from Queue'"
    [allowNone]="true"
    [required]="false"
    [hint]="'Webchat will only be visible in selected queue hours'"
    [projectQueuesGroups]="projectQueuesGroups"
    (selectedQueueId)="onSelectedQueueId($event)"
    matTooltip="Only in Botonic versions +0.12.0"
    matTooltipPosition="right"
  ></app-queue-selector>
  <h5 *ngIf="showCurrentQueue">
    Scheduled to: <strong>{{ getQueueInfo() }}</strong>
  </h5>
</div>
