import { Component, forwardRef, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'ht-area',
  templateUrl: './ht-area.component.html',
  styleUrls: ['./ht-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => HtAreaComponent),
    },
  ],
})
export class HtAreaComponent implements ControlValueAccessor {
  @Input()
  disabled = false

  @Input()
  placeholder = ''

  @Input()
  height = '100px'

  @Input()
  showNumberOfCharacters = false

  @Input()
  maxNumberOfCharacters = 100

  value = ''
  numberOfCharacters = 0

  constructor() {}

  onChange = value => {}
  onTouched = () => {}

  onInput(value: string) {
    this.value = value
    this.onTouched()
    this.onChange(this.value)
    this.numberOfCharacters = value.length
  }
  writeValue(value: string): void {
    this.value = value
    this.numberOfCharacters = value?.length || 0
  }
  registerOnChange(onChange: any) {
    this.onChange = onChange
  }
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }
}
