import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ControlContainer, FormControl, UntypedFormArray } from '@angular/forms'
import { Subject, takeUntil } from 'rxjs'

@Component({
  selector: 'ht-checkbox-list',
  templateUrl: './ht-checkbox-list.component.html',
  styleUrls: ['./ht-checkbox-list.component.scss'],
})
export class HtCheckboxListComponent implements OnInit, OnDestroy {
  @Input() data: any[] = []
  @Input() labelKey = 'name'
  @Input() selectAllLabel = 'Select All'
  @Input() emptyMessage = 'No items to show'
  @Input() canMarkAll = false

  formArray: UntypedFormArray
  selectAllControl = new FormControl(false)

  private destroy$ = new Subject<void>()

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.formArray = this.controlContainer.control as UntypedFormArray

    this.initializeListeners()
  }

  private initializeListeners(): void {
    this.formArray.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateSelectedAllState()
    })

    this.selectAllControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(selectAll => {
        this.toggleSelectAll(selectAll)
      })

    this.formArray.updateValueAndValidity({ onlySelf: true })
  }

  toggleSelectAll(selectAll: boolean): void {
    this.formArray.controls.forEach(control => {
      const checkboxControl = control.get('selected')
      if (!checkboxControl.disabled) {
        checkboxControl.setValue(selectAll)
      }
    })
  }

  updateSelectedAllState(): void {
    const areAllItemsSelected = this.formArray.controls
      .filter(control => !control.get('selected').disabled)
      .every(control => control.get('selected').value)

    this.selectAllControl.setValue(areAllItemsSelected, { emitEvent: false })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }
}
