import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { DateRange } from 'models/date-range'
import { format, getDuration } from 'utils/time-utils'
import {
  HtButtonDSAppearance,
  HtButtonDSSize,
} from '../ht-button-ds/ht-button-ds.component'

/**
 * Instance of easepick library because there is a bug. Waiting to response
 * https://github.com/easepick/easepick/issues/103
 */
declare let easepick: any

@Component({
  selector: 'ht-date-picker-analytics',
  templateUrl: './ht-date-picker-analytics.component.html',
  styleUrls: ['./ht-date-picker-analytics.component.scss'],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  host: { '[attr.aria-label]': 'label' },
})
export class HtDatePickerAnalyticsComponent
  implements AfterViewInit, OnChanges
{
  @ViewChild('datepicker') htmlElementDatePicker
  @Input() dateRange: DateRange

  @Input()
  lockDateRange: DateRange

  @Input()
  config: {
    numberOfCalendar: 1
    button: {
      size: HtButtonDSSize.MEDIUM
      appearance: HtButtonDSAppearance.SOLID
    }
    align: 'left' | 'right'
  }

  @Input()
  label: string

  @Output()
  applyClick = new EventEmitter<DateRange>(null)

  @Output()
  openDatePicker = new EventEmitter<boolean>(null)

  readonly TEXT_NO_COMPARISON = 'No comparison'
  readonly TEXT_NO_DATE = 'No date'
  readonly MESSAGE_ERROR_CLASS = 'message-different-range'
  readonly MESSAGE_ERROR_TEXT =
    'Keep in mind you are comparing different data sizes'
  readonly MESSAGE_ERROR_ICON = 'fa-solid fa-circle-exclamation'

  datePicker: any
  textButton = ''
  styleFileName
  constructor(private ref: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.datePicker &&
      changes.lockDateRange &&
      changes.lockDateRange.currentValue.start &&
      changes.lockDateRange.currentValue.end
    ) {
      //On refresh lockDateRange
      this.datePicker.clear()
      this.initializeDatePicker()
    }
  }

  ngAfterViewInit(): void {
    this.initializeDatePicker()
  }

  open(): void {
    this.datePicker.show()
    if (this.lockDateRange) {
      this.datePicker.gotoDate(this.lockDateRange.start)
    }
    this.openDatePicker.emit(true)
  }

  private initializeDatePicker() {
    this.ref.detectChanges()
    const elementDatePicker = this.htmlElementDatePicker.nativeElement
    const datePickConfig = this.getDatePickConfig(elementDatePicker)
    this.datePicker = new easepick.create(datePickConfig)

    this.initializeDate()
    //On apply button
    this.datePicker.on('select', event => this.onClickApply(event))

    //On click on preset button click
    this.datePicker.on('click', event => this.onClickCalendar(event))
  }
  private initializeDate() {
    if (this.dateRange) {
      this.datePicker.setDateRange(this.dateRange.start, this.dateRange.end)
      this.setTextButton(this.dateRange.start, this.dateRange.end)
    } else {
      this.setTextButton(null, null)
    }
  }

  private isLockDate(date: Date): boolean | null {
    return this.lockDateRange
      ? date.isBetweenInclusive(
          this.lockDateRange.start,
          this.lockDateRange.end
        )
      : null
  }

  private getMaxDate(): Date {
    return this.lockDateRange ? this.lockDateRange.start : new Date()
  }

  private getPresets(): Record<string, unknown> {
    if (this.lockDateRange) {
      return this.getComparePresets(this.lockDateRange.start.removeTime())
    } else {
      return this.getBasicPresets(new Date().removeTime())
    }
  }

  private getComparePresets(startDate: Date): Record<string, unknown> {
    const preset = {
      Yesterday: [startDate.subtract(1, 'day'), startDate.subtract(1, 'day')],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Last 7 days': [
        startDate.subtract(7, 'days'),
        startDate.subtract(1, 'days'),
      ],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Last 30 days': [
        startDate.subtract(30, 'days'),
        startDate.subtract(1, 'days'),
      ],
    }
    //Add "no comparison" button
    //We need to add start and end date because the plugin need it, but we don't use it. Check function onClickOnCalendar
    preset[this.TEXT_NO_COMPARISON] = [
      new Date().subtract(2, 'years'),
      new Date().subtract(2, 'years'),
    ]

    return preset
  }

  private getBasicPresets(startDate: Date): Record<string, unknown> {
    return {
      Yesterday: [startDate.subtract(1, 'day'), startDate.subtract(1, 'day')],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Last 7 days': [startDate.subtract(6, 'days'), startDate],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Last 30 days': [startDate.subtract(29, 'days'), startDate],
    }
  }

  private getDatePickConfig(elementDatePicker) {
    return {
      element: elementDatePicker,
      css: ['/ht-date-picker.css'],

      zIndex: 30,
      plugins: ['RangePlugin', 'PresetPlugin', 'AmpPlugin', 'LockPlugin'],
      calendars: this.config.numberOfCalendar || 1,
      grid: this.config.numberOfCalendar || 1,
      autoApply: false,
      locale: {
        apply: this.lockDateRange ? 'Apply comparison' : 'Apply',
      },
      //Left panel with predefined days
      PresetPlugin: {
        customPreset: this.getPresets(),
      },

      //Allow set month and year with dropdown
      AmpPlugin: {
        dropdown: {
          months: true,
          years: true,
        },
        darkMode: false,
      },

      //Lock days. In our case, if we have lock range date, we lock this range
      LockPlugin: {
        maxDate: this.getMaxDate(),
        inseparable: true,
        filter: (date, picked) => this.isLockDate(date as Date),
      },
    }
  }

  private onClickApply(event) {
    const start: Date = event.detail.start
    const end: Date = event.detail.end

    this.setTextButton(start, end)
    this.ref.markForCheck()

    this.applyClick.emit({ start, end })
  }

  private onClickCalendar(event) {
    //Click on preset section
    if (event.target.classList.contains('preset-button')) {
      //Click on button is "No comparison"
      if (event.target.textContent.indexOf(this.TEXT_NO_COMPARISON) >= 0) {
        this.datePicker.clear()
        this.setTextButton(null, null)
        this.datePicker.hide()
        this.applyClick.emit(null)
        this.ref.markForCheck()
      }
      //Go to date
      else {
        this.datePicker.gotoDate(event.target.dataset.start)
        this.checkDiffRangeError(
          event.target.dataset.start,
          event.target.dataset.end
        )
      }
    }

    //Click on end day
    if (event.target.classList.contains('end')) {
      this.onClickDay()
    }
  }

  private setTextButton(start, end) {
    if (start && end) {
      this.textButton = format(start, 'MMM DD') + ' - ' + format(end, 'MMM DD')
    } else {
      this.textButton = this.lockDateRange
        ? this.TEXT_NO_COMPARISON
        : this.TEXT_NO_DATE
    }
  }

  private onClickDay() {
    const start =
      this.datePicker.ui.shadowRoot.querySelector('.start')?.attributes[
        'data-time'
      ].textContent
    const end =
      this.datePicker.ui.shadowRoot.querySelector('.end')?.attributes[
        'data-time'
      ].textContent

    if (start && end) {
      this.checkDiffRangeError(start, end)
    }
  }

  //#region Range error

  private checkDiffRangeError(start: number, end: number) {
    const startDate = new Date(Number(start))
    const endDate = new Date(Number(end))

    //Set difference range error message
    if (this.isDifferentRange(startDate, endDate)) {
      this.setDifferenceRangeError()
    } else {
      this.cleanDifferenceRangeError()
    }
  }

  private setDifferenceRangeError() {
    const icon = document.createElement('i')
    icon.className = this.MESSAGE_ERROR_ICON

    const message = document.createElement('div')
    message.className = 'text-small'
    message.textContent = this.MESSAGE_ERROR_TEXT

    const container = document.createElement('div')
    container.className = this.MESSAGE_ERROR_CLASS
    container.append(icon)
    container.append(message)

    //Add to footer
    this.datePicker.ui.shadowRoot.querySelector('footer').prepend(container)
  }

  private cleanDifferenceRangeError() {
    const miclaseSelector = this.datePicker.ui.shadowRoot
      .querySelector('footer')
      .querySelector(this.MESSAGE_ERROR_CLASS)

    if (miclaseSelector) {
      miclaseSelector.remove()
    }
  }

  private isDifferentRange(start: Date, end: Date): boolean {
    if (this.lockDateRange) {
      //Days difference
      const days = getDuration(
        start.removeTime().getTime(),
        end.removeTime().getTime()
      ).asDays()

      //Locked days difference
      const lockedDays = getDuration(
        this.lockDateRange.start.removeTime().getTime(),
        this.lockDateRange.end.removeTime().getTime()
      ).asDays()

      if (days !== lockedDays) {
        return true
      }
    }
    return false
  }

  //#endregion
}
