<div class="phone-input-container">
  <div class="country-dropdown">
    <div [ngClass]="isDropdownOpen ? 'opened' : ''" class="dropdown-menu">
      <ht-search-bar
        placeholder="Search..."
        [value]="searchValue"
        (search)="onSearch($event)"
        size="small"
        type="secondary"
      ></ht-search-bar>
      <div class="country-list">
        <ng-container *ngFor="let country of preferredCountriesInDropDown">
          <ng-container
            *ngTemplateOutlet="countryTemplate; context: { country: country }"
          ></ng-container>
        </ng-container>
        <hr *ngIf="preferredCountriesInDropDown?.length" />
        <ng-container *ngFor="let country of filteredCountries">
          <ng-container *ngIf="!preferredCountriesInDropDown.includes(country)"
            ><ng-container
              *ngTemplateOutlet="countryTemplate; context: { country: country }"
            ></ng-container
          ></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <button class="country-btn" type="button" (click)="toggleDropdown($event)">
    <div>
      <div class="flag" [ngClass]="selectedCountry.flagClass"></div>
    </div>
    +{{ selectedCountry.dialCode }}
    <i class="fa fa-chevron-down"></i>
  </button>
  <input
    type="tel"
    [(ngModel)]="phoneNumber"
    (ngModelChange)="onPhoneNumberChange()"
    placeholder="Phone number"
    (blur)="onTouched()"
    (keypress)="onInputKeyPress($event)"
    [disabled]="disabled"
    [attr.aria-label]="label"
  />
</div>

<ng-template #countryTemplate let-country="country">
  <button
    class="country"
    [ngClass]="country === selectedCountry ? 'selected' : ''"
    (click)="onCountrySelect(country, focusable); $event.preventDefault()"
  >
    <div>
      <div class="flag" [ngClass]="country.flagClass"></div>
    </div>
    <div class="label-wrapper">
      {{ country.name }} +{{ country.dialCode }}
    </div>
  </button>
</ng-template>
