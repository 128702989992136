<mat-form-field>
  <mat-label>{{ title }}</mat-label>
  <mat-select
    [required]="required"
    [formControl]="queueControl"
    (selectionChange)="onSelectionChange($event.value)"
  >
    <mat-option *ngIf="allowNone">None</mat-option>
    <mat-optgroup
      *ngFor="let group of projectQueuesGroups"
      [label]="group.name"
    >
      <mat-option *ngFor="let queue of group.queues" [value]="queue.id">
        {{ queue.name }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
  <mat-hint *ngIf="hint" class="hint">{{ hint }}</mat-hint>
</mat-form-field>
