import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'

export const enum HtIconButtonAppearance {
  SQUARE = 'square',
  ROUNDED = 'rounded',
}

@Component({
  selector: '[ht-icon-button]',
  templateUrl: './ht-icon-button.component.html',
  styleUrls: ['./ht-icon-button.component.scss'],
  host: {
    '[attr.aria-disabled]': 'isDisabled || isLoading',
  },
})
export class HtIconButtonComponent implements OnInit, OnChanges {
  @HostBinding('class') modifierClasses = []

  @Input() iconClass = ''

  @Input() isDisabled = false

  @Input() appearance = HtIconButtonAppearance.SQUARE

  constructor() {}

  ngOnInit(): void {
    this.updateModifierClasses()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.intent || changes.size || changes.appearance) {
      this.updateModifierClasses()
    }
  }

  private updateModifierClasses(): void {
    this.modifierClasses = [
      'ht-icon-button',
      `ht-icon-button--${this.appearance}`,
    ]
  }
}
