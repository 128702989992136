<div class="search-field">
  <mat-icon class="search-icon">search</mat-icon>
  <input
    autocomplete="off"
    [placeholder]="placeholder"
    [formControl]="searchControl"
    (keyup.enter)="onEnter()"
    role="searchbox"
  />
  <button
    mat-button
    mat-icon-button
    aria-label="Clear"
    [hidden]="!applied"
    (click)="onClear()"
    role="button"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
