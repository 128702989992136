<table class="table-tiny" cellspacing="0">
  <!--Header-->
  <thead>
    <tr>
      <!--Expand icon-->
      <th class="expand-icon" *ngIf="tableConfig.canExpandRows"></th>

      <th
        [ngStyle]="{ width: column.width, 'text-align': column.textAlign }"
        *ngFor="let column of tableConfig.columns"
        [tooltip]="column.tooltip"
      >
        {{ column.name }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="tableConfig.isLoading === true">
      <tr *ngFor="let i of [1, 2, 3]">
        <td class="loading" colspan="100%"><div *loading="true"></div></td>
      </tr>
    </ng-container>
    <ng-container *ngIf="tableConfig.isLoading === false">
      <ng-container
        *ngFor="
          let row of data
            | paginate
              : {
                  id: tableId,
                  itemsPerPage: tableConfig.pagination.pageSize,
                  currentPage: tableConfig.pagination.currentPage
                }
        "
      >
        <!--Parent rows-->
        <tr
          role="row-parent"
          class="row-parent"
          [ngClass]="{ active: row.active }"
          (click)="onRowClick(row)"
        >
          <!--Expand icon-->
          <td *ngIf="tableConfig.canExpandRows" class="expand-icon">
            <i *ngIf="row.expanded" class="fa fa-chevron-up"></i>
            <i *ngIf="!row.expanded" class="fa fa-chevron-down"></i>
          </td>

          <ng-container
            *ngFor="
              let columnRow of tableConfig.columns;
              let indexParent = index
            "
          >
            <td
              [ngStyle]="{
                width: columnRow.width,
                'text-align': columnRow.textAlign
              }"
              [ngClass]="{
                'first-cell': indexParent === 0 && tableConfig.canExpandRows,
                'second-cell': indexParent === 1 && tableConfig.canExpandRows
              }"
            >
              <ng-container *ngIf="columnRow.type === 'string'">
                <div class="content-cell">{{ row[columnRow.key] }}</div>
              </ng-container>
              <ng-container *ngIf="columnRow.type === 'truncated'">
                <span [tooltip]="row[columnRow.key]">
                  {{ row[columnRow.key] | truncateLongString: 4 : 4 }}
                </span>
              </ng-container>
              <ng-container *ngIf="columnRow.type === 'percent'">
                {{ row[columnRow.key] ? (row[columnRow.key] | percent) : '--' }}
              </ng-container>
              <ng-container *ngIf="columnRow.type === 'time'">
                {{
                  row[columnRow.key]
                    ? (row[columnRow.key] | minutesSeconds)
                    : '--'
                }}
              </ng-container>
              <ng-container *ngIf="columnRow.type === 'datetime'">
                {{
                  row[columnRow.key]
                    ? (row[columnRow.key] | date: DEFAULT_DATE_AND_HOUR_FORMAT)
                    : '--'
                }}
              </ng-container>
              <ng-container *ngIf="columnRow.type === 'icon'">
                <i
                  *ngFor="let icon of row[columnRow.key]"
                  [ngClass]="icon.iconClass"
                  [tooltip]="icon.tooltip"
                >
                </i>
              </ng-container>
              <ng-container *ngIf="columnRow.type === 'circle'">
                <div
                  class="circle"
                  [ngClass]="'chart-background-' + row[columnRow.key]"
                ></div>
              </ng-container>
            </td>
          </ng-container>
        </tr>

        <!--Child rows-->
        <ng-container *ngIf="row.children && row.children.length > 0">
          <tr
            role="row-child"
            [ngStyle]="{ display: row.expanded ? 'table-row' : 'none' }"
            *ngFor="let rowChild of row.children"
            class="row-child"
          >
            <td></td>
            <ng-container
              *ngFor="
                let columnRow of tableConfig.columns;
                let indexChild = index
              "
            >
              <td
                [ngStyle]="{
                  width: columnRow.width,
                  'text-align': columnRow.textAlign
                }"
                [ngClass]="{
                  'second-cell': indexChild === 1 && tableConfig.canExpandRows,
                  'first-cell': indexChild === 0 && tableConfig.canExpandRows
                }"
              >
                <ng-container *ngIf="columnRow.type === 'string'">
                  <div class="content-cell">{{ rowChild[columnRow.key] }}</div>
                </ng-container>
                <ng-container *ngIf="columnRow.type === 'icon'">
                  <i
                    *ngFor="let icon of rowChild[columnRow.key]"
                    [ngClass]="icon.iconClass"
                    [tooltip]="icon.tooltip"
                  >
                  </i>
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<!--Paginator-->
<pagination-controls
  [id]="tableId"
  (pageChange)="onPageChange($event)"
  (pageBoundsCorrection)="onPageChange($event)"
  autoHide="true"
  class="pagination-tiny"
></pagination-controls>
