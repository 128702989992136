import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

export interface HtFilterChipData {
  id: string
  label: string
}

@Component({
  selector: 'ht-filter-chip',
  templateUrl: './ht-filter-chip.component.html',
  styleUrls: ['./ht-filter-chip.component.scss'],
})
export class HtFilterChipComponent implements OnInit {
  @Input()
  data: HtFilterChipData = { id: '', label: 'defalt' }

  @Input()
  size: 'default' | 'large' = 'default'

  @Input()
  color:
    | 1
    | 2
    | 3
    | 'neutral'
    | 'primary'
    | 'aqua'
    | 'green'
    | 'yellow'
    | 'orange'
    | 'red'
    | 'red-light' = 'neutral'

  @Output()
  removeClick = new EventEmitter<string>()

  constructor() {}

  ngOnInit(): void {}

  onChipClick() {
    this.removeClick.emit(this.data.id)
  }
}
