<form
  class="connect-bg connect-channel"
  (ngSubmit)="addWebchat()"
  #webchatForm="ngForm"
  name="webchatForm"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
>
  <h2>Add Webchat</h2>
  <p>
    Adding chat capabilities to your website or mobile app will be as easy as
    adding Google Analytics.
  </p>
  <p>
    You can integrate as many webchat as you want. In order to achieve that you
    have to give us a unique name for this integration.
  </p>

  <mat-form-field fxFlex>
    <input
      fxFlex
      matInput
      [(ngModel)]="webchatChannelName"
      #webchatName="ngModel"
      name="webchatName"
      type="text"
      placeholder="Webchat name"
      required
    />
  </mat-form-field>

  <mat-divider class="webchat-settings-divider"></mat-divider>
  <app-webchat-settings
    [webchatSettings]="webchatSettings"
    (webchatSettingsChanged)="onWebchatSettingsChanged($event)"
  ></app-webchat-settings>

  <button
    mat-raised-button
    fxFlex="none"
    type="submit"
    [disabled]="!webchatForm.form.valid"
  >
    Enable Webchat
  </button>
</form>
