<ng-content></ng-content>
<div class="ht-chip-actions" *ngIf="hasActions">
  <button
    ht-icon-button
    *ngFor="let action of actions"
    [attr.aria-label]="action.label"
    (click)="onClickAction($event, action)"
  >
    <i class="fa-solid {{ action.icon }}"></i>
  </button>
</div>
