import { BadgeType } from '../ht-badge/ht-badge.model'

export enum BannerType {
  Info = 'info',
  Neutral = 'neutral',
  Error = 'error',
  Alert = 'alert',
  AI = 'ai',
}

export interface BannerBadgeConfig {
  text: string
  type: BadgeType
}
