<div class="select-all" *ngIf="canMarkAll && data.length > 1">
  <ht-checkbox [formControl]="selectAllControl">{{
    selectAllLabel
  }}</ht-checkbox>
</div>
<div class="list" *ngIf="data.length" [formGroup]="formArray">
  <ng-container
    *ngFor="let dataItem of data; index as i"
    [formGroup]="formArray.controls[i]"
  >
    <ht-checkbox
      [label]="dataItem[labelKey]"
      [formControl]="formArray.controls[i].controls['selected']"
      >{{ dataItem[labelKey] }}</ht-checkbox
    >
  </ng-container>
</div>
<div class="empty-message" *ngIf="!data.length">
  {{ emptyMessage }}
</div>
