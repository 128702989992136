import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { Store } from '@ngrx/store'
import { environment } from 'environment/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import * as fromRoot from '../reducers'

@Injectable()
export class AuthGuard {
  constructor(
    private router: Router,
    private store: Store<fromRoot.State>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.select(fromRoot.isLoggedIn).pipe(
      map(loggedId => {
        if (!loggedId) {
          if (environment.minimalVersion) {
            this.router.navigate(['/sign-in'])
          } else {
            this.router.navigate(['/sign-in'], {
              queryParams: { returnUrl: state.url },
            })
          }
        }
        return loggedId
      })
    )
  }
}
