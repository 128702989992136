import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core'
import {
  HtMultiselectDropdownComponent,
  MultiselectDropdownData,
  DropdownSelectableItem,
} from '../ht-multiselect-dropdown/ht-multiselect-dropdown.component'
import { merge, takeUntil } from 'rxjs'
import { cloneValue } from 'utils/json-utils'
import { HtChipDSData } from '../ht-chip-ds/ht-chip-ds.component'

@Component({
  selector: 'app-ht-filter-chip-group',
  templateUrl: './ht-filter-chip-group.component.html',
  styleUrls: ['./ht-filter-chip-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtFilterChipGroupComponent implements AfterContentInit, OnDestroy {
  @Output() removeChip = new EventEmitter<string>()
  @Output() removeAll = new EventEmitter<void>()

  @Input() isClearFiltersEnabled = true

  @ContentChildren('multiselectWithChips')
  multiselects: QueryList<HtMultiselectDropdownComponent>

  chips: HtChipDSData[] = []

  private destroy$ = new EventEmitter<void>()

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.initializeMultiselectEventsListener()
  }

  onRemoveChip(id: string): void {
    const chipToRemove = this.chips.find(chip => chip.id === id)

    const multiselectsData = this.multiselects.map(
      multiselect => multiselect.data
    )
    let elementToUncheck: DropdownSelectableItem
    let index = 0

    while (index < multiselectsData.length && !elementToUncheck) {
      const clonedData = cloneValue(multiselectsData[index])
      elementToUncheck = this.getElementsFromMultiselect(clonedData).find(
        item => this.isElementEqualToChip(item, chipToRemove.id)
      )

      if (elementToUncheck) {
        elementToUncheck.checked = false
        const currentMultiselect = this.multiselects.toArray()[index]

        this.resetMultiselectToData(currentMultiselect, clonedData)

        this.removeChip.emit(id)
      }

      index++
    }
  }

  onRemoveAllChips(): void {
    this.chips = []

    const multiselectsData = this.multiselects.map(
      multiselect => multiselect.data
    )

    multiselectsData.forEach((data, index) => {
      const currentMultiselect = this.multiselects.toArray()[index]

      // Unchecks all dropdown items
      const clonedData = cloneValue(data)

      clonedData.forEach(section => {
        section.items.forEach(item => {
          item.checked = false
        })
      })

      this.resetMultiselectToData(currentMultiselect, clonedData)
    })

    this.removeAll.emit()
  }

  private initializeMultiselectEventsListener(): void {
    // Apply changes events
    merge(
      // Apply changes event is emitted when the user clicks on the apply button, user removes a chip or user clicks on remove all chips button
      ...this.multiselects.map(multiselect => multiselect.applyChanges),
      // Reset event is emitted when we programmatically reset the multiselect
      ...this.multiselects.map(multiselect => multiselect.resetEvent)
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(appliedFilters => this.processChips(appliedFilters))
  }

  private processChips(appliedFilters: MultiselectDropdownData[]): void {
    // Goes through every dropdown item to add/remove chips depending on checked property
    this.getElementsFromMultiselect(appliedFilters).forEach(item => {
      const chipDisplayed = this.chips.find(chip =>
        this.isElementEqualToChip(item, chip.id)
      )

      if (item.checked && !chipDisplayed) {
        this.addChip(item)
      } else if (!item.checked && chipDisplayed) {
        this.onFilterRemoveChip(chipDisplayed.id)
      }
    })

    this.cd.markForCheck()
  }

  private onFilterRemoveChip(id: string): void {
    // Removes chip after its corresponding filter option has been unselected
    this.chips = this.chips.filter(chip => chip.id !== id)
  }

  private addChip(element: DropdownSelectableItem): void {
    // Adds chip after its corresponding filter option has been selected
    this.chips.push({
      label: element.label,
      id: this.buildChipIdFromOriginalElement(element),
    })
  }

  private resetMultiselectToData(
    multiselect: HtMultiselectDropdownComponent,
    data: MultiselectDropdownData[]
  ): void {
    // Resets correspoding multiselect with unchecked items
    multiselect.reset(data, false)
    // Sending apply changes event. This will update the chips and also the parent component will treat this removal as if it was done by the user clicking apply
    multiselect.applyChanges.emit(data)
  }

  private getElementsFromMultiselect(
    data: MultiselectDropdownData[]
  ): DropdownSelectableItem[] {
    // Flats array of arrays. MultiselectDropdownData is an array of sections, each section has an array of items
    return data.reduce(
      (acc, appliedFilter) => [...acc, ...appliedFilter.items],
      []
    )
  }

  private buildChipIdFromOriginalElement(
    originalElement: DropdownSelectableItem
  ): string {
    // Builds a chip id based on the original element id: 'randomNumber-originalElementId'
    return `${originalElement.label}-${originalElement.id}`
  }

  private isElementEqualToChip(
    element: DropdownSelectableItem,
    chipId: string
  ): boolean {
    // Checks if the chip corresponds to the element
    return chipId === this.buildChipIdFromOriginalElement(element)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
