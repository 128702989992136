import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { AngularComponent } from '../AngularComponent'

const isEmptyString = (str: string): boolean => Boolean(str.match(/^$/))

@Component({
  selector: 'app-whitelisted-urls',
  templateUrl: './whitelisted-urls.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./whitelisted-urls.component.scss'],
})
export class WhitelistedUrlsComponent
  extends AngularComponent
  implements OnInit, OnDestroy
{
  @Input() text: string
  @Input() hint: string
  @Output() whitelistedUrlsFilled: EventEmitter<string[]> = new EventEmitter()
  whitelistedUrls: string[]

  constructor() {
    super()
  }
  ngOnInit() {
    this.hint = ''
    this.whitelistedUrls = []
  }

  updateText(evt) {
    this.text = evt
  }

  onBlur() {
    this.whitelistedUrls = this.text
      .split(',')
      .map(str => str.trim().split('\n'))
      .reduce((strList, current) => strList.concat(current), [])
      .filter(str => !isEmptyString(str))
      .map(str => str.trim())
    this.whitelistedUrlsFilled.emit(this.whitelistedUrls)
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
