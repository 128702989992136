<div fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="column">
    <h4>Your webchat SDK APP ID is:</h4>
    <strong>{{ providerAccount?.id }}</strong>
  </div>
  <button mat-raised-button (click)="toggleCodeSnippet()">
    {{ !showCodeSnippet ? 'Get Code Snippet' : 'Hide Code Snippet' }}
  </button>
</div>
<div class="webchat-snippet" *ngIf="showCodeSnippet">
  <code>
    <div class="button-wrapper">
      <button class="copy-button" (click)="copyToClipboard()">
        Copy to clipboard
      </button>
    </div>
    <p #webchatSnippet>{{ injectBotonicSdk() }}</p>
  </code>
</div>
