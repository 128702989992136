import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { AngularComponent } from 'shared/AngularComponent'

export enum HtSearchBarTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum HtSearchBarSizes {
  TINY = 'tiny',
  SMALL = 'small',
  DEFAULT = 'default',
}

@Component({
  selector: 'ht-search-bar',
  templateUrl: './ht-search-bar.component.html',
  styleUrls: ['./ht-search-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => HtSearchBarComponent),
    },
  ],
})
export class HtSearchBarComponent
  extends AngularComponent
  implements ControlValueAccessor, OnInit
{
  @Input()
  value = ''

  @Input()
  placeholder = ''

  @Input()
  debounceTimeValue = 300

  @Input()
  type: HtSearchBarTypes = HtSearchBarTypes.PRIMARY

  @Input()
  size: HtSearchBarSizes = HtSearchBarSizes.DEFAULT

  @Input()
  testId?: string

  @Output() search = new EventEmitter<string>()

  $onSearch = new Subject<string>()

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.$onSearch
      .pipe(
        debounceTime(this.debounceTimeValue),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => this.search.emit(this.value))
  }

  onChange = value => {}

  onTouched = () => {}

  onInput(value: string) {
    this.value = value
    this.onTouched()
    this.onChange(this.value)
    this.$onSearch.next(value)
  }

  writeValue(value: string): void {
    this.value = value
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }

  onCrossClick() {
    this.value = ''
    this.search.emit(this.value)
  }
}
