<div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
  <div
    class="channels-list"
    fxFlex="none"
    fxLayout="row"
    fxLayoutAlign="start center"
    *ngFor="let channel of channels"
    role="listitem"
    [ngClass]="!channel.is_active ? 'inactive' : ''"
    [ngClass]="haveSettings(channel) ? 'have-settings' : ''"
  >
    <app-provider-icon
      fxFlex="none"
      mat-list-avatar
      [provider]="channel.provider"
    ></app-provider-icon>
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="start center"
      (click)="handleClick(channel)"
    >
      <i
        class="whatsapp-cloud-icon fa-light fa-cloud"
        tooltip="Whatsapp Cloud"
        *ngIf="channel.is_whatsapp_cloud"
      ></i>
      <div fxFlex="none" class="channel-name">
        {{ channel.is_playground ? '[Playground] ' : '' }}{{ channel.name }}
      </div>
      <a
        class="provider-link"
        fxFlex="none"
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngIf="channel.channelUrl"
        href="{{ channel.channelUrl }}"
        target="_blank"
        ><span>{{ channel.channelUrl }}</span
        ><mat-icon>exit_to_app</mat-icon></a
      >
      <i
        class="inactive-alert-icon fa-solid fa-circle-exclamation"
        tooltip="This integration is inactive"
        *ngIf="!channel.is_active"
      ></i>
    </div>
    <button fxFlex="none" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <!-- TODO: add "see credentials" -->
      <button
        *ngIf="channel.is_active && channel.isFacebook"
        mat-menu-item
        (click)="disableGetStarted(channel)"
      >
        Disable 'Get started button'
      </button>
      <button
        *ngIf="!channel.is_active && channel.isFacebook"
        mat-menu-item
        (click)="enableGetStarted(channel)"
      >
        Enable 'Get started button'
      </button>
      <button
        *ngIf="haveSettings(channel)"
        mat-menu-item
        (click)="handleClick(channel)"
      >
        Show settings
      </button>
      <button
        mat-menu-item
        (click)="channelDeleted.emit(channel)"
        matTooltip="Remove"
      >
        Remove<mat-icon>clear</mat-icon>
      </button>
    </mat-menu>
  </div>
  <div class="text-feedback" *ngIf="feedback">{{ feedback }}</div>
</div>
