<div fxFlex fxLayout="column" class="channel-detail">
  <button fxFlex="none" fxFlexAlign="end" mat-button (click)="back()">
    <mat-icon>close</mat-icon>
  </button>
  <h2>Webchat - {{ providerAccount?.name }}</h2>
  <app-webchat-snippet
    [providerAccount]="providerAccount"
  ></app-webchat-snippet>
  <div *ngIf="webchatSettings" fxLayout="column">
    <mat-divider class="divider"></mat-divider>
    <app-webchat-settings
      *ngIf="webchatSettings"
      [showCurrentQueue]="true"
      [webchatSettings]="webchatSettings"
      (webchatSettingsChanged)="onWebchatSettingsChanged($event)"
    ></app-webchat-settings>
    <button mat-raised-button (click)="updateSettings()">
      Update settings
    </button>
    <p *ngIf="showFeedback()">{{ feedback }}</p>
  </div>
</div>
