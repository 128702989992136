import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'ht-table-footer',
  templateUrl: './ht-table-footer.component.html',
  styleUrls: ['./ht-table-footer.component.scss'],
})
export class HtTableFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
