/* eslint-disable @typescript-eslint/naming-convention */
import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { NgxSkeletonLoaderComponent } from 'ngx-skeleton-loader'

@Directive({
  selector: '[loading]',
})
export class LoadingDirective implements OnInit {
  private _loading = false
  private _loadingHeight = '40px'
  private _loadingWidth = '100%'
  private _loadingLines = 1

  constructor(
    private templateRef: TemplateRef<any>,
    public viewContainerRef: ViewContainerRef
  ) {}

  @Input()
  set loading(value: boolean) {
    this._loading = value
    this.refresh()
  }

  @Input()
  set loadingHeight(value: string) {
    this._loadingHeight = value
    this.refresh()
  }

  @Input()
  set loadingWidth(value: string) {
    this._loadingWidth = value
    this.refresh()
  }

  @Input()
  set loadingLines(value: number) {
    this._loadingLines = value
    this.refresh()
  }

  ngOnInit(): void {}
  refresh() {
    this.viewContainerRef.clear()
    if (this._loading) {
      const component = this.viewContainerRef.createComponent(
        NgxSkeletonLoaderComponent
      )

      component.instance.count = this._loadingLines
      component.instance.theme = {
        height: this._loadingHeight,
        width: this._loadingWidth,
        marginBottom: '0px',
        'background-color': 'rgba(0, 0, 0, 0.05)',
      }
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef)
    }
  }
}
