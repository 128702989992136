import { Component, HostBinding, Input, OnInit } from '@angular/core'

@Component({
  selector: 'ht-expansion-panel',
  templateUrl: './ht-expansion-panel.component.html',
  styleUrls: ['./ht-expansion-panel.component.scss'],
})
export class HtExpansionPanelComponent implements OnInit {
  @Input() isExpanded = false
  @Input() title: string

  @HostBinding('class.is-expanded') get expanded() {
    return this.isExpanded
  }

  toggleIsExpanded(): void {
    this.isExpanded = !this.isExpanded
  }

  ngOnInit(): void {}
}
