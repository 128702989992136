<div
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  class="connect-bg connect-channel"
>
  <h2>Add Facebook Messenger</h2>
  <h3>Step 1. Create a Facebook Page</h3>
  <p>
    You need a Facebook Page where your users will message you. You can
    <a href="https://www.facebook.com/pages/create/" target="_blank"
      >create a Facebook Page here</a
    >
    or skip to Step 2 if you already have one.
  </p>
  <h3>Step 2. Connect your Facebook account with Hubtype</h3>
  <p
    *ngIf="loginStatus?.status === 'connected' && !loadingUser"
    fxLayout="row"
    fxFlexAlign="start center"
  >
    You're now logged in as:
  </p>
  <p *ngIf="loginStatus?.status === 'connected' && !loadingUser">
    <strong class="fb-name"
      >{{ curUser?.firstName }} {{ curUser?.lastName }}</strong
    >
    <span
      class="fb-logout"
      (click)="logoutFb()"
      fxFlex="none"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <span>Logout</span>
      <mat-icon>close</mat-icon>
    </span>
  </p>
  <div *ngIf="loadingUser" fxFlexAlign="center" @loadData>
    <mat-spinner [diameter]="32" [strokeWidth]="3.2"></mat-spinner>
  </div>
  <button
    mat-raised-button
    *ngIf="!curUser && !loadingUser"
    fxFlex="none"
    type="submit"
    (click)="connectAccount()"
  >
    Connect Facebook
  </button>

  <h3 [ngClass]="{ disabled: !curUser }">Step 3. Select a Page</h3>
  <p *ngIf="feedback" class="text-feedback">
    There was a problem getting the Pages
  </p>
  <p *ngIf="facebookPages && !facebookPages?.length">
    You don't have any Facebook Page. Please create one and
    <span class="refresh-fb-pages" (click)="connectAccount()"
      >refresh<mat-icon>refresh</mat-icon></span
    >
  </p>
  <p *ngIf="facebookPages && facebookPages?.length">
    The messages you get to this page will be sent to Hubtype:
  </p>
  <div *ngIf="loadingPages" fxFlexAlign="center" @loadData>
    <mat-spinner [diameter]="32" [strokeWidth]="3.2"></mat-spinner>
  </div>
  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="center center"
    *ngIf="facebookPages && !loadingPages"
    class="pages-select"
  >
    <mat-select
      fxFlex="none"
      [(ngModel)]="selectedFacebookPage"
      floatPlaceholder="never"
    >
      <mat-option *ngFor="let page of facebookPages" [value]="page">
        <img width="20" [src]="page.pic" /> <span>{{ page.name }}</span>
      </mat-option>
    </mat-select>
    <span
      *ngIf="facebookPages?.length"
      class="refresh-fb-pages"
      mat-icon-button
      (click)="refreshPages()"
      fxFlex="none"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <span>refresh</span>
      <mat-icon>refresh</mat-icon>
    </span>
  </div>
  <button
    mat-raised-button
    fxFlex="none"
    type="submit"
    [disabled]="!(facebookPages && selectedFacebookPage)"
    (click)="connectPage()"
  >
    Connect Page
  </button>
</div>
