<button
  ht-select-button
  size="small"
  data-testid="inbox-sort"
  (click)="toggleDropdown()"
>
  <i
    class="fa-solid"
    [ngClass]="{
      'fa-arrow-up-short-wide': sortDirection === 'asc',
      'fa-arrow-down-wide-short': sortDirection !== 'asc'
    }"
  ></i>
  {{ label }}
  <i
    class="fa-solid"
    [ngClass]="isDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
  ></i>
</button>
<div [ngClass]="isDropdownOpen ? 'opened' : ''" class="dropdown-menu">
  <div
    *ngFor="let item of options"
    class="dropdown-menu-item"
    [attr.data-testid]="item.key.sortBy + ':' + item.key.sortDirection"
    (click)="onClickSortOption(item)"
    [ngClass]="{ selected: item.selected }"
  >
    {{ item.label }}
  </div>
</div>
