import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { ProjectQueuesGroup } from '../../utils/desk-utils'
import { AngularComponent } from '../AngularComponent'

@Component({
  selector: 'app-queue-selector',
  templateUrl: './queue-selector.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./queue-selector.component.scss'],
})
export class QueueSelectorComponent extends AngularComponent {
  // mat-select with groups: https://material.angular.io/components/select/overview
  queueControl: UntypedFormControl = new UntypedFormControl()
  @Input() title: string
  @Input() hint: string
  @Input() required: boolean
  @Input() projectQueuesGroups: ProjectQueuesGroup[]
  @Input() allowNone = false
  @Output() selectedQueueId: EventEmitter<string> = new EventEmitter()

  constructor(private router: Router) {
    super()
    this.hint = ''
  }

  onSelectionChange(queueId: string) {
    this.selectedQueueId.emit(queueId)
  }
}
