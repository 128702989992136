<div
  class="panel-header"
  (click)="toggleIsExpanded()"
  [attr.aria-label]="title"
>
  <i
    [ngClass]="isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
    class="fa-solid fa-xs"
  ></i
  >{{ title }}
</div>
<div class="panel-content">
  <ng-content></ng-content>
</div>
