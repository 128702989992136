import { Pipe, PipeTransform } from '@angular/core'
import { HubtypeCase } from 'models/hubtype-case'

@Pipe({
  name: 'caseStatus',
})
export class CaseStatusPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    const name = HubtypeCase.statusName(value)
    if (!name) {
      return ''
    }
    return name
  }
}
