import { Component, Input, OnInit } from '@angular/core'

export interface PercentageListItem {
  label: string
  percentage: number
}

export interface PercentageListOptions {
  initialItemsShown?: number
}

@Component({
  selector: 'ht-percentage-list',
  templateUrl: './ht-percentage-list.component.html',
  styleUrls: ['./ht-percentage-list.component.scss'],
})
export class HtPercentageListComponent implements OnInit {
  @Input()
  data: PercentageListItem[]

  @Input()
  options?: PercentageListOptions = { initialItemsShown: 5 }

  itemsShown: number

  constructor() {}

  ngOnInit(): void {
    this.itemsShown = this.options.initialItemsShown
  }

  seeAll(): void {
    this.itemsShown = this.data.length
  }

  seeLess(): void {
    this.itemsShown = this.options.initialItemsShown
  }
}
