/* eslint-disable @typescript-eslint/naming-convention */
import { environment } from 'environment/environment'
import { Any, JsonConvert, JsonObject, JsonProperty } from 'json2typescript'
import moment from 'moment'
import { FeatureFlag } from '../constants/feature-flags'
import { HubtypeBot } from './hubtype-bot'
import { HubtypeFolder } from './hubtype-folder'
import { HubtypeProject } from './hubtype-project'
import { HubtypeTemplate } from './hubtype-template'
import { HubtypeUser } from './hubtype-user'

enum ContractType {
  ENTERPRISE_PRODUCTION = 'enterprise_production',
  ENTERPRISE_STAGING = 'enterprise_staging',
  FREE = 'free',
  INTERNAL = 'internal',
}

const jsonConverter: JsonConvert = new JsonConvert()

export enum TranslationClient {
  chatGTP = 'llm',
  polyglot = 'polyglot',
}

@JsonObject
export class OrganizationSettingsPusherApp {
  @JsonProperty('key', String, false)
  public key: string = undefined
  @JsonProperty('cluster', String, false)
  public cluster: string = undefined
  @JsonProperty('channel_prefix', String, false)
  public channel_prefix: string = undefined
  @JsonProperty('status_channel', String, false)
  public status_channel: string = undefined
  @JsonProperty('heart_beat_period_in_seconds', Number, true) // TODO: change to not optional after 2024-12-19 release and remove the default value
  public heart_beat_period_in_seconds: number = 3 * 60
}

@JsonObject
export class OrganizationSettings {
  @JsonProperty('default_country_phone_code', String, true)
  public default_country_phone_code?: string = undefined
  @JsonProperty('organization_id_to_use_anonimized_analytics', String, true)
  organization_id_to_use_anonimized_analytics?: string = undefined
  @JsonProperty('translation_client', String, false)
  public translation_client?: TranslationClient = undefined
  @JsonProperty('pusher', OrganizationSettingsPusherApp, false)
  public pusher: OrganizationSettingsPusherApp = undefined
}

@JsonObject
export class MyOrganization {
  @JsonProperty('id', String, false)
  public id: string = undefined

  @JsonProperty('name', String, true)
  public name?: string = undefined

  @JsonProperty('created_at', Any, true)
  public createdAt?: any = undefined

  @JsonProperty('contract_type', String, true)
  public contractType?: string = undefined

  @JsonProperty('licenses', Number, true)
  public licenses?: number = undefined

  @JsonProperty('contracted_maus', Number, true)
  public contractedMaus?: number = undefined

  @JsonProperty('allow_transfer_all_projects', Boolean, true)
  public allowTransferAllProjects?: boolean = undefined

  @JsonProperty('idle_duration', String, true)
  public idleDuration?: string = undefined

  @JsonProperty('feature_flags', Any, true)
  public featureFlags: { [feat: string]: boolean } = {}

  @JsonProperty('settings', OrganizationSettings, false)
  public settings: OrganizationSettings = undefined

  @JsonProperty('webhook_url', String, true)
  public webhookUrl?: string = undefined

  @JsonProperty('subscribe_case_received', Boolean, true)
  public subscribeCaseReceived?: boolean = undefined

  @JsonProperty('subscribe_case_newmessage', Boolean, true)
  public subscribeCaseNewmessage?: boolean = undefined

  @JsonProperty('subscribe_case_resolved', Boolean, true)
  public subscribeCaseResolved?: boolean = undefined

  @JsonProperty('subscribe_case_assigned', Boolean, true)
  public subscribeCaseAssigned?: boolean = undefined

  @JsonProperty('subscribe_case_transferred', Boolean, true)
  public subscribeCaseTransferred?: boolean = undefined

  setupFeatureFlags() {
    if (
      environment.release_name === 'prod-ent0-ent0-beta' ||
      environment.release_name === 'qa-test-int-beta'
    ) {
      this.featureFlags[FeatureFlag.HAS_CONTACT_REASONS_UI] = true
    }
  }

  toOrganizationComplete(): HubtypeOrganizationComplete {
    this.setupFeatureFlags()
    return jsonConverter.deserializeObject(
      {
        id: this.id,
        name: this.name,
        created_at: this.createdAt,
        contract_type: this.contractType,
        licenses: this.licenses,
        contracted_maus: this.contractedMaus,
        allow_transfer_all_projects: this.allowTransferAllProjects,
        idle_duration: this.idleDuration,
        feature_flags_json: this.featureFlags,
        settings: this.settings,
        webhook_url: this.webhookUrl,
        subscribe_case_received: this.subscribeCaseReceived,
        subscribe_case_newmessage: this.subscribeCaseNewmessage,
        subscribe_case_resolved: this.subscribeCaseResolved,
        subscribe_case_assigned: this.subscribeCaseAssigned,
        subscribe_case_transferred: this.subscribeCaseTransferred,
      },
      HubtypeOrganizationComplete
    )
  }
}

@JsonObject
export class HubtypeOrganization {
  @JsonProperty('id', String, false)
  public id: string = undefined

  @JsonProperty('name', String, true)
  public name?: string = undefined

  @JsonProperty('created_at', Any, true)
  public created_at?: any = undefined

  @JsonProperty('contract_type', String, true)
  public contract_type?: string = undefined

  @JsonProperty('licenses', Number, true)
  public licenses?: number = undefined

  @JsonProperty('status', String, true)
  public status?: string = undefined // TODO: this attribute does not exists, only put here for AOT compilation

  @JsonProperty('subscribe_case_received', Boolean, true)
  public subscribe_case_received?: boolean = undefined

  @JsonProperty('subscribe_case_newmessage', Boolean, true)
  public subscribe_case_newmessage?: boolean = undefined

  @JsonProperty('subscribe_case_resolved', Boolean, true)
  public subscribe_case_resolved?: boolean = undefined

  @JsonProperty('subscribe_case_assigned', Boolean, true)
  public subscribe_case_assigned?: boolean = undefined

  @JsonProperty('subscribe_case_transferred', Boolean, true)
  public subscribe_case_transferred?: boolean = undefined

  @JsonProperty('allow_transfer_all_projects', Boolean, true)
  public allow_transfer_all_projects?: boolean = undefined

  @JsonProperty('templates', [HubtypeTemplate], true)
  public templates?: HubtypeTemplate[] = undefined

  @JsonProperty('webhook_url', String, true)
  public webhook_url?: string = undefined
  @JsonProperty('idle_duration', String, true)
  public idle_duration?: string = undefined
  @JsonProperty('feature_flags_json', Any, true)
  public feature_flags_json: { [feat: string]: boolean } = {}

  @JsonProperty('settings', OrganizationSettings, false)
  public settings: OrganizationSettings = undefined

  @JsonProperty('contracted_maus', Number, true)
  public contracted_maus?: number = undefined

  public get created_at_moment() {
    return moment(this.created_at)
  }

  public hasAccessTo(feature: string): boolean {
    return Boolean(this.feature_flags_json[feature])
  }

  public hasNonFreeContractType(): boolean {
    return (
      this.contract_type === ContractType.ENTERPRISE_PRODUCTION ||
      this.contract_type === ContractType.ENTERPRISE_STAGING ||
      this.contract_type === ContractType.INTERNAL
    )
  }

  public showCaseArchive(user: HubtypeUser): boolean {
    const blockCaseArchive = Boolean(
      this.feature_flags_json[FeatureFlag.BLOCK_CASE_ARCHIVE] || false
    )
    if (user.is_agent && blockCaseArchive) {
      return false
    }
    return true
  }
}

@JsonObject
export class HubtypeOrganizationComplete {
  @JsonProperty('id', String, true)
  public id?: string = undefined

  @JsonProperty('name', String, true)
  public name?: string = undefined

  @JsonProperty('bots', [HubtypeBot], true)
  public bots?: HubtypeBot[] = undefined

  @JsonProperty('created_at', Any, true)
  public created_at?: any = undefined

  @JsonProperty('licenses', Number, true)
  public licenses?: number = undefined

  @JsonProperty('contract_type', String, true)
  public contract_type?: string = undefined

  @JsonProperty('projects', [HubtypeProject], true)
  public projects: HubtypeProject[] = undefined

  @JsonProperty('subscribe_case_received', Boolean, true)
  public subscribe_case_received?: boolean = undefined

  @JsonProperty('subscribe_case_newmessage', Boolean, true)
  public subscribe_case_newmessage?: boolean = undefined

  @JsonProperty('subscribe_case_resolved', Boolean, true)
  public subscribe_case_resolved?: boolean = undefined

  @JsonProperty('subscribe_case_assigned', Boolean, true)
  public subscribe_case_assigned?: boolean = undefined

  @JsonProperty('subscribe_case_transferred', Boolean, true)
  public subscribe_case_transferred?: boolean = undefined

  @JsonProperty('allow_transfer_all_projects', Boolean, true)
  public allow_transfer_all_projects?: boolean = undefined

  @JsonProperty('webhook_url', String, true)
  public webhook_url?: string = undefined

  @JsonProperty('idle_duration', String, true)
  public idle_duration?: string = undefined

  @JsonProperty('users', [HubtypeUser], true)
  public users?: HubtypeUser[] = undefined

  @JsonProperty('template_folders', [HubtypeFolder], true)
  public template_folders?: HubtypeFolder[] = undefined

  @JsonProperty('templates', [HubtypeTemplate], true)
  public templates?: HubtypeTemplate[] = undefined

  @JsonProperty('feature_flags_json', Any, true)
  private feature_flags_json: { [feat: string]: Any } = {}

  @JsonProperty('settings', OrganizationSettings, true)
  public settings: OrganizationSettings = undefined

  @JsonProperty('contracted_maus', Number, true)
  public contracted_maus?: number = undefined

  constructor() {}

  public get created_at_moment() {
    return moment(this.created_at)
  }

  public get simplified(): HubtypeOrganization {
    const simple = new HubtypeOrganization()
    simple.id = this.id
    simple.name = this.name
    simple.created_at = this.created_at
    simple.licenses = this.licenses
    simple.contract_type = this.contract_type
    simple.subscribe_case_received = this.subscribe_case_received
    simple.subscribe_case_newmessage = this.subscribe_case_newmessage
    simple.subscribe_case_resolved = this.subscribe_case_resolved
    simple.subscribe_case_assigned = this.subscribe_case_assigned
    simple.subscribe_case_transferred = this.subscribe_case_transferred
    simple.webhook_url = this.webhook_url
    simple.idle_duration = this.idle_duration
    simple.templates = this.templates
    simple.allow_transfer_all_projects = this.allow_transfer_all_projects

    simple.feature_flags_json = buildFlagsKeyMirror(this.feature_flags_json)

    simple.settings = this.settings

    simple.contracted_maus = this.contracted_maus

    return simple
  }
}

function buildFlagsKeyMirror(map: unknown): { [key: string]: true } {
  return Object.entries(map)
    .filter(([key, value]) => value === 'enabled' || value === true)
    .reduce((acc, [key, value]) => {
      acc[key] = true
      return acc
    }, {})
}
