<div class="container">
  <input
    #fileInput
    [placeholder]="placeholder"
    [disabled]="disabled"
    [ngClass]="icon ? '' : 'not-icon'"
    [ngStyle]="{ width: width ? width + 'px' : '100%' }"
    type="file"
    (input)="onInput($event.srcElement.files[0])"
    [accept]="accept"
  />
  <span>{{ fileName ? fileName : placeholder }}</span>
  <i *ngIf="icon" class="fa-solid" [ngClass]="icon"></i>
</div>
