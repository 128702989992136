import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core'

export enum HtTagVariant {
  NEUTRAL = 'neutral',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  ALERT = 'alert',
  WARNING = 'warning',
  INFO = 'info',
}

@Component({
  selector: 'ht-tag',
  templateUrl: './ht-tag.component.html',
  styleUrls: ['./ht-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtTagComponent implements OnInit {
  @HostBinding('class') @Input() variant: HtTagVariant = HtTagVariant.NEUTRAL

  constructor() {}

  ngOnInit(): void {}
}

@Component({
  selector: 'ht-tag-neutral, [ht-tag-neutral]',
  templateUrl: './ht-tag.component.html',
  styleUrls: ['./ht-tag.component.scss'],
})
export class HtTagNeutralComponent extends HtTagComponent {
  @HostBinding('class') variant: HtTagVariant = HtTagVariant.NEUTRAL
}

@Component({
  selector: 'ht-tag-positive,[ht-tag-positive]',
  templateUrl: './ht-tag.component.html',
  styleUrls: ['./ht-tag.component.scss'],
})
export class HtTagPositiveComponent extends HtTagComponent {
  @HostBinding('class') variant: HtTagVariant = HtTagVariant.POSITIVE
}

@Component({
  selector: 'ht-tag-positive, [ht-tag-negative]',
  templateUrl: './ht-tag.component.html',
  styleUrls: ['./ht-tag.component.scss'],
})
export class HtTagNegativeComponent extends HtTagComponent {
  @HostBinding('class') variant: HtTagVariant = HtTagVariant.NEGATIVE
}

@Component({
  selector: 'ht-tag-positive, [ht-tag-alert]',
  templateUrl: './ht-tag.component.html',
  styleUrls: ['./ht-tag.component.scss'],
})
export class HtTagAlertComponent extends HtTagComponent {
  @HostBinding('class') variant: HtTagVariant = HtTagVariant.ALERT
}

@Component({
  selector: 'ht-tag-info, [ht-tag-info]',
  templateUrl: './ht-tag.component.html',
  styleUrls: ['./ht-tag.component.scss'],
})
export class HtTagInfoComponent extends HtTagComponent {
  @HostBinding('class') variant: HtTagVariant = HtTagVariant.INFO
}

@Component({
  selector: 'ht-tag-warning, [ht-tag-warning]',
  templateUrl: './ht-tag.component.html',
  styleUrls: ['./ht-tag.component.scss'],
})
export class HtTagWarningComponent extends HtTagComponent {
  @HostBinding('class') variant: HtTagVariant = HtTagVariant.WARNING
}
