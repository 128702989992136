<div fxFlex fxLayout="column" class="integrate-box">
  <button fxFlex="none" fxFlexAlign="end" mat-button (click)="back()">
    <mat-icon>close</mat-icon>
  </button>
  <div fxFlex="none" *ngIf="bot.zendesk_is_active">
    <p>Integrated</p>
    <a mat-raised-button (click)="unintegrate()">Unintegrate</a>
  </div>
  <div fxFlex="none" *ngIf="!bot.zendesk_is_active">
    <app-channels-zendesk></app-channels-zendesk>
  </div>
</div>
