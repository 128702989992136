<div class="banner-wrapper">
  <div class="banner-info">
    <h4 class="title">
      <i
        *ngIf="leadIconClass"
        class="lead-icon fa"
        [ngClass]="leadIconClass"
      ></i>

      {{ title }}

      <ht-badge *ngIf="badgeConfig" [type]="badgeConfig.type">{{
        badgeConfig.text
      }}</ht-badge>
    </h4>

    <div class="message">{{ message }} </div>
  </div>
  <div class="banner-actions">
    <ng-content select="[banner-action]"></ng-content>

    <i
      *ngIf="isClosable"
      class="banner-close fa-solid fa-xmark"
      (click)="closeBanner($event)"
    ></i>
  </div>
</div>
