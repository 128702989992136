<mat-form-field>
  <mat-label>Whitelisted URLs</mat-label>
  <textarea
    matInput
    cdkTextareaAutosize
    placeholder="e.g. https://mydomain.com, https://myotherdomain.com/es/*"
    [ngModel]="text"
    (ngModelChange)="updateText($event)"
    (blur)="onBlur()"
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="5"
  ></textarea>
  <mat-hint *ngIf="hint" class="hint">{{ hint }}</mat-hint>
</mat-form-field>
