import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'ht-table-header',
  templateUrl: './ht-table-header.component.html',
  styleUrls: ['./ht-table-header.component.scss'],
})
export class HtTableHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
