<div fxFlex fxLayout="column" class="mat-typography">
  <div class="dialog-header" fxLayoutAlign="start center">
    <h3 fxFlex="100" mat-dialog-title>Change bot name</h3>
    <button
      mat-icon-button
      fxFlex="none"
      fxFlexAlign="end"
      (click)="onCancel()"
    >
      <mat-icon class="mat-24" aria-label="Close">clear</mat-icon>
    </button>
  </div>
  <div class="dialog-body" fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-form-field>
      <input
        matInput
        [(ngModel)]="data.name"
        name="name"
        type="text"
        value="data.name"
        required
      />
    </mat-form-field>
  </div>
  <div class="dialog-footer" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="dialogRef.close()">Cancel</button>
    <button mat-raised-button (click)="dialogRef.close(data.name)">
      Update
    </button>
  </div>
</div>
