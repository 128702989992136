import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { HubtypeProviderAccount } from '../../models/hubtype-provider-account'
import { AngularComponent } from '../AngularComponent'

@Component({
  selector: 'app-webchat-snippet',
  templateUrl: './webchat-snippet.component.html',
  styleUrls: ['./webchat-snippet.component.scss'],
})
export class WebchatSnippetComponent extends AngularComponent {
  @Input() providerAccount: HubtypeProviderAccount
  @Input() showCodeSnippet = false
  @ViewChild('webchatSnippet', { static: false })
  private snippetContent: ElementRef<HTMLParagraphElement>

  constructor() {
    super()
  }

  injectBotonicSdk() {
    const webchatSDK = `<html>
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <script
        type="text/javascript"
        src="${this.providerAccount.netlify_url}/webchat.botonic.js"
      ></script>
    </head>
    <body>
      <div id="root" />
      <script type="text/javascript">
        document.addEventListener('DOMContentLoaded', function (event) {
          Botonic.render(document.getElementById('root'), {
            appId: '${this.providerAccount.id}',
            // visibility: 'dynamic', // Uncomment for enable dynamic settings (Botonic +0.12.0).
          })
        })
      </script>
    </body>
    </html>`
    return webchatSDK
  }

  copyToClipboard() {
    const content = this.snippetContent.nativeElement.innerText
    navigator.clipboard.writeText(content)
  }

  toggleCodeSnippet() {
    this.showCodeSnippet = !this.showCodeSnippet
  }
}
