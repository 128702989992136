<div class="dropdown" role="listbox" [attr.aria-label]="label">
  <button
    ht-select-button
    (click)="toggleDropdown()"
    [size]="buttonSize"
    [expanded]="showDropdown"
    type="button"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  >
    <ng-container *ngIf="!hideLabel">
      <ng-content></ng-content>
      <span
        class="selected-count"
        *ngIf="selectedCount && !hideSelectedCount"
        >{{ selectedCount }}</span
      >
    </ng-container>
    <i
      class="fa-solid"
      [ngClass]="showDropdown ? 'fa-chevron-up' : 'fa-chevron-down'"
      *ngIf="!hideArrow"
    ></i>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="showDropdown"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  cdkConnectedOverlayWidth="240px"
>
  <div
    class="menu"
    (click)="handleDropdownMenuClick($event)"
    style="cursor: default !important"
  >
    <ng-container *ngIf="numberOfItems; else noDataAvailable">
      <div
        class="search-bar-wrapper"
        *ngIf="numberOfItems >= SHOW_SEARCHBAR_ELEMENTS_THRESHOLD"
      >
        <ht-search-bar
          type="secondary"
          size="small"
          [placeholder]="searchBarPlaceholder"
          (search)="filterItems($event)"
          [(ngModel)]="searchValue"
        ></ht-search-bar>
      </div>
      <div class="select-actions" *ngIf="allowSelectAll">
        <ht-checkbox
          *ngIf="allowSelectAll"
          [(ngModel)]="selectAllIsChecked"
          (onchange)="onClickSelectAll()"
          [style]="selectAllStyle"
          >{{ selectAllLabel }}</ht-checkbox
        >
      </div>
      <div class="items-container">
        <ng-container
          *ngTemplateOutlet="dropDownGroup; context: { data: filteredData }"
        ></ng-container>
      </div>
      <div class="apply-btn">
        <button
          ht-button-ds-primary
          [fullWidth]="true"
          (click)="onClickApply()"
        >
          Apply
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template
  #dropDownGroup
  let-data="data"
  let-isSelectedGroup="isSelectedGroup"
>
  <ng-container *ngFor="let section of data">
    <div class="section">
      <div *ngIf="section.title" class="section-title">
        {{ section.title }}
      </div>
      <ng-container *ngFor="let item of section.items">
        <div class="dropdown-checkbox-item">
          <ht-checkbox
            [(ngModel)]="item.checked"
            (onchange)="onOptionSelected()"
            [disabled]="item.disabled"
            [style]="'tick'"
            >{{ item.label }}</ht-checkbox
          >
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #noDataAvailable>
  <div class="no-data">No options available</div>
</ng-template>
