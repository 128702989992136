<div class="container">
  <div class="text-small">Filter by status</div>
  <div class="status-container">
    <ht-checkbox
      role="checkbox-status"
      *ngFor="let status of userStatus"
      [(ngModel)]="status.checked"
      (change)="onCheckStatusChange()"
    >
      <div [ngClass]="status.color" class="text-body-small">{{
        status.label
      }}</div>
    </ht-checkbox>
  </div>
</div>
