import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { startWith, takeUntil } from 'rxjs'
import { AngularComponent } from 'shared/AngularComponent'
import { Column, SortDirection } from '../ht-table.component'

@Component({
  selector: '[ht-table-header-cell]',
  templateUrl: './ht-table-header-cell.component.html',
  styleUrls: ['./ht-table-header-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtTableHeaderCellComponent
  extends AngularComponent
  implements OnInit
{
  @Input() column: Column
  @Input() sortFormGroup: FormGroup

  @HostBinding('class.sortable')
  get isSortable() {
    return this.column?.sortable
  }
  @HostBinding('style.width')
  get width() {
    return this.column?.width
  }

  @HostBinding('style.text-align')
  get textAlign() {
    return this.column?.textAlign
  }

  isSorting = false
  isSortingAsc = false

  constructor(private ref: ChangeDetectorRef) {
    super()
  }

  ngOnInit() {
    this.sortFormGroup.valueChanges
      .pipe(
        startWith({
          columnKey: this.sortFormGroup.value.columnKey,
          direction: this.sortFormGroup.value.direction,
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(({ columnKey, direction }) => {
        this.updateSorting(columnKey, direction)
      })
  }

  private updateSorting(columnKey: string, direction: SortDirection) {
    this.isSorting = this.column.key === columnKey
    this.isSortingAsc = this.isSorting && direction === SortDirection.ASC
    this.ref.markForCheck()
  }
}
