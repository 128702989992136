import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { toIncrementalBatch } from '../../../utils/performance'
import { PopoverComponent } from '../../shared-brand/popover/popover.component'
import {
  TableColumn,
  TableColumnType,
  TableDataItem,
  TableItemAction,
  TableItemActionType,
} from './flat-table.interfaces'

@Component({
  selector: 'app-flat-table',
  templateUrl: './flat-table.component.html',
  styleUrls: ['./flat-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatTableComponent implements OnInit, OnChanges, AfterViewChecked {
  readonly CRUD_TYPE = TableColumnType.CRUD
  readonly ICON_TYPE = TableColumnType.ICON
  readonly SELECT_TYPE = TableColumnType.SELECT
  readonly TEXT_TYPE = TableColumnType.TEXT
  readonly CRUD_DELETE = TableItemActionType.DELETE
  readonly CRUD_EDIT = TableItemActionType.EDIT
  overIconData: TableDataItem
  iconColumns: HTMLElement[]

  @ViewChild(PopoverComponent, { static: true })
  popover: PopoverComponent

  @Input()
  cols: TableColumn[] = []
  @Input()
  data: TableDataItem[] = []
  @Input()
  hideHeaders: false
  @Input()
  preloadBatch = 150

  @Output()
  iconHover = new EventEmitter<TableDataItem>()

  @Output()
  itemAction = new EventEmitter<TableItemAction>()

  selectableRow = false
  tableData: TableDataItem[] = []
  displayColumns: string[] = []
  // eslint-disable-next-line @typescript-eslint/ban-types
  columnProps: { [key: string]: string | {} }[] = []

  constructor(
    private ref: ElementRef<HTMLElement>,
    private changeRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.buildColumns()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cols && changes.cols.currentValue) {
      this.buildColumns()
    }
    if (changes.data) {
      const currentValue: TableDataItem[] = changes.data.currentValue
      this.tableData = []
      toIncrementalBatch(currentValue, this.preloadBatch, 500).subscribe(
        batch => {
          this.tableData = [...this.tableData, ...batch]
          this.changeRef.markForCheck()
        }
      )
    }
  }

  ngAfterViewChecked() {
    const icons: NodeListOf<HTMLElement> =
      this.ref.nativeElement.querySelectorAll('mat-icon')
    this.iconColumns = Array.from(icons)
  }

  iconOver(cell: TableDataItem, row: number) {
    this.overIconData = cell
    const icon = this.iconColumns.filter(el => el.innerText === cell['icon'])[
      row
    ]

    if (!icon) {
      return
    }
    const iconPosition = icon.getBoundingClientRect()
    this.popover.ref.nativeElement.style.top =
      iconPosition['y'] + icon.offsetHeight + 'px'
    this.popover.ref.nativeElement.style.left = iconPosition['x'] + 'px'

    this.iconHover.emit(this.data[row])
  }

  iconOut() {
    this.overIconData = undefined
  }

  onActionClick(element: any, action: TableItemActionType) {
    this.itemAction.emit({
      target: element,
      type: action,
    })
  }

  onClickRow(row: any) {
    if (this.selectableRow) {
      this.itemAction.emit({
        target: row,
        type: TableItemActionType.CLICK,
      })
    }
  }

  trackById(index, item) {
    return item.id
  }

  private buildColumns() {
    this.displayColumns = []
    this.columnProps = []

    this.cols.forEach(col => {
      if (col.type === this.SELECT_TYPE) {
        this.selectableRow = true
      }
      this.displayColumns.push(col.key)
      this.columnProps.push({
        header: col.name,
        type: col.type,
        style: col.style || {},
      })
    })
  }
}
