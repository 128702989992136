<input
  *ngIf="!config.align || config.align === 'left'"
  #datepicker
  class="date-input"
/>

<button
  ht-button-ds-primary
  [size]="config.button?.size"
  [appearance]="config.button?.appearance"
  class="date-btn"
  (click)="open()"
>
  <i class="fa-solid fa-calendar-day"></i>
  {{ textButton }}
  <i class="fa-solid fa-chevron-down"></i>
</button>

<input *ngIf="config.align === 'right'" #datepicker class="date-input" />
