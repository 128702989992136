<button
  ht-button-ds-secondary
  size="small"
  [matMenuTriggerFor]="filterMenu"
  class="filter-button"
  role="button"
>
  <i class="fa-solid fa-filter"></i>
  {{ label }}
  <span [hidden]="count === 0">{{ count }}</span>
</button>

<mat-menu #filterMenu="matMenu" class="filter-menu" xPosition="before">
  <div *ngFor="let group of filters; trackBy: trackByFilterGroup">
    <h5 *ngIf="group.name" class="grouped">{{ group.name }}</h5>
    <div
      *ngFor="let item of group.options; trackBy: trackByFilterOption"
      mat-menu-item
      role="listitem"
      [hidden]="item.hidden"
    >
      <mat-checkbox
        class="filter-menu-item"
        color="primary"
        [(ngModel)]="item.selected"
        (click)="onClickFilterOption(item, $event)"
      >
        {{ item.label }}
      </mat-checkbox>
    </div>
  </div>
  <div class="filter-apply">
    <app-button
      color="primary"
      text="Apply"
      [font]="14"
      [disabled]="!canApply"
      (btnClick)="onClickApply()"
    >
    </app-button>
  </div>
</mat-menu>
