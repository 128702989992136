import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import {
  HubtypeUser,
  STATUS_AVAILABLE,
  STATUS_AWAY,
  STATUS_BUSY,
  STATUS_OFFLINE,
  userFilter,
  userSort,
} from 'models/hubtype-user'
import {
  Column,
  HtTableComponent,
  TableConfig,
} from 'shared/base/ht-table/ht-table.component'

export const USER_AVATAR_COLUMN: Column = {
  name: 'User',
  type: 'profileAvatar',
  textAlign: 'start',
  width: ' -webkit-fill-available',
}

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnChanges {
  @ViewChild('userTable') userTable: HtTableComponent

  @Input()
  statusFilter: string[] = [
    STATUS_OFFLINE,
    STATUS_BUSY,
    STATUS_AWAY,
    STATUS_AVAILABLE,
  ]

  @Input()
  textFilter = ''

  @Input()
  users: HubtypeUser[] = []

  @Input()
  disabledUserIds: string[] = []

  @Input()
  configTable?: TableConfig = {
    columns: [USER_AVATAR_COLUMN],
  }

  @Input()
  emptyCaseFilterText?: string
  @Input()
  emptyCaseListText?: string

  @Input() selectedUsersIds: string[] = []
  @Output() selectedUsersIdsChange = new EventEmitter<string[]>()

  filteredUsers: any[] = []

  /**
   * We have to intercept rowSelected function from ht-table for the search filters
   * We pass of
   * caller -> ht-table
   * to
   * caller -> user-list -> ht-table
   */
  fnInterceptorRowSelected

  constructor() {}

  ngOnInit(): void {
    this.setTable()
    this.fnInterceptorRowSelected = this.configTable.onRowSelected
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setTable()
  }

  getSelectedUsers() {
    const selectedIds = this.userTable.getSelectedIds()
    return this.users.filter(user => selectedIds.includes(user.id))
  }

  private setTable() {
    this.filteredUsers = this.filterUsers()
  }

  private filterUsers() {
    return this.users
      ?.filter(userFilter(this.statusFilter, this.textFilter))
      .sort(userSort)
  }
}
