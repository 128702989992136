<form
  class="connect-bg connect-channel"
  (ngSubmit)="addZendesk()"
  #zendeskForm="ngForm"
  name="zendeskForm"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
>
  <h2>Add Zendesk</h2>
  <h3>Step 1. Create a Zendesk Account</h3>
  <p>
    You need to register to
    <a href="https://www.zendesk.com/register/#getstarted">zendesk</a>.
  </p>
  <h3>Step 2. Add your Zendesk email, domain and token</h3>
  <mat-form-field fxFlex>
    <input
      fxFlex
      matInput
      [(ngModel)]="bot.zendesk_email"
      #zendesk_email="ngModel"
      name="zendesk_email"
      type="text"
      placeholder="Zendesk email"
      required
    />
  </mat-form-field>

  <mat-form-field fxFlex>
    <input
      fxFlex
      matInput
      [(ngModel)]="bot.zendesk_sitename"
      #zendesk_sitename="ngModel"
      name="zendesk_sitename"
      type="text"
      placeholder="Zendesk domain"
      required
    />
    <mat-hint align="start" class="hint"
      >Example: https://yourdomain.zendesk.com</mat-hint
    >
  </mat-form-field>

  <mat-form-field fxFlex>
    <input
      fxFlex
      matInput
      [(ngModel)]="bot.zendesk_token"
      #zendesk_token="ngModel"
      name="zendesk_token"
      type="text"
      placeholder="Token"
      required
    />
    <mat-hint align="start"
      >Help:
      "https://support.zendesk.com/hc/en-us/articles/226022787-Generating-a-new-API-token-"</mat-hint
    >
  </mat-form-field>

  <i class="text-feedback" *ngIf="feedback">{{ feedback }}</i>

  <button
    mat-raised-button
    fxFlex="none"
    type="submit"
    [disabled]="!zendeskForm.form.valid"
  >
    Connect to Zendesk
  </button>
</form>
