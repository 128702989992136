import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-card-selector',
  templateUrl: './card-selector.component.html',
  styleUrls: ['./card-selector.component.scss'],
})
export class CardSelectorComponent implements OnInit {
  @Input()
  title: string
  @Input()
  description: string
  @Input()
  isSelected = false
  @Output()
  clicked: EventEmitter<void> = new EventEmitter()

  constructor() {}

  ngOnInit(): void {}

  onClick() {
    this.clicked.emit()
  }
}
