import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu'

export interface FilterMenuOption {
  key: string
  label: string
  selected: boolean
  hidden?: boolean
}

export interface FilterMenuGroup {
  name?: string
  options: FilterMenuOption[]
}

const isSetsEqual = (a: Set<string>, b: Set<string>) =>
  a.size === b.size && [...a].every(value => b.has(value))

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterMenuComponent implements OnChanges {
  @Input()
  label = 'Filter'
  @Input()
  filters: FilterMenuGroup[] = []
  @Output()
  filtersChange = new EventEmitter<FilterMenuGroup[]>()
  @ViewChild(MatMenuTrigger)
  filterMenu: MatMenuTrigger

  count = 0
  appliedState = new Set<string>()
  tempState = new Set<string>()

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters'].currentValue) {
      const selOptions = this.getSelectedKeys()
      this.tempState = new Set(selOptions)
      this.updateState(selOptions)
    }
  }

  get canApply(): boolean {
    return !isSetsEqual(this.appliedState, this.tempState)
  }

  onClickApply() {
    this.updateState(this.getSelectedKeys())
    this.filtersChange.emit(this.filters)

    this.filterMenu?.closeMenu()
  }

  onClickFilterOption(option: FilterMenuOption, event: Event) {
    if (!option.selected) {
      this.tempState.add(option.key)
    } else {
      this.tempState.delete(option.key)
    }
    event.stopPropagation()
  }

  trackByFilterGroup(i: number, item: FilterMenuGroup): string {
    return item.name || `${i}`
  }

  trackByFilterOption(i: number, item: FilterMenuOption): string {
    return item.key
  }

  private flattenOptions(): FilterMenuOption[] {
    return [...this.filters.map(group => group.options)].flat()
  }

  private getSelectedKeys(): string[] {
    return this.flattenOptions()
      .filter(opt => opt.selected && !opt.hidden)
      .map(opt => opt.key)
  }

  private updateState(selectedKeys: string[]) {
    this.appliedState = new Set(this.tempState)
    this.count = selectedKeys.length
  }
}
