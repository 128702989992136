import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { Subject } from 'rxjs'
import { finalize, first } from 'rxjs/operators'
import { HubtypeUser } from '../../models/hubtype-user'
import { UserService } from '../../services/hubtype-api/user.service'
import { getNavigatorLanguage } from '../../utils/language-utils'
import { randomWord } from '../../utils/string-utils'
import {
  DisplayHoliday,
  FormattedVacationRange,
  numberToDate,
  startOfToday,
  toEndOfDay,
  toStartOfDay,
  VacationRange,
} from '../../utils/time-utils'

@Component({
  selector: 'app-holiday-selector',
  templateUrl: './holiday-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./holiday-selector.component.scss'],
})
export class HolidaySelectorComponent implements OnInit, OnDestroy {
  @Input() public user: HubtypeUser
  @Output() public feedbackError: EventEmitter<string> = new EventEmitter()

  public displayHolidays: DisplayHoliday[] = []
  public feedbackHolidayError: boolean
  public feedbackHoliday: string
  public showPastHolidays = true
  public todayDate = numberToDate(startOfToday())
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private ref: ChangeDetectorRef,
    private adapter: DateAdapter<any>,
    //private snackBar: MatSnackBar,

    @Inject('userService') private userService: UserService
  ) {}

  ngOnInit() {
    this.adapter.setLocale(getNavigatorLanguage())
  }

  getRandomModelName() {
    return randomWord(5)
  }

  canAddMore() {
    const notValidHoliday = this.displayHolidays.find(
      e => e.confirmAddHoliday === false && e.range.id === null
    )

    if (notValidHoliday === undefined || this.displayHolidays.length === 0) {
      return true
    }

    this.feedbackHolidayError = true
    this.feedbackHoliday = `Don't forget to confirm the holiday!`
    return false
  }

  showRemove(holiday: DisplayHoliday) {
    if (!holiday.confirmDeleteHoliday) {
      return false
    }
    setTimeout(() => {
      this.ref.markForCheck()
      holiday.confirmDeleteHoliday = false
    }, 5000)
    return true
  }

  insertPastHoliday(e: VacationRange) {
    const pastHoliday: DisplayHoliday = {
      range: {
        id: e.id,
        startDate: numberToDate(e.start_date),
        endDate: numberToDate(e.end_date),
      },
      confirmAddHoliday: true,
      confirmDeleteHoliday: false,
      canEdit: e.end_date >= startOfToday(),
      isPastHoliday: true,
    }
    this.displayHolidays.unshift(pastHoliday)
  }

  seePastHolidays() {
    this.userService
      .getAgentVacationRanges(this.user)
      .pipe(
        first(),
        finalize(() => this.ref.markForCheck())
      )
      .subscribe(
        res => {
          this.showPastHolidays = false
          res.vacation_ranges.forEach(e => {
            if (!this.displayHolidays.find(dh => dh.range.id === e.id)) {
              this.insertPastHoliday(e)
            }
          })
        },
        err => {
          console.error('Error', err)
          this.feedbackHoliday = 'There was an error getting past holidays'
          this.feedbackHolidayError = true
        }
      )
  }

  hidePastHolidays() {
    this.displayHolidays = this.displayHolidays.filter(h => !h.isPastHoliday)
    this.showPastHolidays = true
  }

  addMore() {
    if (!this.canAddMore()) {
      return
    }
    const auxRange: FormattedVacationRange = {
      id: null,
      startDate: new Date(),
      endDate: new Date(),
    }
    this.displayHolidays.push({
      range: auxRange,
      confirmAddHoliday: false,
      confirmDeleteHoliday: false,
      canEdit: true,
      isPastHoliday: false,
    })
    this.feedbackHolidayError = false
  }

  setDisplayHoliday(holiday: DisplayHoliday, range: VacationRange) {
    const index = this.displayHolidays.findIndex(e => e === holiday)

    if (index === -1) {
      this.feedbackHoliday = 'Something went wrong...'
      this.feedbackHolidayError = true
      return
    }
    this.displayHolidays[index] = {
      range: {
        id: range.id,
        startDate: numberToDate(range.start_date),
        endDate: numberToDate(range.end_date),
      },
      confirmAddHoliday: true,
      confirmDeleteHoliday: false,
      canEdit: true,
      isPastHoliday: false,
    }
  }

  addHoliday(holiday: DisplayHoliday) {
    const id = new Date().getTime()
    const range: VacationRange = {
      id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      start_date: toStartOfDay(holiday.range.startDate),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      end_date: toEndOfDay(holiday.range.endDate),
    }
    this.userService
      .addVacationRange(this.user, range)
      .pipe(
        first(),
        finalize(() => this.ref.markForCheck())
      )
      .subscribe(res => {
        if (res.status === 'ok') {
          this.setDisplayHoliday(holiday, range)
          this.feedbackHoliday = 'Holiday added correctly'
          this.feedbackHolidayError = false
        } else if (res.status === 'error') {
          this.feedbackHoliday = res.message
          this.feedbackHolidayError = true
        }
      })
  }

  deleteHoliday(holiday: DisplayHoliday) {
    if (holiday.range.id === null) {
      this.displayHolidays = this.displayHolidays.filter(
        e => e.range.id !== null
      )
    } else {
      this.userService
        .deleteVacationRange(this.user, holiday.range.id)
        .pipe(
          first(),
          finalize(() => this.ref.markForCheck())
        )
        .subscribe(
          res => {
            this.feedbackHoliday = 'Holiday deleted correctly'
            this.feedbackHolidayError = false
            this.displayHolidays = this.displayHolidays.filter(
              e => e.range.id !== holiday.range.id
            )
          },
          err => {
            console.error('Error', err)
            this.feedbackHoliday = 'There was an error deleting the holiday'
            this.feedbackHolidayError = true
          }
        )
    }
  }

  showFeedback(feedbackToShow: string) {
    if (!feedbackToShow) {
      return false
    }

    setTimeout(() => {
      this.ref.markForCheck()
      this.feedbackHoliday = null
    }, 5000)

    // TODO: replace feedbacks with snackbars
    // this.snackBar.open('Import process running...', '', {
    //   duration: 5000,
    //   panelClass: ['feedback', 'success'],
    // })
    return true
  }

  back() {
    window.history.back()
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
