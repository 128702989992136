<div
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  class="connect-bg connect-channel"
>
  <h2>Add WhatsApp</h2>
  <h3>Step 1. Create a WhatsApp Business Account and link a phone number</h3>
  <button
    mat-raised-button
    fxFlex="none"
    type="submit"
    (click)="launchWhatsAppSignup()"
  >
    Embedded Signup
  </button>

  <h3 [ngClass]=""> Step 2. Connect your Whatsapp number with Hubtype </h3>
  <p *ngIf="feedback" class="text-feedback">
    There was a problem getting the phone number
  </p>

  <p *ngIf="phoneNumbers && !phoneNumbers?.length">
    You don't have any Phone Number registered. Please register one and
    <span class="refresh-fb-pages" (click)="launchWhatsAppSignup()"
      >refresh<mat-icon>refresh</mat-icon></span
    >
  </p>
  <p *ngIf="phoneNumbers && phoneNumbers?.length">
    The messages you get to this phone number will be sent to Hubtype:
  </p>

  <div *ngIf="loadingPhoneNumbers" fxFlexAlign="center" @loadData>
    <mat-spinner [diameter]="32" [strokeWidth]="3.2"></mat-spinner>
  </div>

  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="center center"
    *ngIf="phoneNumbers && !loadingPhoneNumbers"
    class="phones-select"
  >
    <mat-select
      fxFlex="none"
      [(ngModel)]="selectedPhoneNumber"
      floatPlaceholder="never"
    >
      <mat-option *ngFor="let phone of phoneNumbers" [value]="phone">
        <span
          >({{ phone.display_phone_name }}) {{ phone.display_phone_number }}
        </span>
      </mat-option>
    </mat-select>
  </div>
  <button
    mat-raised-button
    fxFlex="none"
    type="submit"
    [disabled]="!(phoneNumbers && selectedPhoneNumber)"
    (click)="addWhatsappCloud()"
  >
    Connect Phone Number
  </button>
</div>
