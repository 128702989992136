import { Component, EventEmitter, Input, Output } from '@angular/core'

export interface HtChipDSData {
  id: string
  label: string
}

// Chip according to Design System (in future, remove others)
@Component({
  selector: 'ht-chip-ds',
  templateUrl: './ht-chip-ds.component.html',
  styleUrls: ['./ht-chip-ds.component.scss'],
})
export class HtChipDSComponent {
  @Input()
  data: HtChipDSData

  @Output()
  removeClick = new EventEmitter<string>()

  constructor() {}

  onChipClick() {
    this.removeClick.emit(this.data.id)
  }
}
