import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-provider-icon',
  templateUrl: './provider-icon.component.html',
  styleUrls: ['./provider-icon.component.scss'],
})
export class ProviderIconComponent {
  @Input() provider?: string
}
