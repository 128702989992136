import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core'
import { HubtypeCaseSort, SortDirection } from 'models/hubtype-case-sort'

export interface SortMenuOption {
  key: HubtypeCaseSort
  label: string
  selected?: boolean
}

@Component({
  selector: 'app-sort-menu',
  templateUrl: './sort-menu.component.html',
  styleUrls: ['./sort-menu.component.scss'],
})
export class SortMenuComponent {
  @Input()
  public label = 'Sort'
  @Input()
  public options: SortMenuOption[] = []
  @Output()
  public readonly sortChange = new EventEmitter<SortMenuOption>()

  public isDropdownOpen = false

  public sortDirection: SortDirection

  constructor(private _eref: ElementRef) {}

  //On click outside of component
  @HostListener('document:click', ['$event'])
  hideDropdown(event) {
    if (
      this.isDropdownOpen &&
      !this._eref.nativeElement.contains(event.target)
    ) {
      this.isDropdownOpen = false
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen
  }

  onClickSortOption(option: SortMenuOption) {
    this.selectOption(option)

    this.isDropdownOpen = false
    this.sortChange.emit(option)
  }

  private selectOption(option: SortMenuOption) {
    this.options.forEach(o => (o.selected = false))
    option.selected = true

    this.sortDirection = option.key.sortDirection
  }
}
