import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay'
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { AngularComponent } from 'shared/AngularComponent'
import { cloneValue } from 'utils/json-utils'
import { containsNormalizedText } from 'utils/string-utils'
import { CheckboxStyle } from '../ht-checkbox/ht-checkbox.component'
import {
  HtSelectButtonAppearance,
  HtSelectButtonSize,
} from '../ht-select-button/ht-select-button.component'

export enum HT_MULTISELECT_DROPDOWN_SIZE {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export interface MultiselectDropdownData {
  items: DropdownSelectableItem[]
  title?: string
}

export interface DropdownSelectableItem {
  id: string
  label: string
  checked: boolean
  disabled?: boolean
}

@Component({
  selector: 'ht-multiselect-dropdown',
  templateUrl: './ht-multiselect-dropdown.component.html',
  styleUrls: ['./ht-multiselect-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtMultiselectDropdownComponent),
      multi: true,
    },
  ],
})
export class HtMultiselectDropdownComponent
  extends AngularComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  @Input()
  allowSelectAll = true
  @Input()
  hideArrow = false
  @Input()
  size?: HT_MULTISELECT_DROPDOWN_SIZE = HT_MULTISELECT_DROPDOWN_SIZE.MEDIUM
  @Input()
  hideLabel = false
  @Input()
  hideSelectedCount = false
  @Input()
  label?: string
  @Input()
  searchBarPlaceholder: string = 'Search'

  @Output() applyChanges = new EventEmitter<MultiselectDropdownData[]>()
  @Output() resetEvent = new EventEmitter<MultiselectDropdownData[]>()

  _data: MultiselectDropdownData[]
  filteredData: MultiselectDropdownData[]
  initialData: MultiselectDropdownData[]
  selectedCount = 0
  numberOfItems = 0
  scrollStrategy: ScrollStrategy = this.sso.reposition()
  searchValue = ''
  showDropdown = false

  buttonSize: HtSelectButtonSize = HtSelectButtonSize.MEDIUM
  buttonAppearance: HtSelectButtonAppearance = HtSelectButtonAppearance.OUTLINE

  selectAllLabel: 'Select all' | 'Deselect all' = 'Select all'
  selectAllIsChecked = false
  selectAllStyle: CheckboxStyle

  SHOW_SEARCHBAR_ELEMENTS_THRESHOLD = 6
  constructor(
    private _eref: ElementRef,
    private sso: ScrollStrategyOptions
  ) {
    super()
  }

  @Input()
  set data(value) {
    if (value) {
      this._data = value
      this.onChange(value)
    }
  }

  get data() {
    return this._data
  }

  //On click outside of component
  @HostListener('document:click', ['$event'])
  hideDropdown(event) {
    if (this.showDropdown && !this._eref.nativeElement.contains(event.target)) {
      this.onCloseDropdown(false)
    }
  }

  filterItems(value: string) {
    if (!value) {
      this.filteredData = this.data
      return
    }
    this.filteredData = []
    this.data.forEach(section => {
      const newSection = {
        title: section.title,
        items: section.items.filter(i =>
          containsNormalizedText(i.label, value)
        ),
      }
      if (newSection.items.length > 0) {
        this.filteredData.push(newSection)
      }
    })
  }

  handleDropdownMenuClick(event: Event) {
    event.stopPropagation()
  }

  ngOnInit(): void {
    this.updateButtonSize()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']?.currentValue) {
      this.initializeDropdownData()
    }

    if (changes['size']) {
      this.updateButtonSize()
    }
  }

  onChange = (value: MultiselectDropdownData[]) => {}

  onClickApply() {
    this.onChange(this.data)
    this.setSelectedCount()
    this.onCloseDropdown(true)
    this.applyChanges.emit(this.data)
  }

  onClickSelectAll() {
    this.toggleAll(this.selectAllIsChecked)
  }

  selectAll() {
    this.toggleAll(true)
  }

  deselectAll() {
    this.toggleAll(false)
  }

  onOptionSelected() {
    this.updateSelectAllCheckbox()
  }

  updateSelectAllCheckbox() {
    if (this.allowSelectAll) {
      let allSelected = true
      let someSelected = false

      this.data.forEach(section => {
        let i = 0
        while (i < section.items.length && (allSelected || !someSelected)) {
          const item = section.items[i]
          if (!item.checked) {
            allSelected = false
          } else {
            someSelected = true
          }

          i++
        }
      })

      this.selectAllLabel =
        allSelected || someSelected ? 'Deselect all' : 'Select all'
      this.selectAllStyle = allSelected
        ? CheckboxStyle.Tick
        : CheckboxStyle.Hyphen
      this.selectAllIsChecked = allSelected || someSelected
    }
  }

  private toggleAll(selectAll: boolean) {
    this.data.forEach(section => {
      section.items.forEach(i => (i.checked = selectAll))
    })

    this.updateSelectAllCheckbox()
  }

  onCloseDropdown(saveChanges: boolean) {
    if (this.data) {
      if (saveChanges) {
        this.initialData = cloneValue(this.data)
      } else {
        this.data = cloneValue(this.initialData)
      }
    }
    this.filteredData = this.data
    this.showDropdown = false
    this.searchValue = ''
  }

  onTouched = () => {}

  registerOnChange(onChangeEvent: any): void {
    this.onChange = onChangeEvent
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched
  }

  reset(data, emitEvent = true) {
    this.data = data
    this.initializeDropdownData()
    if (emitEvent) this.resetEvent.emit(data)
  }

  toggleDropdown() {
    if (this.showDropdown) {
      this.onCloseDropdown(false)
      return
    }

    this.showDropdown = true
  }

  writeValue(data: MultiselectDropdownData[]): void {
    this.data = data
  }

  private initializeDropdownData() {
    this.initialData = cloneValue(this.data)
    this.filteredData = this.data
    this.setSelectedCount()
    this.setNumberOfItems()
    this.updateSelectAllCheckbox()
  }

  private setSelectedCount() {
    let newNumber = 0
    this.data.forEach(
      section => (newNumber += section.items.filter(i => i.checked).length)
    )
    this.selectedCount = newNumber
  }

  private setNumberOfItems() {
    this.numberOfItems = 0
    this.data.forEach(section => (this.numberOfItems += section.items.length))
  }

  private updateButtonSize() {
    switch (this.size) {
      case HT_MULTISELECT_DROPDOWN_SIZE.SMALL:
        this.buttonSize = HtSelectButtonSize.SMALL
        break
      case HT_MULTISELECT_DROPDOWN_SIZE.MEDIUM:
        this.buttonSize = HtSelectButtonSize.MEDIUM
        break
      case HT_MULTISELECT_DROPDOWN_SIZE.LARGE:
        this.buttonSize = HtSelectButtonSize.LARGE
        break

      default:
        this.buttonSize = HtSelectButtonSize.MEDIUM
    }
  }
}
