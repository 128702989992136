<div *ngIf="show">
  <button
    mat-button
    [disabled]="currentPage === 1"
    (click)="onClick(+currentPage - 1)"
  >
    Previous
  </button>
  <button
    *ngFor="let i of pages"
    mat-button
    [ngClass]="{ selected: currentPage === i }"
    [disabled]="currentPage === i"
    (click)="onClick(i)"
  >
    {{ i }}
  </button>
  <button
    mat-button
    [disabled]="currentPage >= lastPage"
    (click)="onClick(+currentPage + 1)"
  >
    Next
  </button>
</div>
