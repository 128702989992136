import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { finalize, first, takeUntil } from 'rxjs/operators'
import { HubtypeBot } from '../../models/hubtype-bot'
import * as fromRoot from '../../reducers'
import { BotService } from '../../services/hubtype-api/bot.service'

@Component({
  selector: 'app-add-integration',
  templateUrl: './add-integration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./add-integration.component.scss'],
})
export class AddIntegrationComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>()
  public bot: HubtypeBot
  public feedback: string
  constructor(
    @Inject('botService') private botService: BotService,
    private store: Store<fromRoot.State>,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store
      .select(fromRoot.getBot)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(bot => {
        this.ref.markForCheck()
        this.bot = bot
      })
  }

  unintegrate() {
    this.botService
      .unIntegrate(this.bot)
      .pipe(
        first(),
        finalize(() => this.ref.markForCheck())
      )
      .subscribe(
        res => {
          this.bot.zendesk_is_active = false
        },
        err => {
          console.log('there was an error unintegrating the service', err)
        }
      )
  }

  back() {
    this.router.navigate([`/bots/${this.bot.id}/integrations`])
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
