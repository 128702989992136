<div class="container-textarea">
  <textarea
    [style.height]="height"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [value]="value"
    (input)="onInput($event.target.value)"
    [maxLength]="maxNumberOfCharacters"
  ></textarea>
  <div *ngIf="showNumberOfCharacters" class="characters">
    {{ numberOfCharacters }}/{{ maxNumberOfCharacters }}
  </div>
</div>
