import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core'

@Component({
  selector: 'app-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPillComponent implements OnInit {
  @Input()
  type = StatusPillType.GREEN

  constructor() {}

  ngOnInit() {}
}

export enum StatusPillType {
  GREEN = 'green',
  GREY = 'grey',
  BLUE = 'blue',
  ORANGE = 'orange',
}
