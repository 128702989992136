import { Inject, Injectable } from '@angular/core'
import { Observable, BehaviorSubject, of, defer, throwError } from 'rxjs'
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { RestService } from '../rest.service'
import { HubtypeApiService } from './hubtype-api.service'
import { HttpErrorResponse } from '@angular/common/http'
import { getTimezone } from 'utils/time-utils'

type RestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS'

interface TinybirdApiMethodParams {
  endpoint: string
  queryParams?: { [p: string]: any | any[] }
  version?: string
}

export interface TinybirdApiResponse<T> {
  data: T[]
  meta: { name: keyof T; type: string }[]
  statistics: {
    bytes_read: number
    elapsed: number
    rows_read: number
  }
}

@Injectable({ providedIn: 'root' })
export class TinybirdApiService {
  private BASE_URL = `${environment.tinybirdBaseUrl}`
  private readonly TOKEN_EXPIRATION_TIME_MILLISECONDS = 60000

  private tinybirdToken$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  )
  private tinybirdTokenRequest$: Observable<string> | null = null
  private tinybirdTokenRequestTimeout: any

  private readonly PIPES = [
    'analytics2_projects_kpi_avg_first_response_time',
    'analytics2_projects_kpi_created_cases',
    'analytics2_conversation_archives',
    'analytics2_conversation_filters',
    'analytics2_conversation_metrics',
    'analytics_automation_metrics_filters',
    'agent_status_log_table_v00',
  ]

  constructor(
    @Inject('apiService')
    private apiService: HubtypeApiService,
    private restService: RestService
  ) {}

  get<T>({
    endpoint,
    queryParams,
    version = 'v0',
  }: TinybirdApiMethodParams): Observable<TinybirdApiResponse<T>> {
    const method: RestMethod = 'GET'
    const options = {
      method,
      params: {
        ...queryParams,
        tz: getTimezone(),
      },
    }

    return this.request(
      options.method,
      this.buildUrl(this.BASE_URL, endpoint, version),
      options,
      this.PIPES
    )
  }

  clearToken(): void {
    if (this.tinybirdTokenRequestTimeout)
      clearTimeout(this.tinybirdTokenRequestTimeout)

    this.tinybirdToken$.next(null)
  }

  private buildUrl(baseUrl: string, endpoint: string, version: string): string {
    return `${baseUrl}/${version}/pipes${endpoint}`
  }

  private request(
    method: RestMethod,
    url: string,
    options: { [param: string]: any },
    pipes: string[]
  ): Observable<any> {
    const request = this.getTinybirdToken(pipes).pipe(
      switchMap(token =>
        this.restService.request(method, url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`,
          },
        })
      )
    )

    return request.pipe(
      catchError(error => {
        if (this.isTokenExpiredError(error)) {
          this.clearToken()
          return request.pipe(
            catchError(secondError => throwError(() => secondError))
          )
        } else {
          return throwError(() => error)
        }
      })
    )
  }

  private isTokenExpiredError(response: HttpErrorResponse): boolean {
    return (
      response.status === 403 &&
      response.error.error.includes('invalid authentication token')
    )
  }

  private getTinybirdToken(pipes: string[]): Observable<string> {
    return defer(() => {
      if (this.tinybirdToken$.value) {
        // Reusing the token if it's still valid
        return of(this.tinybirdToken$.value)
      }

      // No token and no request in progress (to avoid multiple token requests)
      if (!this.tinybirdTokenRequest$) {
        this.tinybirdTokenRequest$ = this.requestTinybirdToken(pipes).pipe(
          tap(newToken => {
            // Saving token and starting timeout to clear it (expired)
            this.tinybirdToken$.next(newToken)
            this.tinybirdTokenRequest$ = null
            this.tinybirdTokenRequestTimeout = setTimeout(
              () => this.clearToken(),
              this.TOKEN_EXPIRATION_TIME_MILLISECONDS
            )
          }),
          shareReplay(1)
        )
      }

      return this.tinybirdTokenRequest$
    })
  }

  private requestTinybirdToken(pipes: string[]): Observable<string> {
    return this.apiService
      .postV2({
        endpoint: '/analytics/auth/create_jwt_token/',
        version: 'v2',
        data: { pipes },
      })
      .pipe(map(response => response.token))
  }
}
