import { Injectable } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable, combineLatest } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { FeatureFlag } from '../constants/feature-flags'
import * as fromRoot from '../reducers'

@Injectable({
  providedIn: 'root',
})
export class CampaignsGuard {
  constructor(private store: Store<fromRoot.State>) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.select(fromRoot.getOrganization),
      this.store.select(fromRoot.getMeState),
    ]).pipe(
      filter(([org, me]) => Boolean(org) && Boolean(me)),
      map(([org, me]) => {
        // IMPORTANT: org not deserialized to HubtypeOrganization on Guard due to early stage
        //  => needs manual check (not method on class) of campaigns feature
        const hasCampaignsFeature = Boolean(
          org.feature_flags_json[FeatureFlag.CAMPAIGNS]
        )
        return (
          hasCampaignsFeature &&
          (me.is_admin || me.campaigns_exceptional_access)
        )
      })
    )
  }
}
