<app-popover width="150px" [hidden]="!overIconData">
  <div *ngIf="overIconData" [innerText]="overIconData['text']"></div>
</app-popover>
<table
  mat-table
  class="flat-table"
  [dataSource]="tableData"
  [trackBy]="trackById"
>
  <ng-container
    *ngFor="let col of displayColumns; let j = index"
    [matColumnDef]="col"
  >
    <th mat-header-cell *matHeaderCellDef [ngStyle]="columnProps[j].style">
      {{ columnProps[j].header }}
    </th>
    <td
      mat-cell
      *matCellDef="let element = $implicit; let i = index"
      [ngClass]="'cell-' + columnProps[j].type"
      [ngStyle]="hideHeaders && columnProps[j].style"
    >
      <div [ngSwitch]="columnProps[j].type">
        <div *ngSwitchCase="ICON_TYPE">
          <mat-icon
            (mouseover)="iconOver(element[col], i)"
            (mouseout)="iconOut()"
          >
            {{ element[col]['icon'] }}
          </mat-icon>
        </div>
        <div *ngSwitchCase="CRUD_TYPE">
          <button
            matTooltip="Edit"
            mat-icon-button
            aria-label="Edit"
            (click)="onActionClick(element, CRUD_EDIT)"
          >
            <mat-icon>edit_outline</mat-icon>
          </button>
          <button
            matTooltip="Delete"
            mat-icon-button
            aria-label="Delete"
            (click)="onActionClick(element, CRUD_DELETE)"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
        <div *ngSwitchCase="SELECT_TYPE" class="sel text-right">
          <mat-icon>chevron_right</mat-icon>
        </div>
        <div *ngSwitchDefault>{{ element[col] }}</div>
      </div>
    </td>
  </ng-container>

  <tr
    [hidden]="hideHeaders"
    mat-header-row
    *matHeaderRowDef="displayColumns"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayColumns"
    [ngClass]="{ selectable: selectableRow }"
    (click)="onClickRow(row)"
  ></tr>
</table>
