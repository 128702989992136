import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'

export interface RadioButtonItem {
  value: string
  label: string
}

@Component({
  selector: 'ht-radiobutton-list',
  templateUrl: './ht-radiobutton-list.component.html',
  styleUrls: ['./ht-radiobutton-list.component.scss'],
  host: { '[attr.aria-label]': 'label' },
})
export class HtRadiobuttonListComponent implements OnInit {
  @Input() items: RadioButtonItem[]
  @Input() control: UntypedFormControl
  @Input() name: string
  @Input() label = ''

  constructor() {}

  ngOnInit() {}
}
