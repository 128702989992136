import { JsonObject, JsonProperty } from 'json2typescript'

@JsonObject
export class HubtypeActiveUsers {
  static readonly MAUS_CUTOFF_YEAR = 2018

  @JsonProperty('maus', Number, false)
  public maus = 0
  @JsonProperty('start_date', String, false)
  public startDate: string = undefined
  @JsonProperty('end_date', String, false)
  public endDate: string = undefined
}
