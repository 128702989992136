import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { finalize } from 'rxjs/operators'
import * as orgAction from '../../actions/organization'
import { HubtypeBot } from '../../models/hubtype-bot'
import { HubtypeOrganization } from '../../models/hubtype-organization'
import {
  filterEnabledProviders,
  HubtypeProviderAccount,
} from '../../models/hubtype-provider-account'
import { HubtypeQueue } from '../../models/hubtype-queue'
import * as fromRoot from '../../reducers'
import { ProviderAccountService } from '../../services/hubtype-api/provider-account.service'
import { AngularComponent } from '../AngularComponent'

@Component({
  selector: 'app-add-channels',
  templateUrl: './add-channels.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./add-channels.component.scss'],
})
export class AddChannelsComponent extends AngularComponent implements OnInit {
  public selectedProvider
  public providerAccount: HubtypeProviderAccount
  public step = 1
  private organization: HubtypeOrganization
  public bot: HubtypeBot
  public queue: HubtypeQueue
  public feedback = ''

  @Output() newChannel = new EventEmitter<HubtypeProviderAccount>()
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private ref: ChangeDetectorRef,
    @Inject('providerAccountService')
    private providerAccountService: ProviderAccountService
  ) {
    super()
  }

  ngOnInit() {
    this.subscribeUntilDestroy(
      this.store.select(fromRoot.getOrganization),
      org => {
        this.ref.markForCheck()
        this.organization = org
      }
    )
    this.subscribeUntilDestroy(this.store.select(fromRoot.getBot), b => {
      this.ref.markForCheck()
      this.bot = b
    })
    this.subscribeUntilDestroy(
      this.store.select(fromRoot.getSelectedQueue),
      q => {
        this.ref.markForCheck()
        this.queue = q
      }
    )
    this.subscribeUntilDestroy(this.route.paramMap, (params: ParamMap) => {
      const provider = params.get('provider')
      if (this.providers.includes(provider)) {
        this.selectProvider(provider)
      }
    })
  }

  init() {
    this.ref.markForCheck()
    this.step = 1
    this.selectedProvider = null
    this.providerAccount = null
  }

  get providers(): string[] {
    if (this.bot) {
      return filterEnabledProviders(
        HubtypeProviderAccount.BOT_PROVIDERS,
        this.organization
      )
    }
    if (this.queue) {
      return filterEnabledProviders(
        HubtypeProviderAccount.QUEUE_PROVIDERS,
        this.organization
      )
    }
    return []
  }

  getProviderName(p) {
    return HubtypeProviderAccount.names[p]
  }

  selectProvider(p) {
    this.ref.markForCheck()
    this.selectedProvider = p
    this.step = 2
  }

  providerCreated(provider?: HubtypeProviderAccount) {
    this.ref.markForCheck()
    if (!provider) {
      return
    }
    if (this.bot) {
      provider.bot_id = this.bot.id
    }
    if (this.queue) {
      provider.queue_id = this.queue.id
    }
    this.providerAccount = provider
    const providerAccount = this.providerAccountService
      .connectProvider(provider)
      .pipe(finalize(() => this.ref.markForCheck()))

    this.subscribeUntilDestroy(
      providerAccount,
      pa => {
        this.providerAccount = pa
        this.store.dispatch(
          new orgAction.AddProviderAccountAction(this.providerAccount)
        )
        this.step = 4
      },
      err => {
        this.feedback = err.detail
          ? err.detail
          : 'There was an unexpected error. Please try again.'
        this.step = 5
      }
    )
    this.step = 3
  }

  back() {
    this.backRoute(this.router, this.route)
  }
}
