<form
  class="connect-bg connect-channel"
  (ngSubmit)="addTwitter()"
  #twitterForm="ngForm"
  name="twitterForm"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
>
  <h2>Add Twitter</h2>
  <h3>Step 1. Create a Twitter App</h3>
  <p>
    First you need to create a
    <a href="https://apps.twitter.com/" target="_blank">Twitter App</a>.
    <br /><br />Then, go to the "Permission" tab and put "Access" into "Read,
    Write and Access direct messages". <br /><br />Finally, in the "Keys and
    Access Tokens" you have to "Create Access Token"
  </p>
  <h3>Step 2. Add your Consumer and Access Tokens</h3>
  <p>
    IMPORTANT: Your app can only have a unique webhook subscribed per
    environment
  </p>
  <mat-form-field fxFlex>
    <input
      matInput
      [(ngModel)]="twitterChannel.twitterUsername"
      #twitterUsername="ngModel"
      name="twitterUsername"
      type="text"
      placeholder="Twitter handler (@username):"
      required
    />
  </mat-form-field>

  <mat-form-field fxFlex>
    <input
      matInput
      [(ngModel)]="twitterChannel.twitterConsumerKey"
      #twitterConsumerKey="ngModel"
      name="twitterConsumerKey"
      type="text"
      placeholder="Twitter Consumer Key:"
      required
    />
  </mat-form-field>

  <mat-form-field fxFlex>
    <input
      matInput
      [(ngModel)]="twitterChannel.twitterConsumerSecret"
      #twitterConsumerSecret="ngModel"
      name="twitterConsumerSecret"
      type="text"
      placeholder="Twitter Consumer Secret:"
      required
    />
  </mat-form-field>

  <mat-form-field fxFlex>
    <input
      matInput
      [(ngModel)]="twitterChannel.twitterAccessToken"
      #twitterAccessToken="ngModel"
      name="twitterAccessToken"
      type="text"
      placeholder="Twitter Access Token:"
      required
    />
  </mat-form-field>

  <mat-form-field fxFlex>
    <input
      matInput
      [(ngModel)]="twitterChannel.twitterAccesTokenSecret"
      #twitterAccesTokenSecret="ngModel"
      name="twitterAccesTokenSecret"
      type="text"
      placeholder="Twitter Access Token Secret:"
      required
    />
  </mat-form-field>

  <mat-form-field fxFlex>
    <input
      matInput
      [(ngModel)]="twitterChannel.twitterEnvironment"
      #twitterEnvironment="ngModel"
      name="twitterEnvironment"
      type="text"
      placeholder="Twitter Environment:"
      required
    />
  </mat-form-field>

  <button
    mat-raised-button
    fxFlex="none"
    type="submit"
    [disabled]="!twitterForm.form.valid"
  >
    Connect account
  </button>
</form>
