<div class="no-auth-page-container">
  <div class="title">
    <h1>Welcome</h1>
    <p>Complete your profile to start using Hubtype</p>
  </div>

  <form [formGroup]="form" class="ht-form" (ngSubmit)="onSubmit()">
    <div class="ht-form-item">
      <div class="ht-form-item-title">Email</div>
      <div class="ht-form-item-control">
        <ht-input
          label="Email"
          type="email"
          formControlName="email"
          [fullWidth]="true"
        ></ht-input>
      </div>
    </div>

    <div class="ht-form-item">
      <div class="ht-form-item-title">Username</div>
      <div class="ht-form-item-control">
        <ht-input
          label="Username"
          type="text"
          formControlName="username"
          [fullWidth]="true"
        ></ht-input>
      </div>
    </div>

    <div class="ht-form-item">
      <div class="ht-form-item-title">First name</div>
      <div class="ht-form-item-control">
        <ht-input
          label="First name"
          type="text"
          formControlName="firstName"
          [fullWidth]="true"
        ></ht-input>
      </div>
    </div>

    <div class="ht-form-item">
      <div class="ht-form-item-title">Last name</div>
      <div class="ht-form-item-control">
        <ht-input
          label="Last name"
          type="text"
          formControlName="lastName"
          [fullWidth]="true"
        ></ht-input>
      </div>
    </div>

    <div class="ht-form-row" *ngIf="form.errors?.serverError">
      <div class="error-alert">
        <i class="fa-solid fa-circle-xmark"></i>
        <span>{{ form.errors?.serverError }}</span>
      </div>
    </div>

    <div class="ht-form-actions">
      <button
        ht-button-ds-primary
        type="submit"
        size="large"
        [fullWidth]="true"
        [isLoading]="isLoading"
      >
        Continue
      </button>
    </div>
  </form>
</div>
