import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { WebchatSettings } from '../../models/hubtype-provider-account'
import { HubtypeQueue } from '../../models/hubtype-queue'
import * as fromRoot from '../../reducers'
import { ProjectQueuesGroup } from '../../utils/desk-utils'
import { AngularComponent } from '../AngularComponent'

@Component({
  selector: 'app-webchat-settings',
  templateUrl: './webchat-settings.component.html',
  styleUrls: ['./webchat-settings.component.scss'],
})
export class WebchatSettingsComponent
  extends AngularComponent
  implements OnInit, OnDestroy
{
  @Input() showCurrentQueue = false
  @Input() webchatSettings: WebchatSettings
  @Output() webchatSettingsChanged: EventEmitter<WebchatSettings> =
    new EventEmitter<WebchatSettings>()

  projectQueuesGroups: ProjectQueuesGroup[]
  queues: HubtypeQueue[]

  constructor(
    private store: Store<fromRoot.State>,
    private ref: ChangeDetectorRef
  ) {
    super()
  }

  ngOnInit() {
    this.subscribeUntilDestroy(this.store.select(fromRoot.getProjects), p => {
      this.ref.markForCheck()
      this.subscribeUntilDestroy(this.store.select(fromRoot.getQueues), q => {
        this.ref.markForCheck()
        this.queues = q
      })
      // TODO: Which should be the visible queues for user?
      this.projectQueuesGroups = p.map(project => ({
        id: project.id,
        name: project.name,
        queues: project.queues,
      }))
    })
  }

  emitSettings() {
    this.webchatSettingsChanged.emit(this.webchatSettings)
  }

  getWhitelistedUrlsText() {
    if (this.webchatSettings) {
      return (
        this.webchatSettings.whitelisted_urls &&
        this.webchatSettings.whitelisted_urls.join('\n')
      )
    }
    return ''
  }

  getQueueInfo() {
    /*
      TODO: This has been done as a workaround as has not been able
      to handle correctly the reference for QueueSelectorComponent
    */
    const scheduledQueueId = this.webchatSettings.scheduled_queue_id
    if (!scheduledQueueId) {
      return 'None'
    }
    let results = this.queues.filter(q => q.id === scheduledQueueId)
    if (results.length == 0) {
      return 'None'
    }
    const queue = results[0]
    return `${queue.name} - ${queue.id}`
  }

  onWhitelistedUrlsFilled(whitelisted_urls: string[]) {
    this.webchatSettings.whitelisted_urls = whitelisted_urls
    this.emitSettings()
  }

  onSelectedQueueId(selectedQueueId: string) {
    this.webchatSettings.scheduled_queue_id = selectedQueueId
    this.emitSettings()
  }

  onCheck() {
    this.emitSettings()
  }
}
