<div class="date-range-selector">
  <mat-form-field>
    <input
      matInput
      ngDefaultControl
      placeholder="From:"
      [formControl]="fromControl"
      [matDatepicker]="startDatePicker"
      [max]="range.toDate"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="startDatePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      ngDefaultControl
      placeholder="To:"
      [formControl]="toControl"
      [matDatepicker]="endDatePicker"
      [min]="range.fromDate"
      [max]="TODAY"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="endDatePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>
</div>
