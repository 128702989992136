import { Component, EventEmitter, Output } from '@angular/core'
import { HubtypeProviderAccount } from '../../../models/hubtype-provider-account'

@Component({
  selector: 'app-channels-telegram',
  templateUrl: './telegram.component.html',
  styleUrls: ['./telegram.component.scss'],
})
export class TelegramComponent {
  telegramChannel = {
    telegramBotToken: '',
    telegramBotName: '',
  }
  pattern = '^[0-9]{9,10}:[a-zA-Z0-9_-]{35,36}$'

  @Output() newChannel = new EventEmitter<HubtypeProviderAccount>()
  constructor() {}

  addTelegram() {
    const provider = new HubtypeProviderAccount()
    provider.provider = HubtypeProviderAccount.TELEGRAM
    provider.credentials = this.telegramChannel.telegramBotToken
    provider.name = this.telegramChannel.telegramBotName
    this.newChannel.emit(provider)
  }
}
