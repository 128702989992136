<button
  ht-button-ds
  [size]="config?.button?.size"
  [appearance]="config?.button?.appearance"
  [intent]="config?.button?.intent"
  class="date-btn"
  (click)="open()"
>
  <i class="fa-solid fa-calendar-day"></i>
  {{ textButton }}
  <i class="fa-solid fa-chevron-down"></i>
</button>

<div class="error-message" *ngIf="showError && error && !noErrorMessage">
  {{ error }}
</div>

<input
  *ngIf="!config.align || config.align === 'left'"
  #datepicker
  class="date-input"
/>

<input *ngIf="config.align === 'right'" #datepicker class="date-input" />
