<div class="modal" *ngIf="isOpen">
  <div [ngStyle]="{ width: options.width + 'px' }" class="modal-container">
    <div class="sub-title">
      <div>
        <div
          *ngIf="options.subtitle"
          [ngClass]="{
            'sub-title-red': options.subtitle.color === 'red'
          }"
          class="text-body bold break-word"
          >{{ options.subtitle.text }}</div
        >
      </div>

      <div (click)="close()" *ngIf="!options.notClosable"
        ><i class="fa-solid fa-xmark"></i
      ></div>
    </div>
    <div *ngIf="options.title" class="title">
      {{ options.title }}
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>

    <div *ngIf="shouldShowButtonBar()" class="button-bar">
      <button
        ht-button-ds
        role="leftBtn"
        [hidden]="!options?.leftBtn.show"
        (click)="options?.leftBtn.click()"
        [intent]="options?.leftBtn.intent"
        [appearance]="options?.leftBtn.appearance"
        >{{ options?.leftBtn.text }}</button
      >
      <ng-container *ngFor="let btn of this.options.middleBtnArry">
        <button
          ht-button-ds
          [appearance]="btn.appearance"
          [intent]="btn.intent"
          [hidden]="!btn.show"
          (click)="btn.click()"
          [isDisabled]="btn.disabled || false"
          [style]="btn.style"
        >
          {{ btn.text }}
        </button>
      </ng-container>

      <button
        ht-button-ds
        [intent]="options?.rightBtn.intent"
        [appearance]="options?.rightBtn.appearance"
        role="rightBtn"
        [hidden]="!options?.rightBtn.show"
        (click)="options?.rightBtn.click()"
        [isDisabled]="
          options?.rightBtn.disabled ? options?.rightBtn.disabled : false
        "
        >{{ options?.rightBtn.text }}</button
      >
    </div>
  </div>
</div>
<div *ngIf="isOpen" class="modal-container-background"></div>
