import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core'

type ChipColor = 'primary' | 'secondary'

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent implements OnInit, OnChanges {
  @Input()
  color: ChipColor = 'primary'

  classes = ''

  ngOnInit(): void {
    this.setClass()
  }

  ngOnChanges(): void {
    this.setClass()
  }

  private setClass() {
    this.classes = `chip-${this.color}`
  }
}
