import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { HubtypeOrganization } from 'models/hubtype-organization'
import { HubtypeUser } from 'models/hubtype-user'
import { Observable } from 'rxjs'
import { first, map, switchMap } from 'rxjs/operators'
import { deserialise } from 'utils/json-utils'
import * as fromRoot from '../reducers'

@Injectable({
  providedIn: 'root',
})
export class CaseArchiveGuard {
  constructor(
    private router: Router,
    private store: Store<fromRoot.State>
  ) {}

  canActivate(): Observable<boolean> {
    const userStore = this.store.select(fromRoot.getMeState)
    const organizationStore = this.store.select(fromRoot.getOrganization)

    return userStore.pipe(
      first(x => Boolean(x)),
      switchMap(user =>
        organizationStore.pipe(
          first(x => Boolean(x)),
          map(organization => {
            organization = deserialise(organization, HubtypeOrganization)
            user = deserialise(user, HubtypeUser)
            return organization.showCaseArchive(user)
          })
        )
      )
    )
  }
}
