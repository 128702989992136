import { HubtypeUser } from '../models/hubtype-user'
import { isWordInText } from './string-utils'

export function passwordMatchesUserInfo(
  password: string,
  user: HubtypeUser
): boolean {
  if (!user) {
    throw Error('User is ' + user + ' when checking its email and password')
  }
  const emailName = user.email.substring(0, user.email.indexOf('@'))

  return (
    [user.username, user.name, user.email, emailName].includes(password) ||
    isWordInText(password, user.username) ||
    isWordInText(password, user.name) ||
    isWordInText(password, emailName)
  )
}
