<div
  *ngIf="showConnectionProblem"
  class="global-connection-alert error"
  @slideIn
  @slideOut
>
  <i class="fa-regular fa-cloud-slash"></i>
  <p>
    <b>Your internet connection is unstable.</b>
  </p>
</div>

<div
  *ngIf="showConnectionRecovered"
  class="global-connection-alert success"
  @slideIn
  @slideOut
>
  <i class="fa-regular fa-cloud-check"></i>
  <p>
    <b>Your connection is now restored.</b> Please refresh to see the most
    updated data.
  </p>
  <button ht-button-ds-secondary size="small" (click)="refresh()"
    ><i class="fa-solid fa-arrows-rotate"></i> Refresh</button
  >
  <button
    ht-button-ds
    appearance="minimal"
    size="small"
    (click)="closeConnectionRecoveredAlert()"
  >
    <i class="fa-solid fa-times"></i>
  </button>
</div>
