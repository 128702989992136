import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'decimalPercent',
})
export class DecimalPercentPipe implements PipeTransform {
  transform(value: number | null): any {
    if (value === null) {
      return '--'
    }
    const percentage = value * 100
    const result = parseFloat(percentage.toFixed(2))
    return `${result}%`
  }
}
