import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { DropdownData } from '../ht-dropdown/ht-dropdown.component'
export interface DropdownLink {
  id: string
  text: string
  link: string
}
@Component({
  selector: 'ht-dropdown-link',
  templateUrl: './ht-dropdown-link.component.html',
  styleUrls: ['./ht-dropdown-link.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => HtDropdownLinkComponent),
    },
  ],
})
export class HtDropdownLinkComponent implements OnInit {
  @Input()
  data: DropdownData[]
  constructor() {}

  ngOnInit(): void {}
}
