import { AfterViewInit, Directive, ElementRef } from '@angular/core'

@Directive({
  selector: '[htautofocus]',
})
export class HtAutofocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.el.nativeElement.focus()
  }
}
