import { Component, HostBinding, Input, type OnInit } from '@angular/core'

@Component({
  selector: 'ht-form-field-card',
  templateUrl: './ht-form-field-card.component.html',
  styleUrls: ['./ht-form-field-card.component.scss'],
})
export class HtFormFieldCardComponent implements OnInit {
  @Input() title = ''
  @Input() description = ''

  // avoid conflict with html title attribute: https://stackoverflow.com/questions/60528098/angular-using-title-as-input
  @HostBinding('attr.title') get getTitle(): null {
    return null
  }

  ngOnInit(): void {}
}
