<ht-table
  #userTable
  [data]="filteredUsers"
  [(selectedIds)]="selectedUsersIds"
  [disabledIds]="disabledUserIds"
  [config]="configTable"
></ht-table>
<div
  role="empty-case-filter"
  *ngIf="filteredUsers?.length === 0 && users?.length > 0"
  class="text-body-small"
  >{{ emptyCaseFilterText || 'No users matching filter criteria' }}</div
>
<div
  role="empty-case-list"
  *ngIf="filteredUsers?.length === 0 && users?.length === 0"
  class="text-body-small"
  >{{ emptyCaseListText || 'No users to show' }}</div
>
