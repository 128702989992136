import { Component, HostBinding, Input, type OnInit } from '@angular/core'
import { BadgeType } from './ht-badge.model'

@Component({
  selector: 'ht-badge',
  templateUrl: './ht-badge.component.html',
  styleUrls: ['./ht-badge.component.scss'],
})
export class HtBadgeComponent implements OnInit {
  @Input() type: BadgeType = BadgeType.Tertiary

  @HostBinding('class.--type-primary') get isPrimary() {
    return this.type === BadgeType.Primary
  }

  @HostBinding('class.--type-secondary') get isSecondary() {
    return this.type === BadgeType.Secondary
  }

  @HostBinding('class.--type-tertiary') get isTertiary() {
    return this.type === BadgeType.Tertiary
  }

  @HostBinding('class.--type-system') get isSystem() {
    return this.type === BadgeType.System
  }

  ngOnInit(): void {}
}
