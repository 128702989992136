import { Component, Input, AfterViewInit } from '@angular/core'

import { environment } from 'environment/environment'

declare const google: any

@Component({
  selector: 'ht-map',
  templateUrl: './ht-map.component.html',
  styleUrls: ['./ht-map.component.scss'],
})
export class HtMapComponent implements AfterViewInit {
  @Input() latitude: number
  @Input() longitude: number

  readonly mapId = Math.floor(100000 + Math.random() * 900000).toString()

  constructor() {}

  ngAfterViewInit() {
    this.loadGoogleMapsApi()
      .then(() => {
        this.initMap()
      })
      .catch(error => {
        console.error('Error loading Google Maps API:', error)
      })
  }

  initMap(): void {
    if (!this.latitude || !this.longitude) {
      return
    }

    const mapElement = document.getElementById(this.mapId) as HTMLElement
    if (!mapElement) {
      return
    }

    const map = new google.maps.Map(mapElement, {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: 8,
    })

    new google.maps.Marker({
      position: { lat: this.latitude, lng: this.longitude },
      map: map,
    })
  }

  private loadGoogleMapsApi(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.getElementById('google-maps-api')) {
        resolve()
        return
      }

      const script = document.createElement('script')
      script.id = 'google-maps-api'
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.maps_api}`
      script.async = true
      script.defer = true
      script.onload = () => resolve()
      script.onerror = error => reject(error)

      document.head.appendChild(script)
    })
  }
}
