import { Component, EventEmitter, Output } from '@angular/core'
import {
  HubtypeUser,
  STATUS_AVAILABLE,
  STATUS_AWAY,
  STATUS_BUSY,
  STATUS_OFFLINE,
} from 'models/hubtype-user'

export interface FilterUserStatus {
  checked: boolean
  label: string
  color: string
  status: string
}

@Component({
  selector: 'filter-user-status',
  templateUrl: './filter-user-status.component.html',
  styleUrls: ['./filter-user-status.component.scss'],
})
export class FilterUserStatusComponent {
  @Output()
  statusChange = new EventEmitter<FilterUserStatus[]>()

  userStatus: FilterUserStatus[] = [
    {
      status: STATUS_AVAILABLE,
      checked: true,
      label: this.getLabel(STATUS_AVAILABLE),
      color: 'green',
    },
    {
      status: STATUS_BUSY,
      checked: true,
      label: this.getLabel(STATUS_BUSY),
      color: 'red',
    },
    {
      status: STATUS_AWAY,
      checked: true,
      label: this.getLabel(STATUS_AWAY),
      color: 'orange',
    },
    {
      status: STATUS_OFFLINE,
      checked: true,
      label: this.getLabel(STATUS_OFFLINE),
      color: 'gray',
    },
  ]
  constructor() {}

  onCheckStatusChange() {
    this.statusChange.emit(this.userStatus)
  }

  private getLabel(statusFilter: string) {
    return HubtypeUser.statusList.find(status => status.key === statusFilter)
      .label
  }
}
