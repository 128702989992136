<div fxFlex fxLayout="column" class="mat-typography">
  <div class="dialog-header" fxLayoutAlign="start center">
    <h3 fxFlex="100" mat-dialog-title>{{ data.title }}</h3>
    <i
      fxFlex="none"
      class="material-icons close-icon"
      fxLayoutAlign="end"
      (click)="onCancel()"
      >clear</i
    >
  </div>
  <div
    fxFlex="100"
    fxLayout="row"
    fxLayoutAlign="center stretch"
    class="edit-profile-form"
  >
    <form
      class="change-password"
      #newPasswordForm="ngForm"
      (ngSubmit)="changePasswordForm(newPasswordForm)"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        class="password-form"
      >
        <mat-form-field>
          <label>Current password</label>
          <input
            matInput
            type="password"
            [(ngModel)]="userPassword.currentPassword"
            #current_password="ngModel"
            name="currentPassword"
            required
          />
        </mat-form-field>
        <mat-form-field>
          <label>New password</label>
          <input
            matInput
            type="password"
            [(ngModel)]="userPassword.newPassword"
            #new_password="ngModel"
            name="newPassword"
            required
            minlength="12"
            maxlength="64"
          />
          <mat-hint>Minimum 12 characters long</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <label>New password (repeat)</label>
          <input
            matInput
            type="password"
            [(ngModel)]="userPassword.confirmationPassword"
            #new_password2="ngModel"
            name="confirmationPassword"
            required
            minlength="12"
            maxlength="64"
          />
        </mat-form-field>
      </div>
      <p *ngIf="feedback" class="text-feedback">
        {{ feedback }}
      </p>
      <div class="change-password-button" fxLayoutAlign="end">
        <div fxLayoutAlign="end" class="cancel">
          <button mat-button (click)="onCancel()">Cancel</button>
        </div>

        <button
          mat-raised-button
          type="submit"
          [disabled]="!newPasswordForm.form.valid"
        >
          Change password
        </button>
      </div>
    </form>
  </div>
</div>
