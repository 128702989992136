<div class="percentage-list">
  <div div class="items">
    <ng-container *ngFor="let item of data | slice: 0 : itemsShown">
      <div class="list-item">
        <div class="info">
          <div>{{ item.label }}</div>
          <div>{{ item.percentage | percent }}</div>
        </div>
        <div
          class="percentage-bar"
          [ngStyle]="{ width: item.percentage * 100 + '%' }"
        ></div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="itemsShown < data.length" class="see-more" (click)="seeAll()">
    See all {{ data.length }} results
  </div>
  <div
    *ngIf="
      itemsShown !== options.initialItemsShown && itemsShown === data.length
    "
    class="see-more"
    (click)="seeLess()"
  >
    See less
  </div>
</div>
