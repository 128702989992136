import { Component, Input, type OnInit } from '@angular/core'

@Component({
  selector: 'ht-info-message',
  templateUrl: './ht-info-message.component.html',
  styleUrls: ['./ht-info-message.component.scss'],
})
export class HtInfoMessageComponent implements OnInit {
  @Input() title = ''
  ngOnInit(): void {}
}
