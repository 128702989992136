import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'ht-radiobutton',
  templateUrl: './ht-radiobutton.component.html',
  styleUrls: ['./ht-radiobutton.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtRadiobuttonComponent),
      multi: true,
    },
  ],
})
export class HtRadiobuttonComponent implements ControlValueAccessor, OnInit {
  @Input() label = ''
  @Input() name: string
  @Input() value: string
  @Input() disabled?: boolean

  @ViewChild('radioButton', { static: true }) radioButton: ElementRef

  id = ''

  private _value: string

  constructor() {}

  ngOnInit() {
    this.updateId()
  }

  onChange: any = () => {}
  onTouched: any = () => {}

  registerOnChange(onChange: any) {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }

  writeValue(value: string) {
    this._value = value
    this.updateChecked()
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  onInputChange() {
    this.onChange(this.value)
  }

  updateChecked() {
    const checked = this.value === this._value
    if (this.radioButton) {
      this.radioButton.nativeElement.checked = checked
    }
  }

  private updateId() {
    this.id = this.name + '_' + this.value
  }
}
