import { Component, EventEmitter, Output } from '@angular/core'
import {
  HubtypeProviderAccount,
  WebchatSettings,
} from '../../../models/hubtype-provider-account'

@Component({
  selector: 'app-channels-webchat',
  templateUrl: './webchat.component.html',
  styleUrls: ['./webchat.component.scss'],
})
export class WebchatComponent {
  webchatChannelName = ''
  webchatSettings: WebchatSettings = {
    visible: true,
    scheduled_queue_id: null,
    whitelisted_urls: [],
  }
  @Output() newChannel = new EventEmitter<HubtypeProviderAccount>()

  constructor() {}

  addWebchat() {
    const provider = new HubtypeProviderAccount()
    provider.provider = HubtypeProviderAccount.WEBCHAT
    provider.name = this.webchatChannelName
    provider.credentials_json = {}
    provider.credentials_json.settings = this.webchatSettings
    this.newChannel.emit(provider)
  }

  onWebchatSettingsChanged(updatedWebchatSettings: WebchatSettings) {
    this.webchatSettings = updatedWebchatSettings
  }
}
