import { animate, state, style, transition, trigger } from '@angular/animations'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import {
  combineLatest as observableCombineLatest,
  from as observableFrom,
  of as observableOf,
} from 'rxjs'
import {
  catchError,
  filter,
  finalize,
  first,
  map,
  switchMap,
} from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { HubtypeProviderAccount } from '../../../models/hubtype-provider-account'
import {
  FacebookUserInfo,
  HubtypeUserFacebookPage,
} from '../../../models/hubtype-user'
import { ProviderAccountService } from '../../../services/hubtype-api/provider-account.service'
import { AngularComponent } from '../../AngularComponent'
import { InitParams, LoginResponse } from './models/facebook.models'
import { FacebookService } from './services/facebook.service'

@Component({
  selector: 'app-channels-facebook',
  templateUrl: './facebook.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./facebook.component.scss'],
  animations: [
    trigger('loadData', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('400ms ease-in')),
      transition(':leave', animate('400ms ease-out')),
    ]),
  ],
})
export class FacebookComponent extends AngularComponent implements OnInit {
  @Input() provider: string
  @Output() newChannel = new EventEmitter<HubtypeProviderAccount>()

  curUser: FacebookUserInfo
  loginStatus: LoginResponse
  facebookPages: HubtypeUserFacebookPage[]
  selectedFacebookPage: HubtypeUserFacebookPage
  loadingUser = false
  loadingPages = false
  feedback = false

  constructor(
    @Inject('providerAccountService')
    private providerAccountService: ProviderAccountService,
    private fb: FacebookService,
    private ref: ChangeDetectorRef
  ) {
    super()
  }

  ngOnInit() {
    const initParams: InitParams = {
      appId: environment.facebook_app_id,
      xfbml: true,
      version: 'v20.0',
    }
    observableFrom(this.fb.init(initParams)).subscribe(() =>
      this.connectAccount()
    )
  }

  connectAccount() {
    const tokenScope =
      this.provider === HubtypeProviderAccount.INSTAGRAM
        ? 'instagram_basic,instagram_manage_messages,pages_manage_metadata,business_management'
        : 'email,public_profile,pages_manage_metadata,pages_messaging,pages_show_list,business_management'
    this.ref.markForCheck()
    this.loadingUser = true
    const userObservable = observableFrom(
      this.fb.login({
        scope: tokenScope,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return_scopes: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        enable_profile_selector: true,
      })
    ).pipe(
      switchMap((response: LoginResponse) => {
        this.loginStatus = response
        return this.providerAccountService.setFbCredentials(
          this.provider,
          response.authResponse
        )
      }),
      finalize(() => {
        this.loadingUser = false
        this.ref.markForCheck()
      })
    )
    this.subscribeUntilDestroy(
      userObservable,
      user => {
        this.curUser = user
        this.refreshPages()
      },
      err => {
        this.logoutFb()
      }
    )
  }

  refreshPages() {
    this.feedback = false
    this.loadingPages = true
    this.providerAccountService
      .getFbPages(this.provider)
      .pipe(
        filter(pages => pages && pages.length > 0),
        switchMap(pages => {
          const pics = []
          for (let i = pages.length - 1; i >= 0; i--) {
            const z = observableCombineLatest([
              observableOf(pages[i]),
              observableFrom(this.fb.api(`/${pages[i].id}/picture`)).pipe(
                map(r => r.data.url),
                catchError(err => observableOf(''))
              ),
            ])
            pics.push(z)
          }
          return observableCombineLatest(...pics)
        }),
        finalize(() => {
          this.loadingPages = false
          this.ref.markForCheck()
        })
      )
      .subscribe(pageWithPics => {
        if (pageWithPics) {
          this.facebookPages = pageWithPics.map(p => ({ ...p[0], pic: p[1] }))
          this.selectedFacebookPage = this.facebookPages[0]
        }
      })
  }

  logoutFb() {
    if (this.loginStatus) {
      this.fb
        .logout()
        .then(response => {
          this.ref.markForCheck()
          this.curUser = null
          this.loginStatus = null
          this.facebookPages = null
          this.selectedFacebookPage = null
        })
        .catch((error: any) => console.error(error))
      this.providerAccountService.logoutFacebook().pipe(first()).subscribe()
    }
  }

  connectPage() {
    const provider = new HubtypeProviderAccount()
    provider.provider = this.provider
    provider.credentials = this.selectedFacebookPage.id
    this.newChannel.emit(provider)
  }
}
